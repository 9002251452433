import { mixed, number, object, string, type ObjectSchema } from 'yup';
import { ServiceIcon } from '../../../types/serviceInfo.types';
import { FieldValues, IInfo } from './types';

//TODO
const imageSchema = object<File>()
  .shape({
    lastModified: number().required(),
    modified: number().required(),
    name: string().required(),
    webkitRelativePath: string().required(),
    size: number().required(),
    type: string().required(),
  })
  .noUnknown(true);

const infoSchema = object<IInfo>().shape({
  title: string().required(),
  titleContent: string().required(),
  subTitle: string().required(),
  subTitleContent: string().required(),
  titleList: string().required(),
  list: string().required(),
  listContent: string().required(),
  titleTip: string().required(),
  tipContent: string().required(),
});

export const validationSchema: ObjectSchema<FieldValues> = object({
  fileInfo: mixed().required().defined(),

  icon: mixed<ServiceIcon>().required().defined(),
  titleEn: string().trim().required(),
  titleRu: string().trim().required(),
  titleUk: string().trim().required(),
  titlePl: string().trim().required(),

  shortTitleEn: string().trim().required(),
  shortTitleRu: string().trim().required(),
  shortTitleUk: string().trim().required(),
  shortTitlePl: string().trim().required(),

  subTitleEn: string().required().defined(),
  subTitlePl: string().required().defined(),
  subTitleUk: string().required().defined(),
  subTitleRu: string().required().defined(),

  metaDescriptionEn: string().required().defined(),
  metaDescriptionPl: string().required().defined(),
  metaDescriptionUk: string().required().defined(),
  metaDescriptionRu: string().required().defined(),

  infoEn: infoSchema.required().defined(),
  infoRu: infoSchema.required().defined(),
  infoUk: infoSchema.required().defined(),
  infoPl: infoSchema.required().defined(),
}).required();
