import { useMemo } from 'react';
import { IPagesDto } from './PagesPage.type';
import { CreatePageModal } from './components/PageModal/CreatePageModal';
import { DeletePageModal } from './components/PageModal/DeletePageModal';
import { EditPageModal } from './components/PageModal/EditPageModal';

export enum MODALS {
  CREATE_PAGE = 'CREATE_PAGE',
  EDIT_PAGE = 'EDIT_PAGE',
  DELETE_PAGE = 'DELETE_PAGE',
}

export type ModalKeys = keyof typeof MODALS;

interface chooseModalProps {
  choosenModal: ModalKeys | null;
  updatePageAfterDelete: () => void;
  updatePageAfterCreate: (page: IPagesDto) => void;
  updatePageAfterEdit: (editedPage: IPagesDto) => void;
  selectedPage: IPagesDto | null;
  closeModal: () => void;
}

export const ChooseModal = ({
  selectedPage,
  closeModal,
  choosenModal,
  updatePageAfterEdit,
  updatePageAfterDelete,
  updatePageAfterCreate,
}: chooseModalProps) => {
  const modal = useMemo(() => {
    switch (choosenModal) {
      case MODALS.CREATE_PAGE:
        return (
          <CreatePageModal
            updatePageAfterCreate={updatePageAfterCreate}
            open
            onClose={() => closeModal()}
          />
        );
      case MODALS.EDIT_PAGE:
        return (
          selectedPage && (
            <EditPageModal
              updatePageAfterEdit={updatePageAfterEdit}
              page={selectedPage}
              open
              onClose={() => closeModal()}
            />
          )
        );
      case MODALS.DELETE_PAGE:
        return (
          selectedPage && (
            <DeletePageModal
              updatePageAfterDelete={updatePageAfterDelete}
              item={selectedPage}
              onClose={() => closeModal()}
              open
            />
          )
        );
      default:
        return <></>;
    }
  }, [
    choosenModal,
    closeModal,
    selectedPage,
    updatePageAfterCreate,
    updatePageAfterDelete,
    updatePageAfterEdit,
  ]);

  return modal;
};
