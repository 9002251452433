export const AirPods = ({
  height = '1em',
  width = '1em',
}: {
  height?: string;
  width?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2600_8497)">
        <path
          d="M35.4745 37.9995H32.9482C31.4324 37.9995 30.4219 36.989 30.4219 35.4732V32.1048C30.4219 31.5995 30.7587 31.2627 31.264 31.2627C31.7692 31.2627 32.1061 31.5995 32.1061 32.1048V35.4732C32.1061 35.9785 32.4429 36.3153 32.9482 36.3153H35.4745C35.9798 36.3153 36.3166 35.9785 36.3166 35.4732V32.1048C36.3166 31.5995 36.6535 31.2627 37.1587 31.2627C37.664 31.2627 38.0008 31.5995 38.0008 32.1048V35.4732C38.0008 36.989 36.9903 37.9995 35.4745 37.9995Z"
          fill="#101010"
        />
        <path
          d="M35.4745 34.6311H32.9482C31.4324 34.6311 30.4219 33.6206 30.4219 32.1048V21.9995C30.4219 21.4943 30.7587 21.1574 31.264 21.1574C31.7692 21.1574 32.1061 21.4943 32.1061 21.9995V32.1048C32.1061 32.6101 32.4429 32.9469 32.9482 32.9469H35.4745C35.9798 32.9469 36.3166 32.6101 36.3166 32.1048V16.1048C36.3166 15.5995 36.6535 15.2627 37.1587 15.2627C37.664 15.2627 38.0008 15.5995 38.0008 16.1048V32.1048C38.0008 33.6206 36.9903 34.6311 35.4745 34.6311Z"
          fill="#101010"
        />
        <path
          d="M31.2632 22.8418C27.5579 22.8418 24.5264 19.8103 24.5264 16.105C24.5264 12.3997 27.5579 9.36816 31.2632 9.36816C34.9685 9.36816 38.0001 12.3997 38.0001 16.105C38.0001 16.6103 37.6632 16.9471 37.1579 16.9471C36.6527 16.9471 36.3158 16.6103 36.3158 16.105C36.3158 13.4103 33.9579 11.0524 31.2632 11.0524C28.5685 11.0524 26.2106 13.4103 26.2106 16.105C26.2106 18.7997 28.5685 21.1576 31.2632 21.1576C31.7685 21.1576 32.1053 21.4945 32.1053 21.9997C32.1053 22.505 31.7685 22.8418 31.2632 22.8418Z"
          fill="#101010"
        />
        <path
          d="M11.0526 34.6319H8.52632C7.01053 34.6319 6 33.6213 6 32.1055V28.7371C6 28.2319 6.33684 27.895 6.84211 27.895C7.34737 27.895 7.68421 28.2319 7.68421 28.7371V32.1055C7.68421 32.6108 8.02105 32.9477 8.52632 32.9477H11.0526C11.5579 32.9477 11.8947 32.6108 11.8947 32.1055V28.7371C11.8947 28.2319 12.2316 27.895 12.7368 27.895C13.2421 27.895 13.5789 28.2319 13.5789 28.7371V32.1055C13.5789 33.6213 12.5684 34.6319 11.0526 34.6319Z"
          fill="#101010"
        />
        <path
          d="M11.0526 31.263H8.52632C7.01053 31.263 6 30.2524 6 28.7366V12.7366C6 12.2314 6.33684 11.8945 6.84211 11.8945C7.34737 11.8945 7.68421 12.2314 7.68421 12.7366V28.7366C7.68421 29.2419 8.02105 29.5787 8.52632 29.5787H11.0526C11.5579 29.5787 11.8947 29.2419 11.8947 28.7366V18.6314C11.8947 18.1261 12.2316 17.7893 12.7368 17.7893C13.2421 17.7893 13.5789 18.1261 13.5789 18.6314V28.7366C13.5789 30.2524 12.5684 31.263 11.0526 31.263Z"
          fill="#101010"
        />
        <path
          d="M12.7368 19.4737C12.2316 19.4737 11.8947 19.1368 11.8947 18.6316C11.8947 18.1263 12.2316 17.7895 12.7368 17.7895C15.4316 17.7895 17.7895 15.4316 17.7895 12.7368C17.7895 10.0421 15.4316 7.68421 12.7368 7.68421C10.0421 7.68421 7.68421 10.0421 7.68421 12.7368C7.68421 13.2421 7.34737 13.5789 6.84211 13.5789C6.33684 13.5789 6 13.2421 6 12.7368C6 9.03158 9.03158 6 12.7368 6C16.4421 6 19.4737 9.03158 19.4737 12.7368C19.4737 16.4421 16.4421 19.4737 12.7368 19.4737Z"
          fill="#101010"
        />
      </g>
      <defs>
        <clipPath id="clip0_2600_8497">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
