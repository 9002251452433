export const ConnectorOnTheMotherboard = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_9355)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <path
        d="M50 69.9377C45.0019 69.9377 40.2677 68.0636 36.6226 64.7526L34.204 67.1712C33.9441 67.4311 33.5 67.2468 33.5 66.8797V58.6627C33.5 58.4344 33.6843 58.2502 33.9125 58.2502H42.1295C42.4966 58.2502 42.6809 58.6943 42.421 58.9542L39.5431 61.8321C42.4155 64.3731 46.1046 65.8127 50 65.8127C59.614 65.8127 67.1174 57.2258 65.6269 47.5651C65.4536 46.4403 66.225 45.3871 67.3511 45.2124C68.469 45.0364 69.5291 45.8106 69.7038 46.9367C71.582 59.0834 62.1426 69.9377 50 69.9377Z"
        fill="#1068ED"
      />
      <path
        d="M32.6489 54.7878C31.5393 54.9638 30.4709 54.2006 30.2963 53.0635C28.418 40.9168 37.8574 30.0625 50 30.0625C54.9981 30.0625 59.7323 31.9366 63.3774 35.2476L65.796 32.829C66.0559 32.5691 66.5 32.7534 66.5 33.1205V41.3375C66.5 41.5658 66.3158 41.75 66.0875 41.75H57.8705C57.5034 41.75 57.3191 41.3059 57.579 41.046L60.4569 38.1681C57.5859 35.6271 53.8954 34.1875 50 34.1875C40.386 34.1875 32.8826 42.7744 34.3731 52.4351C34.5478 53.5613 33.775 54.6145 32.6489 54.7878Z"
        fill="#1068ED"
      />
      <path
        d="M59.218 44.2386L56.963 44.7913C56.809 44.8284 56.6509 44.7734 56.5505 44.6497C55.8671 43.8123 55.0751 43.1468 54.1319 42.6202C53.993 42.5432 53.9105 42.3974 53.9215 42.2393L54.0741 39.9224C54.0879 39.7121 53.9407 39.5251 53.7345 39.4893L50.088 38.8458C49.8804 38.8087 49.6796 38.9352 49.6205 39.1373L48.9687 41.3744C48.9247 41.5257 48.7982 41.6329 48.6429 41.6591C47.6047 41.8268 46.5941 42.1816 45.6701 42.7316C45.5354 42.8113 45.369 42.8113 45.2384 42.7233L43.3092 41.4336C43.1346 41.3167 42.8995 41.3497 42.7634 41.5119L40.3832 44.3486C40.2485 44.5094 40.2554 44.7473 40.4011 44.8986L42.0057 46.5733C42.1144 46.6861 42.1432 46.8511 42.0882 46.9968C41.7074 48.0006 41.5327 49.0579 41.5492 50.1098C41.552 50.2666 41.4667 50.4109 41.3265 50.4811L39.2365 51.5109C39.0481 51.6044 38.9587 51.8244 39.0316 52.0224L40.298 55.5012C40.3695 55.6992 40.5799 55.8106 40.7834 55.7611L43.0384 55.2083C43.1924 55.1712 43.3505 55.2262 43.4509 55.3499C44.1329 56.1873 44.9262 56.8528 45.8695 57.3794C46.0084 57.4564 46.0909 57.6022 46.0799 57.7603L45.9272 60.0772C45.9135 60.2876 46.0606 60.4746 46.2669 60.5103L49.9134 61.1538C50.121 61.1909 50.3217 61.0644 50.3809 60.8623L51.0326 58.6252C51.0766 58.4739 51.2031 58.3667 51.3585 58.3406C52.398 58.1728 53.4072 57.8167 54.3312 57.2681C54.466 57.1883 54.6324 57.1883 54.763 57.2749L56.6907 58.5633C56.8654 58.6802 57.1005 58.6472 57.2366 58.4849L59.6167 55.6483C59.7515 55.4874 59.7446 55.2496 59.5989 55.0983L57.9942 53.4236C57.8856 53.3108 57.8567 53.1458 57.9117 53.0001C58.2926 51.9963 58.4672 50.9389 58.4507 49.8871C58.448 49.7303 58.5332 49.5859 58.6735 49.5158L60.7635 48.4859C60.9519 48.3924 61.0412 48.1724 60.9684 47.9744L59.702 44.4957C59.6319 44.3004 59.4215 44.1877 59.218 44.2386ZM51.4107 53.8746C49.2699 54.6542 46.9035 53.5501 46.1252 51.4092C45.3456 49.2683 46.4497 46.9019 48.5906 46.1237C50.7315 45.3441 53.0979 46.4482 53.8761 48.5891C54.6544 50.7299 53.5502 53.0963 51.4107 53.8746Z"
        fill="#1068ED"
      />
      <defs>
        <filter
          id="filter0_b_2954_9355"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_9355"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_9355"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
