import { Typography } from '@mui/material';
import { useState } from 'react';
import { API } from '../../../../api/api';
import { URLS } from '../../../../api/urls';
import { Modal } from '../../../../components/Modal/Modal';
import { ALERT_TEXT } from '../../../../constants/content';
import { useAlert } from '../../../../context/Alert.context';
import { IPagesDto, PageEdit } from '../../PagesPage.type';
import { Tip } from '../Tip/Tip';
import tipStyle from '../Tip/styles.module.css';
import { PageForm } from './PageForm';

interface CreatePageModalProps {
  open: boolean;
  onClose: () => void;
  updatePageAfterCreate: (page: IPagesDto) => void;
}

export const CreatePageModal = ({
  open,
  onClose,
  updatePageAfterCreate,
}: CreatePageModalProps) => {
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();
  const editPageModal = (formData: PageEdit) => {
    setLoading(true);
    API.post(URLS.PAGES, { ...formData })
      .then(({ data }) => {
        updatePageAfterCreate(data);
        onClose();
        showAlert({
          message: ALERT_TEXT.PAGE_CREATED_SUCCESS,
          severity: 'success',
        });
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      shouldPreventModal
      title={
        <div className={tipStyle.modalHeader}>
          <Typography variant="h6">Add page</Typography>
          <Tip />
        </div>
      }
      open={open}
      onClose={onClose}
    >
      <PageForm loading={loading} onSubmit={editPageModal} onCancel={onClose} />
    </Modal>
  );
};
