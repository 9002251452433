import { Typography } from '@mui/material';
import { Modal } from '../../../../../../components/Modal/Modal';
import { ServiceInfo } from '../../../../../../types/serviceInfo.types';
import { Service } from '../../../../../../types/services.types';
import styles from '../../../../../ServicesPage/modals/styles.module.css';
import { ServiceForm } from '../components/ServiceForm';

interface Props {
  open: boolean;
  onClose: () => void;
  serviceInfo: ServiceInfo[];
  onAdd: (service: Service) => void;
}

export const CreateServiceModal = ({
  open,
  onClose,
  serviceInfo,
  onAdd,
}: Props) => {
  const createService = (service: Service) => {
    onClose();
    onAdd(service);
  };

  return (
    <Modal
      shouldPreventModal
      title={
        <div className={styles.modalHeader}>
          <Typography variant="h6">Create service</Typography>
        </div>
      }
      open={open}
      onClose={onClose}
    >
      <ServiceForm
        serviceInfo={serviceInfo}
        service={null}
        createService={createService}
      />
    </Modal>
  );
};
