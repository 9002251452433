export const BrokenDisplayIpad = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_9943)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.3333 55.8667C67.0268 55.8667 66.7173 55.7713 66.4533 55.5733L61.7468 51.7952L57.0667 56.4739V60.2667C57.0667 61.0763 56.4096 61.7333 55.6 61.7333C54.7904 61.7333 54.1333 61.0763 54.1333 60.2667V57.3333H51.2C50.3904 57.3333 49.7333 56.6763 49.7333 55.8667C49.7333 55.0571 50.3904 54.4 51.2 54.4H54.9928L59.3928 50H55.6C55.138 50 54.7039 49.7829 54.4267 49.4133L53.7931 48.5671L52.014 49.7536C51.3393 50.2024 50.4285 50.0205 49.9797 49.3473C49.5309 48.6727 49.7128 47.7619 50.386 47.3131L52.0301 46.2175L50.0267 43.5467C49.5412 42.8984 49.6717 41.9788 50.32 41.4933C50.9683 41.0079 51.8879 41.1384 52.3733 41.7867L56.3333 47.0667H60C60.3168 47.0667 60.6263 47.1693 60.88 47.36L67.3333 52.5227V32.4C67.3333 29.9712 65.3621 28 62.9333 28H36.4C33.9712 28 32 29.9712 32 32.4V67.6C32 70.0288 33.9712 72 36.4 72H62.9333C65.3621 72 67.3333 70.0288 67.3333 67.6V55.8667ZM46.7333 33.8667H52.6C53.4096 33.8667 54.0667 33.2096 54.0667 32.4C54.0667 31.5904 53.4096 30.9333 52.6 30.9333H46.7333C45.9237 30.9333 45.2667 31.5904 45.2667 32.4C45.2667 33.2096 45.9237 33.8667 46.7333 33.8667Z"
        fill="#1068ED"
      />
      <defs>
        <filter
          id="filter0_b_2954_9943"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_9943"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_9943"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
