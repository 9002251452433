export const DataRecovery = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_9325)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <path
        d="M42.4667 62.7999C43.4 62.7999 44.4 62.7333 45.2667 62.6666C44.7333 60.7999 44.6667 58.7999 45.0667 56.9999C44.2 57.0666 43.3333 57.1333 42.5333 57.1333C37.7333 57.1333 33.0667 55.9333 30.5333 53.8666C30.3333 53.6666 30.1333 53.4666 29.9333 53.2666V57.2666C29.9333 60.2666 35.6667 62.7999 42.4667 62.7999Z"
        fill="#1068ED"
      />
      <path
        d="M45.7333 63.933C44.6667 64.0664 43.5333 64.133 42.4667 64.133C36.8667 64.133 32.1333 62.533 29.9333 60.1997V64.1997C29.9333 67.1997 35.6667 69.7997 42.4667 69.7997C44.9333 69.7997 47.4 69.4664 49.4 68.8664C47.8 67.5997 46.5333 65.933 45.7333 63.933Z"
        fill="#1068ED"
      />
      <path
        d="M42.4667 40.9998C49.2667 40.9998 55 38.4665 55 35.3998C55 32.3998 49.2667 29.7998 42.4667 29.7998C35.6667 29.7998 29.9333 32.3331 29.9333 35.3998C29.9333 38.4665 35.6667 40.9998 42.4667 40.9998Z"
        fill="#1068ED"
      />
      <path
        d="M42.4667 48.733C45.8667 48.733 49 48.1997 51.3333 47.1997C53 46.4663 55.0667 45.1997 55.0667 43.133V38.333C52.8667 40.6663 48.1333 42.2663 42.5333 42.2663C36.9333 42.2663 32.2 40.6663 30 38.333V43.133C29.9333 46.8663 36.2667 48.733 42.4667 48.733Z"
        fill="#1068ED"
      />
      <path
        d="M31.4 52.7999C34.1333 55.0666 40.0667 56.1999 45.4667 55.5999C45.4667 55.4666 45.5333 55.3999 45.5333 55.2666C47 51.1333 50.7333 48.1333 55 47.3999V46.2666C52.6 48.7999 47.4 49.9333 42.4667 49.9333C38.4667 49.9333 32.6667 49.1333 29.9333 46.2666V50.1333C29.9333 51.1333 30.4 51.9999 31.4 52.7999Z"
        fill="#1068ED"
      />
      <path
        d="M69.8667 58.9329C69.6 58.6663 66 55.1329 65.3333 54.3996C65.1333 54.0663 64.7333 53.9329 64.3333 54.3329H64.2667C64.0667 54.5996 63.2667 55.3329 59.6667 58.9996C59.2667 59.3996 59.5333 60.1329 60.1333 60.1329H62.6C62.2667 62.9329 59.8667 65.1329 56.9333 65.1329C53.8 65.1329 51.2667 62.5996 51.2667 59.4663C51.2667 56.7329 53.2 54.3996 55.8667 53.8663C56.8667 53.6663 58.0667 53.7329 58.9333 54.1329C59.2 54.1996 59.4667 54.1996 59.6667 53.9996L62.4667 51.1996C62.8 50.8663 62.6667 50.3329 62.3333 50.1329C60.1333 48.9329 57.7333 48.4663 55.6667 48.7996C50.4 49.3996 46.2667 53.9329 46.2667 59.4663C46.2667 65.4663 51.1333 70.1996 57 70.1996C62.6667 70.1996 67.4 65.7329 67.7333 60.1329H69.3333C69.8667 60.1996 70.4 59.4663 69.8667 58.9329Z"
        fill="#1068ED"
      />
      <defs>
        <filter
          id="filter0_b_2954_9325"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_9325"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_9325"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
