export const Iphone = ({
  height = '1em',
  width = '1em',
}: {
  height?: string;
  width?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.6282 0H13.3718C12.6104 0.000862911 11.8805 0.301139 11.3421 0.834954C10.8037 1.36877 10.5009 2.09253 10.5 2.84746V41.1525C10.5009 41.9075 10.8037 42.6312 11.3421 43.165C11.8805 43.6989 12.6104 43.9991 13.3718 44H31.6282C32.3896 43.9991 33.1195 43.6989 33.6579 43.165C34.1963 42.6312 34.4991 41.9075 34.5 41.1525V2.84746C34.4991 2.09253 34.1963 1.36877 33.6579 0.834954C33.1195 0.301139 32.3896 0.000862911 31.6282 0ZM33.0641 41.1525C33.0637 41.53 32.9122 41.8919 32.643 42.1588C32.3738 42.4257 32.0089 42.5758 31.6282 42.5763H13.3718C12.9911 42.5758 12.6262 42.4257 12.357 42.1588C12.0878 41.8919 11.9363 41.53 11.9359 41.1525V2.84746C11.9363 2.46999 12.0878 2.10811 12.357 1.84121C12.6262 1.5743 12.9911 1.42416 13.3718 1.42373H16.8348L17.6999 2.31721C17.7669 2.38637 17.8474 2.4414 17.9364 2.479C18.0254 2.5166 18.1211 2.53599 18.2179 2.53602H26.7821C26.8789 2.53599 26.9746 2.5166 27.0636 2.479C27.1526 2.4414 27.2331 2.38637 27.3001 2.31721L28.1652 1.42373H31.6282C32.0089 1.42416 32.3738 1.5743 32.643 1.84121C32.9122 2.10811 33.0637 2.46999 33.0641 2.84746V41.1525Z"
        fill="#101010"
      />
    </svg>
  );
};
