import { GadgetDto } from '../../../types/gadgets.types';
import { ModelDto } from '../../../types/models.types';
import { ModalKeys } from '../chooseModal';

export interface IInitialState {
  gadgets: GadgetDto[];
  loading: boolean;
  choosenModal: ModalKeys | null;
  selectedGadget: GadgetDto | null;
  selectedModel: ModelDto | null;
}

export const actionGadgetTypes = {
  SET_GADGETS: 'SET_GADGETS' as const,
  SET_LOADING: 'SET_LOADING' as const,
  SET_SELECTED_GADGET: 'SET_SELECTED_GADGET' as const,
  SET_SELECTED_MODEL: 'SET_SELECTED_MODEL' as const,
  SET_CHOOSEN_MODAL: 'SET_CHOOSEN_MODAL' as const,
};

export type ActionTypes =
  | { type: typeof actionGadgetTypes.SET_GADGETS; payload: GadgetDto[] }
  | { type: typeof actionGadgetTypes.SET_LOADING; payload: boolean }
  | {
      type: typeof actionGadgetTypes.SET_CHOOSEN_MODAL;
      payload: null | ModalKeys;
    }
  | {
      type: typeof actionGadgetTypes.SET_SELECTED_GADGET;
      payload: null | GadgetDto;
    }
  | {
      type: typeof actionGadgetTypes.SET_SELECTED_MODEL;
      payload: null | ModelDto;
    };
