import { useState } from 'react';
import { API } from '../../../../api/api';
import { URLS } from '../../../../api/urls';
import { Modal } from '../../../../components/Modal/Modal';
import { ALERT_TEXT } from '../../../../constants/content';
import { useAlert } from '../../../../context/Alert.context';
import { ModelDto } from '../../../../types/models.types';
import { ModelEdit } from '../../GadgetsPage.type';
import { ModelForm } from './ModelForm';

interface EditModelModalProps {
  open: boolean;
  onClose: () => void;
  updateGadgetAfterEditModel: (model: ModelEdit) => void;
  selectedModel: ModelDto;
}

export const EditModelModal = ({
  open,
  onClose,
  updateGadgetAfterEditModel,
  selectedModel,
}: EditModelModalProps) => {
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();

  const editModel = (model: ModelEdit) => {
    setLoading(true);
    const formData = new FormData();
    model.file && formData.append('file', model.file);
    formData.append('nameEn', model.nameEn);
    formData.append('nameRu', model.nameRu);
    formData.append('namePl', model.namePl);
    formData.append('nameUk', model.nameUk);
    formData.append('services', JSON.stringify(model.services));
    selectedModel?.gadgetId &&
      formData.append('gadgetId', selectedModel.gadgetId);
    API.put(`${URLS.MODELS}/${selectedModel.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        updateGadgetAfterEditModel(data);
        onClose();
        showAlert({
          message: ALERT_TEXT.MODEL_EDIT_SUCCESS,
          severity: 'success',
        });
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal shouldPreventModal title="Edit model" open={open} onClose={onClose}>
      <ModelForm
        gadgetSlug={selectedModel.gadgetSlug as string}
        loading={loading}
        onSubmit={editModel}
        onCancel={onClose}
        defaultValue={selectedModel}
      />
    </Modal>
  );
};
