import { FieldsArray, PostTopics } from '../../types';

export enum ACTIVE_TAB {
  POLISH = 0,
  ENGLISH = 1,
  RUSSIAN = 2,
  UKRANIAN = 3,
}

export enum LANGUAGES {
  POLISH = 'Pl',
  ENGLISH = 'En',
  RUSSIAN = 'Ru',
  UKRANIAN = 'Uk',
}

export const EN_FIELD_NAMES: FieldsArray = [
  'titleEn',
  'bodyEn',
  'metaDescriptionEn',
];
export const PL_FIELD_NAMES: FieldsArray = [
  'titlePl',
  'bodyPl',
  'metaDescriptionPl',
];
export const UK_FIELD_NAMES: FieldsArray = [
  'titleUk',
  'bodyUk',
  'metaDescriptionUk',
];
export const RU_FIELD_NAMES: FieldsArray = [
  'titleRu',
  'bodyRu',
  'metaDescriptionRu',
];

export const topicSelect = [
  {
    value: PostTopics.TUTORIALS,
    label: 'Tutorials',
  },
  {
    value: PostTopics.TECHNOLOGY,
    label: 'Technology',
  },
  {
    value: PostTopics.COMPANY,
    label: 'Company',
  },
  {
    value: PostTopics.EVENTS,
    label: 'Events',
  },
];
