import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { IPagesDto } from '../PagesPage.type';
import styles from '../styles.module.css';

interface RowProps {
  onPageEdit: () => void;
  onPageDelete: () => void;
  row: IPagesDto;
}

export const Row = ({ row, onPageEdit, onPageDelete }: RowProps) => {
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell align="left" component="th" scope="row">
          <div className={styles.pageName}>
            <Typography>{row.page}</Typography>
          </div>
        </TableCell>
        <TableCell align="right">
          <div className={styles.action}>
            <EditIcon onClick={onPageEdit} />
            <DeleteIcon color="error" onClick={onPageDelete} />
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
