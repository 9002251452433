import DeleteIcon from '@mui/icons-material/Delete';
import { TableCell, TableRow } from '@mui/material';
import { replaceUnderscoreWithSpace } from '../../../helpers/textTransform';
import { Column, IMail } from '../MailsPage.type';
import styles from '../styles.module.css';

type Props = {
  columns: Column[];
  row: IMail;
  onPageDelete: (mailId: string) => void;
};

export const TableRowComponent = ({ row, columns, onPageDelete }: Props) => {
  const handleColumnValue = (column: Column) => {
    switch (column.name) {
      case 'communicationChannel':
        return !!row[column.name]
          ? replaceUnderscoreWithSpace(row[column.name])
          : row[column.name];
      case 'source':
        return !!row[column.name]
          ? replaceUnderscoreWithSpace(row[column.name])
          : row[column.name];
      case 'actions':
        return;
      default:
        return row[column.name];
    }
  };
  return (
    <TableRow hover role="checkbox" tabIndex={-1}>
      {columns.map((column) => {
        const value = handleColumnValue(column);

        if (column.name === 'actions') {
          return (
            <TableCell key={column.name} align={column.align}>
              <div className={styles.action}>
                <DeleteIcon
                  color="error"
                  onClick={() => onPageDelete(row.id)}
                />
              </div>
            </TableCell>
          );
        }

        return (
          <TableCell key={column.name} align={column.align}>
            {typeof value === 'object' && value !== null
              ? `${value.models.name} / ${value.serviceInfo.shortTitleEn}`
              : value}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
