import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FeedIcon from '@mui/icons-material/Feed';
import HomeIcon from '@mui/icons-material/Home';
import { Container, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  BreadcrumbsComponent,
  LinkItem,
} from '../../components/Breadcrumbs/Breadcrumbs';
import { Spinner } from '../../components/Spinner/Spinner';
import { ServiceInfo } from '../../types/serviceInfo.types';
import styles from '../BlogPage/styles.module.css';
import { useServicePage } from './ServiceInfo.bl';
import { ChooseModal, MODALS } from './chooseModal';
import { Row } from './components/Row';
import { parseServiceInfoListToString } from './utils/parseServiceInfo';

const linkList: LinkItem[] = [
  {
    name: 'Projects',
    to: '/',
    icon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    name: 'Choice',
    to: '/choice',
    icon: <AccountTreeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    name: 'Service info',
    to: '',
    icon: <FeedIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
];

export const ServiceInfoPage = () => {
  const {
    gadgets,
    loading,
    selectedGadgetId,
    selectedServiceInfo,
    setModal,
    closeModal,
    choosenModal,
    setSelectedGadget,
    updateAfterAdd,
    setSelectedServiceInfo,
    updateAfterEdit,
  } = useServicePage();

  if (loading)
    return (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );

  return (
    <Container maxWidth="xl">
      <div className={styles.container}>
        <div className={styles.header}>
          <Typography variant="h2">Service info</Typography>
        </div>
        <BreadcrumbsComponent linkList={linkList} />
        {!gadgets.length && (
          <div className={styles.loader}>
            <Typography variant="h2">Table is empty.</Typography>
          </div>
        )}
        {!!gadgets.length && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className={styles.tableHeader}>
                <TableRow>
                  {['Gadgets', ''].map((item) => (
                    <TableCell className={styles.text} key={item}>
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {gadgets.map((row: any) => (
                  <Row
                    key={row.id}
                    row={row}
                    onServiceInfoAdd={() => {
                      setSelectedGadget(row.id);
                      setModal(MODALS.CREATE_SERVICE_INFO);
                    }}
                    onServiceInfoEdit={(serviceInfo: ServiceInfo) => {
                      setSelectedGadget(row.id);
                      setSelectedServiceInfo(serviceInfo);
                      setModal(MODALS.EDIT_SERVICE_INFO);
                    }}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <ChooseModal
        updateAfterAdd={updateAfterAdd}
        updateAfterEdit={updateAfterEdit}
        selectedServiceInfo={parseServiceInfoListToString(selectedServiceInfo)}
        choosenModal={choosenModal}
        selectedGadgetId={selectedGadgetId}
        closeModal={closeModal}
      />
    </Container>
  );
};
