import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { API } from '../../../../../../api/api';
import { URLS } from '../../../../../../api/urls';
import { IFile } from '../../../../../../types/types';
import styles from './styles.module.css';

export const AuthorPhotoSelect = ({
  name,
  rules,
  title,
  disabled,
  ...props
}: any) => {
  const [authorPhotoList, setAuthorPhotoList] = useState<IFile[]>([]);
  const {
    field: { onChange, value, ref },
    fieldState: { error },
  } = useController({ name, rules });

  useEffect(() => {
    API.get(`${URLS.POSTS}/authors`)
      .then(({ data }) => {
        const photos = data.filter((item: any) => item);
        setAuthorPhotoList(photos);
      })
      .catch((error: Error) => console.log(error));
  }, []);
  return (
    <FormControl className={styles.select} fullWidth>
      <Typography className={styles.title} variant="body1">
        {title}
      </Typography>
      <div>
        <Select
          disabled={disabled}
          error={!!error}
          fullWidth
          value={!disabled ? value : ''}
          onChange={onChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          {...props}
        >
          {!disabled &&
            authorPhotoList.length > 0 &&
            authorPhotoList.map((item) => (
              <MenuItem key={item?.id} value={item?.fileName}>
                <div className={styles.selectItemWrapper}>
                  <img
                    className={styles.selectIcon}
                    src={`${process.env.REACT_APP_API_URL_STATIC}/posts/author/${item?.fileName}`}
                    alt={item?.originalName}
                  />
                  <div className={styles.selectText}>{item?.originalName}</div>
                </div>
                {/* <DeleteIcon color="error" onClick={() => {}} /> */}
              </MenuItem>
            ))}
        </Select>
        {error?.message && (
          <Typography color="error" variant="body1">
            This field is required
          </Typography>
        )}
      </div>
    </FormControl>
  );
};
