export interface IPaginationMetaDtoParameters {
  page: number;
  limit: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface IFile {
  id: string;
  fileName: string;
  mimeType: string;
  originalName: string;
  size: number;
}

export const PAGES = {
  GLOBAL: 'Global',
  HOME: 'Home',
  PRICES: 'Prices',
  SERVICES: 'Services',
  BLOG: 'Blog',
  CONTACT_US: 'Contact us',
  SUPPORT: 'Support',
  TERMS: 'Terms',
  REGULATIONS: 'Regulations',
  PRIVACY_POLICY: 'Privacy Policy',
};
