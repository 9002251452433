import HelpIcon from '@mui/icons-material/Help';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { Modal } from '../../../../components/Modal/Modal';
import { PAGES } from '../../../../types/types';
import styles from './styles.module.css';

export const Tip = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles.tip}>
      <div onClick={() => setOpen(true)}>
        <HelpIcon />
      </div>
      <Modal open={open} onClose={() => setOpen(false)} title="How to fill">
        <div className={styles.model}>
          <Typography variant="h5">Pages list:</Typography>
          <div>
            <ol>
              {Object.values(PAGES).map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ol>
          </div>
        </div>
      </Modal>
    </div>
  );
};
