import { useState } from 'react';
import { API } from '../../../../api/api';
import { URLS } from '../../../../api/urls';
import { Modal } from '../../../../components/Modal/Modal';
import { ALERT_TEXT } from '../../../../constants/content';
import { useAlert } from '../../../../context/Alert.context';
import { GadgetDto } from '../../../../types/gadgets.types';
import { GadgetEdit } from '../../GadgetsPage.type';
import { GadgetForm } from './GadgetForm';

interface CreateGadgetModalProps {
  open: boolean;
  onClose: () => void;
  gadget: GadgetEdit;
  updateGadgetAfterEdit: (data: GadgetDto) => void;
}

export const EditGadgetModal = ({
  open,
  onClose,
  gadget,
  updateGadgetAfterEdit,
}: CreateGadgetModalProps) => {
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();

  const createGadgetModal = (formData: GadgetEdit) => {
    setLoading(true);
    API.put(`${URLS.GADGETS}/${gadget.id}`, formData)
      .then(({ data }) => {
        updateGadgetAfterEdit(data);
        onClose();
        showAlert({
          message: ALERT_TEXT.GADGET_EDIT_SUCCESS,
          severity: 'success',
        });
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal shouldPreventModal title="Edit gadget" open={open} onClose={onClose}>
      <GadgetForm
        loading={loading}
        onSubmit={createGadgetModal}
        onCancel={onClose}
        defaultValue={gadget}
      />
    </Modal>
  );
};
