import HomeIcon from '@mui/icons-material/Home';
import { Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import choiceProjectImage from '../../assets/images/choice-project.jpg';
import {
  BreadcrumbsComponent,
  LinkItem,
} from '../../components/Breadcrumbs/Breadcrumbs';
import { CommonCard } from '../../components/common/CommonCard/CommonCard';
import { locations } from '../../utils/locations';
import styles from './styles.module.css';

const linkList: LinkItem[] = [
  {
    name: 'Projects',
    to: '/',
    icon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
];

export const ProjectsPage = () => {
  const navigate = useNavigate();

  const navigateToTheRoute = (path: string) => {
    navigate(path);
  };

  const cards = [
    {
      title: 'Choice',
      onButtonClicked: () => navigateToTheRoute(locations.choice_app.path),
      image: choiceProjectImage,
      description: 'Choice apple store.',
    },
    {
      title: 'Choice store for clients',
      onButtonClicked: () => navigateToTheRoute(locations.main_page.path),
      image: choiceProjectImage,
      description: 'Choice apple store.',
    },
    {
      title: 'Choice store for business',
      onButtonClicked: () => navigateToTheRoute(locations.main_page.path),
      image: choiceProjectImage,
      description: 'Choice apple store.',
    },
  ];

  return (
    <Container maxWidth="xl">
      <div className={styles['card-container']}>
        <BreadcrumbsComponent linkList={linkList} />
        <div className={styles.cardsList}>
          {cards.map((card) => (
            <CommonCard {...card} key={card.title} />
          ))}
        </div>
      </div>
    </Container>
  );
};
