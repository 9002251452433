import { object, string, type ObjectSchema, number, boolean, mixed } from 'yup';
import { PostTopics } from '../../types';

export const validationSchema: ObjectSchema<any> = object({
  titleEn: string().required(),
  titlePl: string().required(),
  titleUk: string().required(),
  titleRu: string().required(),

  bodyEn: string().required(),
  bodyPl: string().required(),
  bodyUk: string().required(),
  bodyRu: string().required(),

  metaDescriptionRu: string().required(),
  metaDescriptionEn: string().required(),
  metaDescriptionPl: string().required(),
  metaDescriptionUk: string().required(),

  previewTextRu: string().required(),
  previewTextEn: string().required(),
  previewTextPl: string().required(),
  previewTextUk: string().required(),

  altText: string().required(),
  //TODO IFile | null;
  fileInfo: mixed().required(),
  authorFileInfo: mixed().notRequired(),

  author: string().required(),
  authorPosition: string().required(),
  readTime: number().required(),
  topic: mixed().oneOf([PostTopics.TUTORIALS, PostTopics.TECHNOLOGY, PostTopics.COMPANY, PostTopics.EVENTS]).nullable(),
  isTrending: boolean(),

  selectedAuthorFileInfo: mixed().nullable().notRequired()
}).required();
