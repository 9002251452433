import { useEffect, useReducer } from 'react';
import { API } from '../../api/api';
import { URLS } from '../../api/urls';
import { useAlert } from '../../context/Alert.context';
import { GadgetDto } from '../../types/gadgets.types';
import {
  ServiceInfo,
  ServiceInfoFromBack,
} from '../../types/serviceInfo.types';
import { ModalKeys } from './chooseModal';
import { initialState, reducer } from './reducer/reducer';
import { actionServiceInfoTypes } from './reducer/reducer.type';

export const parseServiceInfo = (
  serviceInfo: ServiceInfoFromBack,
): ServiceInfo => {
  return {
    ...serviceInfo,
    infoEn: JSON.parse(serviceInfo.infoEn),
    infoPl: JSON.parse(serviceInfo.infoPl),
    infoUk: JSON.parse(serviceInfo.infoUk),
    infoRu: JSON.parse(serviceInfo.infoRu),
  };
};

export const useServicePage = () => {
  const [
    { gadgets, loading, selectedGadgetId, choosenModal, selectedServiceInfo },
    dispatch,
  ] = useReducer(reducer, initialState);
  const { showAlert } = useAlert();
  useEffect(() => {
    API.get<GadgetDto[]>(`${URLS.GADGETS}?serviceInfo=true`)
      .then(({ data }) => {
        const parsedData = data.map((gadget) => {
          return {
            ...gadget,
            serviceInfo:
              gadget?.serviceInfo?.map((serviceInfo: any) =>
                serviceInfo,
              ) || [],
          };
        });
        dispatch({
          type: actionServiceInfoTypes.SET_GADGETS,
          payload: parsedData,
        });
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }))
      .finally(() =>
        dispatch({ type: actionServiceInfoTypes.SET_LOADING, payload: false }),
      );
  }, []);

  const actions = {
    setModal: (value: ModalKeys | null) =>
      dispatch({
        type: actionServiceInfoTypes.SET_CHOOSEN_MODAL,
        payload: value,
      }),
    setSelectedGadget: (gadget: string | null) =>
      dispatch({
        type: actionServiceInfoTypes.SET_SELECTED_GADGET,
        payload: gadget,
      }),
    setSelectedServiceInfo: (serviceInfo: ServiceInfo) =>
      dispatch({
        type: actionServiceInfoTypes.SET_SELECTED_SERVICE_INFO,
        payload: serviceInfo,
      }),
    closeModal: () =>
      dispatch({
        type: actionServiceInfoTypes.SET_CHOOSEN_MODAL,
        payload: null,
      }),
  };

  const updateAfterAdd = (serviceInfo: ServiceInfoFromBack) => {
    const dataAfterCreating = gadgets.map((gadget: GadgetDto) => {
      if (gadget.id === serviceInfo?.gadget?.id) {
        return {
          ...gadget,
          serviceInfo: [
            parseServiceInfo(serviceInfo),
            ...(gadget.serviceInfo as ServiceInfo[]),
          ],
        };
      }
      return gadget;
    });
    dispatch({
      type: actionServiceInfoTypes.SET_GADGETS,
      payload: dataAfterCreating,
    });
  };

  const updateAfterEdit = (serviceInfo: ServiceInfoFromBack) => {
    const parsedServiceInfo = {
      ...serviceInfo,
      infoEn: JSON.parse(serviceInfo.infoEn),
      infoPl: JSON.parse(serviceInfo.infoPl),
      infoRu: JSON.parse(serviceInfo.infoRu),
      infoUk: JSON.parse(serviceInfo.infoUk)};
    const dataAfterCreating = gadgets.map((gadget: GadgetDto) => {
      if (gadget.id === serviceInfo?.gadget?.id) {
        return {
          ...gadget,
          serviceInfo: gadget.serviceInfo?.map((item) => {
            if (item.id === serviceInfo?.id) {
              return { ...parsedServiceInfo };
            } else return item;
          }),
        };
      }
      return gadget;
    });
    dispatch({
      type: actionServiceInfoTypes.SET_GADGETS,
      payload: dataAfterCreating,
    });
  };

  return {
    gadgets,
    loading,
    selectedGadgetId,
    choosenModal,
    updateAfterAdd,
    selectedServiceInfo,
    updateAfterEdit,
    ...actions,
  };
};
