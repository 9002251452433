export const Ipad = ({
  height = '1em',
  width = '1em',
}: {
  height?: string;
  width?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.1282 0H8.87183C8.11044 0.000862911 7.38048 0.301139 6.8421 0.834954C6.30372 1.36877 6.00087 2.09253 6 2.84746V41.1525C6.00087 41.9075 6.30372 42.6312 6.8421 43.165C7.38048 43.6989 8.11044 43.9991 8.87183 44H35.1282C35.8896 43.9991 36.6195 43.6989 37.1579 43.165C37.6963 42.6312 37.9991 41.9075 38 41.1525V2.84746C37.9991 2.09253 37.6963 1.36877 37.1579 0.834954C36.6195 0.301139 35.8896 0.000862911 35.1282 0ZM36.5641 41.1525C36.5637 41.53 36.4122 41.8919 36.143 42.1588C35.8738 42.4257 35.5089 42.5758 35.1282 42.5763H8.87183C8.49113 42.5758 8.12615 42.4257 7.85696 42.1588C7.58777 41.8919 7.43635 41.53 7.43591 41.1525V2.84746C7.43635 2.46999 7.58777 2.10811 7.85696 1.84121C8.12615 1.5743 8.49113 1.42416 8.87183 1.42373H16.3348L17.1999 2.31721C17.2669 2.38637 17.3474 2.4414 17.4364 2.479C17.5254 2.5166 17.6211 2.53599 17.7179 2.53602H26.2821C26.3789 2.53599 26.4746 2.5166 26.5636 2.479C26.6526 2.4414 26.7331 2.38637 26.8001 2.31721L27.6652 1.42373H35.1282C35.5089 1.42416 35.8738 1.5743 36.143 1.84121C36.4122 2.10811 36.5637 2.46999 36.5641 2.84746V41.1525Z"
        fill="#101010"
      />
    </svg>
  );
};
