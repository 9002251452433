export enum URLS {
  GADGETS = '/gadgets',
  MODELS = '/models',
  SERVICE_INFO = '/service-info',
  SERVICE = '/services',
  POSTS = '/posts',
  MAIL = '/mail',
  TRADE_IN = '/trade-in',
  PAGES = 'pages',
}
