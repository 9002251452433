import { createContext, useContext, useState } from 'react';
import { AlertMsg, ISeverity } from '../components/AlertMsg/AlertMsg';

interface AlertContextProps {
  showAlert: (obj: showAlertProps) => void;
}

interface showAlertProps {
  message: string;
  severity: ISeverity;
}

const AlertContext = createContext<AlertContextProps | undefined>(undefined);

export const useAlert = (): AlertContextProps => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within a AlertProvider');
  }
  return context;
};

export const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<ISeverity>('info');

  const showAlert = ({ message, severity }: showAlertProps) => {
    setOpen(true);
    setMessage(message);
    setSeverity(severity);
  };

  const closeAlert = () => setOpen(false);

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {children}
      <AlertMsg
        message={message}
        closeAlert={closeAlert}
        severity={severity}
        open={open}
      />
    </AlertContext.Provider>
  );
};
