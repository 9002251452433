import styles from './styles.module.css';

interface CardProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title' | 'children'> {
  title: React.ReactNode;
  children: React.ReactNode;
}

export const Card = ({ title, children, ...props }: CardProps) => {
  return (
    <div className={styles.container} {...props}>
      <div className={styles.title}>{title}</div>
      <div className={styles.children}>{children}</div>
    </div>
  );
};
