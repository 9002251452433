import * as React from 'react';
import styles from './styles.module.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface DeleteConfirmationModalProps {
  onClose: () => void;
  open: boolean;
  titleItem?: any;
  onDelete: () => void;
  loading: boolean;
}

export const DeleteConfirmationModal = ({
  onClose,
  open,
  titleItem,
  onDelete,
  loading,
}: DeleteConfirmationModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent sx={{ padding: 5 }}>
        <div className={styles.content}>
          <WarningRoundedIcon sx={{ fontSize: 70 }} color="error" />
          <Typography variant="h5">
            Are you sure you want to delete{' '}
            <span style={{ fontWeight: 'bold' }}>{titleItem || ''}</span>?
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>
          CANCEL
        </Button>
        <LoadingButton
          loading={loading}
          color="error"
          variant="contained"
          onClick={onDelete}
          autoFocus
        >
          DELETE
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
