import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useController } from 'react-hook-form';
import { ServiceInfo } from '../../../../../../types/serviceInfo.types';
import { getIconByName } from '../../../../../ServicesPage/utils/ServiceInfoIcons';
import styles from '../styles.module.css';

export const ServiceInfoSelect = ({
  serviceInfoData,
  name,
  rules,
  title,
  formMethods,
  ...props
}: any) => {
  const {
    field: { onChange, value, ref },
    fieldState: { error },
  } = useController({ name, rules });
  return (
    <FormControl
      className={styles.inputContainer}
      fullWidth
      sx={{ m: 1, minWidth: 120 }}
    >
      <Typography variant="h6">{title}</Typography>
      <div>
        <Select
          error={!!error}
          fullWidth
          value={value}
          onChange={onChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          {...props}
        >
          {!serviceInfoData.length && (
            <MenuItem>
              <Typography variant="h6">
                Please fill service info before creating
              </Typography>
            </MenuItem>
          )}
          {serviceInfoData.map((item: ServiceInfo) => (
            <MenuItem key={item.id} value={item.id}>
              <div className={styles.selectIconItem}>
                {getIconByName(item.icon)}
                {item.titleEn}
              </div>
            </MenuItem>
          ))}
        </Select>
        {error?.message && (
          <Typography color="error" variant="body1">
            This field is required
          </Typography>
        )}
      </div>
    </FormControl>
  );
};
