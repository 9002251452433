import { IPaginationMetaDtoParameters } from '../../types/types';

export enum PostTopics {
  TUTORIALS = 'Tutorials',
  TECHNOLOGY = 'Technology',
  COMPANY = 'Company',
  EVENTS = 'Events',
}

export enum PostSorting {
  DATE = 'Date',
  LIKES = 'Likes',
  TRENDING = 'Trending',
}

export interface IPost {
  id?: string;
  titleEn: string;
  titlePl: string;
  titleUk: string;
  titleRu: string;
  bodyEn: string;
  bodyPl: string;
  bodyUk: string;
  bodyRu: string;
  metaDescriptionRu: string;
  metaDescriptionEn: string;
  metaDescriptionPl: string;
  metaDescriptionUk: string;
  previewTextRu: string;
  previewTextEn: string;
  previewTextPl: string;
  previewTextUk: string;
  slug: string;
  altText: string;
  fileInfo: any;
  authorFileInfo: any;
  likes?: number;
  views?: number;
  author: string;
  authorPosition: string;
  isTrending: boolean;
  readTime: number;
  topic: PostTopics;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface FieldValues {
  titleEn: string;
  titlePl: string;
  titleUk: string;
  titleRu: string;

  bodyEn: string;
  bodyPl: string;
  bodyUk: string;
  bodyRu: string;

  metaDescriptionRu: string;
  metaDescriptionEn: string;
  metaDescriptionPl: string;
  metaDescriptionUk: string;

  previewTextRu: string;
  previewTextEn: string;
  previewTextPl: string;
  previewTextUk: string;

  altText: string;
  //TODO IFile | null;
  fileInfo: any;
  authorFileInfo: any;

  author: string;
  authorPosition: string;
  readTime: number;
  topic: PostTopics | null;
  isTrending: boolean;

  selectedAuthorFileInfo?: string;
}

export interface IBlogResponse {
  data: IPost[];
  meta: IPaginationMetaDtoParameters;
}

export type FieldsArray = Array<keyof FieldValues>;
