import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export type ISeverity = 'success' | 'info' | 'warning' | 'error';

interface AlertMsgProps {
  message: string;
  severity: ISeverity;
  open: boolean;
  closeAlert: () => void;
}

export const AlertMsg = ({
  message,
  severity,
  open,
  closeAlert,
}: AlertMsgProps) => {
  return (
    <Snackbar onClose={closeAlert} open={open} autoHideDuration={3000}>
      <Alert severity={severity} variant="filled" sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};
