export const BrokenDisplayMac = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_9636)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72 52.8667C71.6958 52.8667 71.3886 52.7713 71.1266 52.5733L66.4555 48.7952L61.8105 53.4739V57.2667C61.8105 58.0763 61.1584 58.7333 60.3549 58.7333C59.5514 58.7333 58.8992 58.0763 58.8992 57.2667V54.3333H55.988C55.1845 54.3333 54.5323 53.6763 54.5323 52.8667C54.5323 52.0571 55.1845 51.4 55.988 51.4H59.7523L64.1192 47H60.3549C59.8964 47 59.4655 46.7829 59.1904 46.4133L58.5615 45.5671L56.7958 46.7536C56.1263 47.2024 55.2223 47.0205 54.7769 46.3473C54.3315 45.6727 54.512 44.7619 55.1801 44.3131L56.8119 43.2175L54.8235 40.5467C54.3416 39.8984 54.4712 38.9788 55.1146 38.4933C55.758 38.0079 56.6707 38.1384 57.1525 38.7867L61.0827 44.0667H64.7218C65.0362 44.0667 65.3434 44.1693 65.5952 44.36L72 49.5227V38.4C72 35.9712 70.0436 34 67.6331 34H32.3669C29.9564 34 28 35.9712 28 38.4V61.6C28 64.0288 29.9564 66 32.3669 66H67.6331C70.0436 66 72 64.0288 72 61.6V52.8667Z"
        fill="#1068ED"
      />
      <defs>
        <filter
          id="filter0_b_2954_9636"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_9636"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_9636"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
