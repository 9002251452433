import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HomeIcon from '@mui/icons-material/Home';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { Container, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BreadcrumbsComponent,
  LinkItem,
} from '../../components/Breadcrumbs/Breadcrumbs';
import CustomTablePagination from '../../components/CustomTablePagination/CustomTablePagination';
import { Spinner } from '../../components/Spinner/Spinner';
import { locations } from '../../utils/locations';
import { DeletePostModal } from './components/DeletePostModal';
import styles from './styles.module.css';
import { IPost } from './types';
import { useBlogPage } from './useBlogPage';
import { convertDate, cutText } from './utils/utils';

const linkList: LinkItem[] = [
  {
    name: 'Projects',
    to: '/',
    icon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    name: 'Choice',
    to: '/choice',
    icon: <AccountTreeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    name: 'Blog',
    to: '',
    icon: <NewspaperIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
];

export const BlogPage = () => {
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState('');

  const {
    posts,
    loading,

    selectedPost,
    setSelectedPost,

    openDeleteModal,
    setOpenDeleteModal,

    metaData,
    page,
    quantity,
    order,

    setPage,
    setQuantity,
    setOrder,
    updatePostsAfterDelete,
  } = useBlogPage();

  const onDelete = (post: IPost) => {
    setSelectedPost(post);
    setDeleteId(post.id as string);
    setOpenDeleteModal(true);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setQuantity(+event.target.value);
    setPage(1);
  };

  if (loading)
    return (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );

  return (
    <Container maxWidth="xl">
      <div className={styles.container}>
        <div className={styles.header}>
          <Typography variant="h2">Posts</Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate(locations.choice_blog_new.path)}
          >
            Add
          </Button>
        </div>
        <BreadcrumbsComponent linkList={linkList} />
        {!posts.length && (
          <div className={styles.loader}>
            <Typography variant="h2">Table is empty.</Typography>
          </div>
        )}
        {!!posts.length && (
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className={styles.tableHeader}>
                  <TableRow>
                    {[
                      'Post',
                      'Created Date',
                      'Updated Date',
                      'Author',
                      'Topic',
                      'Likes',
                      'Views',
                      'Trending',
                    ].map((item) => (
                      <TableCell className={styles.text} key={item}>
                        {item}
                      </TableCell>
                    ))}
                    <TableCell className={styles.text} align="right">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {posts.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '& > *': { borderBottom: 'unset' } }}
                    >
                      <TableCell align="left" component="th" scope="row">
                        {cutText(row.titleEn)}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {convertDate(row.createdAt as Date)}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {convertDate(row.updatedAt as Date)}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {row.author}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {row.topic}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {row.likes}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {row.views}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {row.isTrending ? '+' : '-'}
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.action}>
                          <EditIcon
                            onClick={() =>
                              navigate(
                                `${locations.choice_blog.path}/${row.slug}`,
                              )
                            }
                          />
                          <DeleteIcon
                            color="error"
                            onClick={() => onDelete(row)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {metaData && (
              <CustomTablePagination
                count={metaData.pageCount}
                rowsPerPage={quantity}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Paper>
        )}
      </div>
      {selectedPost && (
        <DeletePostModal
          onClose={() => setOpenDeleteModal(false)}
          open={openDeleteModal}
          postId={deleteId}
          updatePostsAfterDelete={updatePostsAfterDelete}
        />
      )}
    </Container>
  );
};
