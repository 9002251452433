import {
  Avatar,
  Box,
  Button,
  List,
  ListItemAvatar,
  Typography,
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import { Link } from 'react-router-dom';
import choiceLogo from '../../assets/images/choice-logo.png';
import { useAuth } from '../../context/Auth.context';
import { locations } from '../../utils/locations';
import styles from './styles.module.css';

type NavLinks = {
  name: string;
  link: string;
  image: string;
};

const navLinks: NavLinks[] = [
  {
    name: 'Choice',
    link: locations.choice_app.path,
    image: choiceLogo,
  },
  {
    name: 'Client store',
    link: '',
    image: choiceLogo,
  },
  {
    name: 'Business store',
    link: '',
    image: choiceLogo,
  },
];

export const Sidebar = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setIsAuth } = useAuth();

  const handleLogout = () => {
    setIsAuth(false);
    localStorage.removeItem('token');
  };

  return (
    <Drawer open={open} onClose={onClose}>
      <Box sx={{ width: 250, padding: 3, height: '100%' }} role="presentation">
        <div className={styles.linksWrapper}>
          <Typography variant="h6">SELECT APP</Typography>
          <List>
            {navLinks.map(({ name, link, image }) => (
              <ListItem key={name} disablePadding>
                <Link className={styles.linkItem} to={link} onClick={onClose}>
                  <ListItemAvatar>
                    <Avatar className={styles.avatar}>
                      <img src={image} alt="choice-logo" />
                    </Avatar>
                  </ListItemAvatar>
                  <Typography textTransform={'capitalize'} variant="button">
                    {name}
                  </Typography>
                </Link>
              </ListItem>
            ))}
          </List>
        </div>
        <Button
          fullWidth
          variant="contained"
          color="error"
          onClick={handleLogout}
        >
          Log out
        </Button>
      </Box>
    </Drawer>
  );
};
