export const MacBook = ({
  height = '1em',
  width = '1em',
}: {
  height?: string;
  width?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2600_8485)">
        <path
          d="M40.137 8H5.88061C5.11923 8.00086 4.38927 8.30114 3.85089 8.83495C3.31251 9.36877 3.00966 10.0925 3.00879 10.8475V31.1525C3.00966 31.9075 3.31251 32.6312 3.85089 33.165C4.38927 33.6989 5.11923 33.9991 5.88061 34H40.137C40.8983 33.9991 41.6283 33.6989 42.1667 33.165C42.7051 32.6312 43.0079 31.9075 43.0088 31.1525V10.8475C43.0079 10.0925 42.7051 9.36877 42.1667 8.83495C41.6283 8.30114 40.8983 8.00086 40.137 8ZM41.5729 31.1525C41.5724 31.53 41.421 31.8919 41.1518 32.1588C40.8826 32.4257 40.5177 32.5758 40.137 32.5763H5.88061C5.49992 32.5758 5.13494 32.4257 4.86575 32.1588C4.59656 31.8919 4.44514 31.53 4.4447 31.1525V10.8475C4.44514 10.47 4.59656 10.1081 4.86575 9.84121C5.13494 9.5743 5.49992 9.42416 5.88061 9.42373H17.3436L18.2087 10.3172C18.2757 10.3864 18.3561 10.4414 18.4452 10.479C18.5342 10.5166 18.6299 10.536 18.7266 10.536H27.2909C27.3877 10.536 27.4834 10.5166 27.5724 10.479C27.6614 10.4414 27.7419 10.3864 27.8089 10.3172L28.674 9.42373H40.137C40.5177 9.42416 40.8826 9.5743 41.1518 9.84121C41.421 10.1081 41.5724 10.47 41.5729 10.8475V31.1525Z"
          fill="#101010"
        />
        <rect x="0.5" y="35" width="44" height="1.5" rx="0.75" fill="#101010" />
      </g>
      <defs>
        <clipPath id="clip0_2600_8485">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
