import { Typography } from '@mui/material';
import { useState } from 'react';
import { API } from '../../../../../../api/api';
import { URLS } from '../../../../../../api/urls';
import { Modal } from '../../../../../../components/Modal/Modal';
import { ALERT_TEXT } from '../../../../../../constants/content';
import { useAlert } from '../../../../../../context/Alert.context';
import { ServiceInfo } from '../../../../../../types/serviceInfo.types';
import { Service } from '../../../../../../types/services.types';
import styles from '../../../../../ServicesPage/modals/styles.module.css';
import { ServiceForm } from '../components/ServiceForm';

interface Props {
  open: boolean;
  onClose: () => void;
  serviceInfo: ServiceInfo[];
  onEdit: (service: Service) => void;
  service: Service;
}

export const EditServiceModal = ({
  open,
  onClose,
  serviceInfo,
  onEdit,
  service: defaultService,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();

  const onEditService = (service: Service) => {
    setLoading(true);
    API.put(`${URLS.SERVICE}/${defaultService.id}`, service)
      .then((res) => {
        onEdit(res.data);
        onClose();
        showAlert({
          message: ALERT_TEXT.SERVICE_EDIT_SUCCESS,
          severity: 'success',
        });
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      shouldPreventModal
      title={
        <div className={styles.modalHeader}>
          <Typography variant="h6">Edit service</Typography>
        </div>
      }
      open={open}
      onClose={onClose}
    >
      <ServiceForm
        serviceInfo={serviceInfo}
        service={defaultService}
        createService={onEditService}
      />
    </Modal>
  );
};
