import { actionMailsTypes, ActionTypes, IInitialState } from './reducer.type';

export const initialState: IInitialState = {
  mails: [],
  metaData: null,
  page: 1,
  quantity: 10,
  order: 'DESC',
};

export const reducer = (
  state = initialState,
  action: ActionTypes,
): IInitialState => {
  switch (action.type) {
    case actionMailsTypes.SET_MAILS:
      return { ...state, mails: action.payload };
    case actionMailsTypes.SET_META_DATA:
      return { ...state, metaData: action.payload };
    case actionMailsTypes.SET_PAGE:
      return { ...state, page: action.payload };
    case actionMailsTypes.SET_QUANTITY:
      return { ...state, quantity: action.payload };
    case actionMailsTypes.SET_ORDER:
      return { ...state, order: action.payload };
    default:
      return state;
  }
};
