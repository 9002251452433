import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from '@mui/material';
import styles from './styles.module.css';
import { GadgetsIcon } from './icons';

export const IconSelect = ({ value, onChange, ...props }: any) => {
  return (
    <div className={styles.inputContainer}>
      <Typography variant="h6">Choose icon</Typography>
      <div>
        <Select
          fullWidth
          defaultValue={value}
          value={value}
          onChange={onChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          {...props}
        >
          {GadgetsIcon.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <div className={styles.selectIconItem}>
                {item.icon}
                {item.label}
              </div>
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};
