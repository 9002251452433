export const Microphone = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_9199)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <path
        d="M49.9999 58.4608C52.3271 58.4608 54.3187 57.6323 55.9757 55.9759C57.6323 54.3196 58.4608 52.327 58.4608 50V36.4618C58.4608 34.1347 57.633 32.1429 55.9757 30.4858C54.3187 28.829 52.3271 28 49.9999 28C47.6729 28 45.681 28.829 44.024 30.4858C42.3668 32.1427 41.5384 34.1347 41.5384 36.4618V50C41.5384 52.3269 42.3672 54.3196 44.024 55.9759C45.6808 57.6323 47.6729 58.4608 49.9999 58.4608Z"
        fill="#1068ED"
      />
      <path
        d="M64.7277 45.4254C64.3937 45.0904 63.9962 44.9229 63.5379 44.9229C63.08 44.9229 62.6834 45.0904 62.348 45.4254C62.0134 45.7603 61.846 46.1569 61.846 46.615V49.9998C61.846 53.2612 60.6866 56.0506 58.3685 58.3687C56.051 60.6869 53.2612 61.8459 49.9998 61.8459C46.7384 61.8459 43.9489 60.6869 41.6306 58.3687C39.3126 56.0513 38.1538 53.2613 38.1538 49.9998V46.615C38.1538 46.1569 37.9862 45.7603 37.6514 45.4254C37.3164 45.0904 36.9202 44.9229 36.4616 44.9229C36.003 44.9229 35.6063 45.0904 35.2715 45.4254C34.9364 45.7603 34.769 46.1569 34.769 46.615V49.9998C34.769 53.8957 36.0693 57.2848 38.6692 60.1667C41.2691 63.0487 44.4819 64.7013 48.3074 65.124V68.6148H41.5383C41.0799 68.6148 40.6833 68.7825 40.3484 69.1175C40.0135 69.4522 39.8459 69.849 39.8459 70.3073C39.8459 70.7649 40.0135 71.1624 40.3484 71.4972C40.6833 71.8319 41.0799 71.9999 41.5383 71.9999H58.4608C58.9191 71.9999 59.3162 71.832 59.6506 71.4972C59.986 71.1625 60.1536 70.765 60.1536 70.3073C60.1536 69.8491 59.986 69.4523 59.6506 69.1175C59.3163 68.7825 58.9191 68.6148 58.4608 68.6148H51.6925V65.124C55.5174 64.7013 58.7298 63.0487 61.3301 60.1667C63.9304 57.2848 65.231 53.8957 65.231 49.9998V46.615C65.231 46.1569 65.0631 45.7606 64.7277 45.4254Z"
        fill="#1068ED"
      />
      <defs>
        <filter
          id="filter0_b_2954_9199"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_9199"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_9199"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
