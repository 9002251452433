export const RadiatorReplacement = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_10963)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <path d="M30.5781 35.0898H28V37.668H30.5781V35.0898Z" fill="#1068ED" />
      <path d="M72 35.0898H69.4219V37.668H72V35.0898Z" fill="#1068ED" />
      <path
        d="M54.7549 32.5117H51.2891V60.1836H54.7549V32.5117Z"
        fill="#1068ED"
      />
      <path
        d="M66.8438 32.5117H63.3779V60.1836H66.8438V32.5117Z"
        fill="#1068ED"
      />
      <path
        d="M36.6221 32.5117H33.1562V60.1836H36.6221V32.5117Z"
        fill="#1068ED"
      />
      <path
        d="M42.6661 32.5117H39.2002V60.1836H42.6661V32.5117Z"
        fill="#1068ED"
      />
      <path
        d="M48.711 32.5117H45.2451V60.1836H48.711V32.5117Z"
        fill="#1068ED"
      />
      <path
        d="M60.7989 32.5117H57.333V60.1836H60.7989V32.5117Z"
        fill="#1068ED"
      />
      <path
        d="M36.1787 62.7617H33.6006V67.4883H36.1787V62.7617Z"
        fill="#1068ED"
      />
      <path
        d="M66.3994 62.7617H63.8213V67.4883H66.3994V62.7617Z"
        fill="#1068ED"
      />
      <defs>
        <filter
          id="filter0_b_2954_10963"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_10963"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_10963"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
