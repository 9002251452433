import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { Container, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  BreadcrumbsComponent,
  LinkItem,
} from '../../components/Breadcrumbs/Breadcrumbs';
import { Spinner } from '../../components/Spinner/Spinner';
import { IPagesDto } from './PagesPage.type';
import { ChooseModal, MODALS } from './chooseModal';
import { Row } from './components/Row';
import styles from './styles.module.css';
import { usePagesPage } from './usePagesPage';

const linkList: LinkItem[] = [
  {
    name: 'Projects',
    to: '/',
    icon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    name: 'Choice',
    to: '/choice',
    icon: <AccountTreeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    name: 'Pages',
    to: '',
    icon: <PostAddIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
];

export const PagesPage = () => {
  const {
    pages,
    loading,
    setModal,
    setSelectedPage,
    selectedPage,
    choosenModal,
    closeModal,
    updatePageAfterDelete,
    updatePageAfterCreate,
    updatePageAfterEdit,
  } = usePagesPage();
  if (loading)
    return (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );

  return (
    <Container maxWidth="xl">
      <div className={styles.container}>
        <div className={styles.header}>
          <Typography variant="h2">Pages</Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setModal(MODALS.CREATE_PAGE);
            }}
          >
            Add
          </Button>
        </div>
        <BreadcrumbsComponent linkList={linkList} />
        {!pages.length && (
          <div className={styles.loader}>
            <Typography variant="h2">Table is empty.</Typography>
          </div>
        )}
        {!!pages.length && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className={styles.tableHeader}>
                <TableRow>
                  {['Page'].map((item) => (
                    <TableCell className={styles.text} key={item}>
                      {item}
                    </TableCell>
                  ))}
                  <TableCell className={styles.text} align="right">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pages.map((row: IPagesDto) => (
                  <Row
                    key={row.id}
                    row={row}
                    onPageEdit={() => {
                      setSelectedPage(row);
                      setModal(MODALS.EDIT_PAGE);
                    }}
                    onPageDelete={() => {
                      setSelectedPage(row);
                      setModal(MODALS.DELETE_PAGE);
                    }}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <ChooseModal
        updatePageAfterDelete={updatePageAfterDelete}
        updatePageAfterCreate={updatePageAfterCreate}
        updatePageAfterEdit={updatePageAfterEdit}
        choosenModal={choosenModal}
        selectedPage={selectedPage}
        closeModal={closeModal}
      />
    </Container>
  );
};
