import { useMemo } from 'react';
import { GadgetDto } from '../../types/gadgets.types';
import { ModelDto } from '../../types/models.types';
import { CreateGadgetModal } from './components/GadgetModal/CreateGadgetModal';
import { DeleteGadgetModal } from './components/GadgetModal/DeleteGadgetModal';
import { EditGadgetModal } from './components/GadgetModal/EditGadgetModal';
import { CreateModelModal } from './components/ModelModal/CreateModelModal';
import { DeleteModelModal } from './components/ModelModal/DeleteModelModal';
import { EditModelModal } from './components/ModelModal/EditModelModal';
import { ModelEdit } from './GadgetsPage.type';

export enum MODALS {
  CREATE_GADGET = 'CREATE_GADGET',
  EDIT_GADGET = 'EDIT_GADGET',
  DELETE_GADGET = 'DELETE_GADGET',
  CREATE_MODEL = 'CREATE_MODEL',
  EDIT_MODEL = 'EDIT_MODEL',
  DELETE_MODEL = 'DELETE_MODEL',
}

export type ModalKeys = keyof typeof MODALS;

interface chooseModalProps {
  choosenModal: ModalKeys | null;
  updateGadgetAfterDelete: () => void;
  updateGadgetAfterCreate: (gadget: GadgetDto) => void;
  updateGadgetAfterEdit: (editedGadget: GadgetDto) => void;
  updateGadgetAfterModelDelete: () => void;
  updateGadgetAfterCreateModel: (model: ModelDto) => void;
  updateGadgetAfterEditModel: (model: ModelEdit) => void;
  selectedGadget: GadgetDto | null;
  selectedModel: ModelDto | null;
  closeModal: () => void;
}

export const ChooseModal = ({
  selectedModel,
  selectedGadget,
  closeModal,
  choosenModal,
  updateGadgetAfterCreateModel,
  updateGadgetAfterModelDelete,
  updateGadgetAfterEdit,
  updateGadgetAfterDelete,
  updateGadgetAfterCreate,
  updateGadgetAfterEditModel,
}: chooseModalProps) => {
  const modal = useMemo(() => {
    switch (choosenModal) {
      case MODALS.CREATE_GADGET:
        return (
          <CreateGadgetModal
            updateGadgetAfterCreate={updateGadgetAfterCreate}
            open
            onClose={() => closeModal()}
          />
        );
      case MODALS.EDIT_GADGET:
        return (
          selectedGadget && (
            <EditGadgetModal
              updateGadgetAfterEdit={updateGadgetAfterEdit}
              gadget={selectedGadget}
              open
              onClose={() => closeModal()}
            />
          )
        );
      case MODALS.DELETE_GADGET:
        return (
          selectedGadget && (
            <DeleteGadgetModal
              updateGadgetAfterDelete={updateGadgetAfterDelete}
              item={selectedGadget}
              onClose={() => closeModal()}
              open
            />
          )
        );
      case MODALS.CREATE_MODEL:
        return (
          selectedModel && (
            <CreateModelModal
              open
              selectedModel={selectedModel}
              onClose={() => closeModal()}
              updateGadgetAfterCreateModel={updateGadgetAfterCreateModel}
            />
          )
        );
      case MODALS.DELETE_MODEL:
        return (
          selectedModel && (
            <DeleteModelModal
              updateGadgetAfterModelDelete={updateGadgetAfterModelDelete}
              model={selectedModel}
              onClose={() => closeModal()}
              open
            />
          )
        );
      case MODALS.EDIT_MODEL:
        return (
          selectedModel && (
            <EditModelModal
              updateGadgetAfterEditModel={updateGadgetAfterEditModel}
              selectedModel={selectedModel}
              onClose={() => closeModal()}
              open
            />
          )
        );
      default:
        return <></>;
    }
  }, [
    choosenModal,
    closeModal,
    selectedGadget,
    selectedModel,
    updateGadgetAfterCreate,
    updateGadgetAfterCreateModel,
    updateGadgetAfterDelete,
    updateGadgetAfterEdit,
    updateGadgetAfterModelDelete,
  ]);

  return modal;
};
