export const NoWifi = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_9313)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <g clipPath="url(#clip0_2954_9313)">
        <path
          d="M62.4266 52.8843C57.1478 52.8843 52.8532 57.1789 52.8532 62.4577C53.3345 75.1408 71.5207 75.1372 72.0001 62.4576C72 57.1788 67.7054 52.8843 62.4266 52.8843ZM65.2907 65.3218C64.7873 65.8253 63.9711 65.8253 63.4677 65.3218L62.4266 64.2807L61.3854 65.3218C60.882 65.8253 60.0659 65.8253 59.5624 65.3218C59.059 64.8184 59.059 64.0023 59.5624 63.4988L60.6036 62.4577L59.5624 61.4166C59.059 60.9132 59.059 60.097 59.5624 59.5936C60.0659 59.0902 60.882 59.0902 61.3854 59.5936L62.4266 60.6346L63.4677 59.5935C63.9711 59.0901 64.7873 59.0901 65.2907 59.5935C65.7941 60.0969 65.7941 60.9131 65.2907 61.4165L64.2496 62.4576L65.2907 63.4988C65.7941 64.0023 65.7941 64.8184 65.2907 65.3218Z"
          fill="#1068ED"
        />
        <path
          d="M52.9892 49.5181C56.9612 53.2102 62.4722 47.7078 58.7777 43.7296C52.7996 37.7516 43.1071 37.7516 37.129 43.7296C35.5306 45.3281 35.5306 47.9196 37.129 49.5181C38.7275 51.1165 41.319 51.1165 42.9175 49.5181C45.6987 46.7368 50.208 46.7368 52.9892 49.5181Z"
          fill="#1068ED"
        />
        <path
          d="M60.9194 41.588C64.8859 45.2789 70.4045 39.7811 66.7077 35.7996C56.3499 25.4418 39.5566 25.4418 29.1988 35.7996C27.6004 37.398 27.6004 39.9896 29.1988 41.588C30.7973 43.1864 33.3888 43.1864 34.9872 41.588C42.1481 34.427 53.7584 34.427 60.9194 41.588Z"
          fill="#1068ED"
        />
        <path
          d="M47.9533 50.4609C45.6928 50.4609 43.8603 52.2935 43.8603 54.554C44.0609 59.9774 51.8464 59.9759 52.0463 54.5539C52.0463 52.2935 50.2138 50.4609 47.9533 50.4609Z"
          fill="#1068ED"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_2954_9313"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_9313"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_9313"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2954_9313">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(28 28)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
