import AccountTreeIcon from '@mui/icons-material/AccountTree';
import EditNoteIcon from '@mui/icons-material/EditNote';
import HomeIcon from '@mui/icons-material/Home';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API } from '../../../../api/api';
import { URLS } from '../../../../api/urls';
import {
  BreadcrumbsComponent,
  LinkItem,
} from '../../../../components/Breadcrumbs/Breadcrumbs';
import { Spinner } from '../../../../components/Spinner/Spinner';
import { useAlert } from '../../../../context/Alert.context';
import { locations } from '../../../../utils/locations';
import { PostForm } from '../../components/PostForm/PostForm';
import { FieldValues, IPost } from '../../types';
import styles from '../styles.module.css';

const linkList: LinkItem[] = [
  {
    name: 'Projects',
    to: '/',
    icon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    name: 'Choice',
    to: '/choice',
    icon: <AccountTreeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    name: 'Blog',
    to: '/choice/blog',
    icon: <NewspaperIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    name: 'Edit blog',
    to: '',
    icon: <EditNoteIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
];

export const EditPostPage = () => {
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState(true);
  const [post, setPost] = useState<IPost | null>(null);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    API.get<IPost>(`${URLS.POSTS}/${id}?isAdmin=true`)
      .then(({ data }) => {
        setPost(data);
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }))
      .finally(() => setLoading(false));
  }, []);

  const editPost = (dataFromForm: FieldValues) => {
    const formData = new FormData();
    formData.append('fileInfo', dataFromForm.fileInfo as File);
    formData.append('authorFileInfo', dataFromForm.authorFileInfo as File);
    formData.append(
      'selectedAuthorFileInfo',
      dataFromForm.selectedAuthorFileInfo as string,
    );
    formData.append('altText', dataFromForm.altText);

    formData.append('author', dataFromForm.author);
    formData.append('authorPosition', dataFromForm.authorPosition);
    formData.append('readTime', dataFromForm.readTime.toString());
    formData.append('topic', dataFromForm.topic as string);
    formData.append('isTrending', JSON.stringify(dataFromForm.isTrending));

    formData.append('metaDescriptionRu', dataFromForm.metaDescriptionRu);
    formData.append('metaDescriptionEn', dataFromForm.metaDescriptionEn);
    formData.append('metaDescriptionPl', dataFromForm.metaDescriptionPl);
    formData.append('metaDescriptionUk', dataFromForm.metaDescriptionUk);

    formData.append('previewTextRu', dataFromForm.previewTextRu);
    formData.append('previewTextEn', dataFromForm.previewTextEn);
    formData.append('previewTextPl', dataFromForm.previewTextPl);
    formData.append('previewTextUk', dataFromForm.previewTextUk);

    formData.append('bodyEn', dataFromForm.bodyEn);
    formData.append('bodyPl', dataFromForm.bodyPl);
    formData.append('bodyUk', dataFromForm.bodyUk);
    formData.append('bodyRu', dataFromForm.bodyRu);

    formData.append('titleEn', dataFromForm.titleEn);
    formData.append('titlePl', dataFromForm.titlePl);
    formData.append('titleUk', dataFromForm.titleUk);
    formData.append('titleRu', dataFromForm.titleRu);

    API.put(`${URLS.POSTS}/${post?.id}`, formData)
      .then(({ data }) => {
        navigate(locations.choice_blog.path);
      })
      .then((e) =>
        showAlert({ message: 'Post has been updated', severity: 'success' }),
      )
      .catch((e) => showAlert({ message: e.message, severity: 'error' }));
  };

  if (loading)
    return (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );

  return (
    <div className={styles.container}>
      <Typography className={styles.title} variant="h2">
        Edit new post
      </Typography>
      <BreadcrumbsComponent linkList={linkList} />
      <div className={styles.form}>
        <PostForm post={post} onAction={editPost} />
      </div>
    </div>
  );
};
