export const AddingMemory = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_11108)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <g clipPath="url(#clip0_2954_11108)">
        <path
          d="M30.8361 40.4201H69.1611V55.3243H30.8361V40.4201ZM29.4166 37.5784C28.6324 37.5812 27.9972 38.2164 28 39.0007V61.0021C28 61.7842 28.6345 62.4187 29.4166 62.4215H41.4848C42.2662 62.4187 42.9014 61.7842 42.9014 61.0021V58.166H44.3208V61.0021C44.3208 61.7842 44.956 62.4187 45.7403 62.4215H70.5806C71.3627 62.4187 71.9972 61.7863 72 61.0021V39.0007C72 38.2164 71.3648 37.5784 70.5806 37.5784H29.4166Z"
          fill="#1068ED"
        />
        <path
          d="M61.3543 41.8396C60.57 41.8396 59.9348 42.4748 59.9348 43.2591V52.4883C59.9377 53.2704 60.5721 53.9049 61.3543 53.9049H66.3195C67.1037 53.9077 67.7418 53.2725 67.7418 52.4883V43.2591C67.7418 42.472 67.1066 41.8368 66.3195 41.8396H61.3543Z"
          fill="#1068ED"
        />
        <path
          d="M52.1277 41.8396C51.3435 41.8396 50.7111 42.4748 50.7083 43.2591V52.4883C50.7111 53.2704 51.3456 53.9049 52.1277 53.9049H57.0958C57.88 53.9077 58.5152 53.2725 58.518 52.4883V43.2591C58.518 42.472 57.8807 41.8368 57.0958 41.8396H52.1277Z"
          fill="#1068ED"
        />
        <path
          d="M42.9015 41.8396C42.1194 41.8396 41.4849 42.4748 41.4849 43.259V52.4883C41.4849 53.2704 42.1194 53.9049 42.9015 53.9049H47.8695C48.6516 53.9049 49.2861 53.2697 49.2861 52.4883V43.259C49.2861 42.4748 48.6516 41.8396 47.8695 41.8396H42.9015Z"
          fill="#1068ED"
        />
        <path
          d="M33.6749 41.8396C32.8928 41.8396 32.2583 42.4748 32.2583 43.259V52.4883C32.2583 53.2704 32.8928 53.9049 33.6749 53.9049H38.643C39.4251 53.9049 40.0596 53.2697 40.0596 52.4883V43.259C40.0596 42.4748 39.4251 41.8396 38.643 41.8396H33.6749Z"
          fill="#1068ED"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_2954_11108"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_11108"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_11108"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2954_11108">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(28 28)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
