import { actionGadgetTypes, ActionTypes, IInitialState } from './reducer.type';

export const initialState: IInitialState = {
  gadgets: [],
  loading: true,
  choosenModal: null,
  selectedGadget: null,
  selectedModel: null,
};

export const reducer = (
  state = initialState,
  action: ActionTypes,
): IInitialState => {
  switch (action.type) {
    case actionGadgetTypes.SET_GADGETS:
      return { ...state, gadgets: action.payload };
    case actionGadgetTypes.SET_LOADING:
      return { ...state, loading: action.payload };
    case actionGadgetTypes.SET_CHOOSEN_MODAL:
      return { ...state, choosenModal: action.payload };
    case actionGadgetTypes.SET_SELECTED_GADGET:
      return { ...state, selectedGadget: action.payload };
    case actionGadgetTypes.SET_SELECTED_MODEL:
      return { ...state, selectedModel: action.payload };
    default:
      return state;
  }
};
