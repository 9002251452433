import React, { useRef, useState } from 'react';
import { FormState, useForm } from 'react-hook-form';
import { FieldValues, IPost } from '../../types';
import { mapToFieldValues } from '../../utils/mapToFieldValues';
import {
  ACTIVE_TAB,
  EN_FIELD_NAMES,
  PL_FIELD_NAMES,
  RU_FIELD_NAMES,
  UK_FIELD_NAMES,
} from './consts';
import { validationSchema } from './validationSchema';
import { yupResolver } from '@hookform/resolvers/yup';

interface usePostFormProps {
  post: IPost | null;
  onAction: (post: FieldValues) => void;
}

export const hasNonEmptyValues = (
  allDirtyFields:
    | FormState<FieldValues>['dirtyFields']
    | FormState<FieldValues>['errors'],
  fieldsToCheck: Array<keyof FieldValues>,
) => fieldsToCheck.some((fieldName) => allDirtyFields[fieldName]);

export const usePostForm = ({ post, onAction }: usePostFormProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(ACTIVE_TAB.POLISH);
  const [loading, setLoading] = useState(false);

  const formMethods = useForm<FieldValues>({
    defaultValues: mapToFieldValues(post),
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  const selectedAuthorFileInfo = formMethods.watch('selectedAuthorFileInfo');

  const [previewUrl, setPreviewUrl] = useState<string | ''>(
    post
      ? `${process.env.REACT_APP_API_URL_STATIC}/posts/${post?.fileInfo?.fileName}`
      : '',
  );
  const [authorPreviewUrl, setAuthorPreviewUrl] = useState<string>(
    post && post.authorFileInfo && post.authorFileInfo.length > 0
      ? `${process.env.REACT_APP_API_URL_STATIC}/posts/author/${post.authorFileInfo[0].fileName}`
      : '',
  );

  const [fileName, setFileName] = useState<string>('');
  const [authorFileName, setAuthorFileName] = useState<string>('');

  const fileInputRef = useRef<HTMLInputElement>(null);
  const authorFileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFileName(file.name);
      formMethods.setValue('fileInfo', file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAuthorFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setAuthorFileName(file.name);
      formMethods.setValue('authorFileInfo', file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setAuthorPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveAuthorImage = () => {
    setAuthorFileName('');
    formMethods.setValue('authorFileInfo', '');
    setAuthorPreviewUrl('');

    if (authorFileInputRef.current) {
      authorFileInputRef.current.value = '';
    }
  };

  const handleRemoveFile = () => {
    setFileName('');
    formMethods.setValue('fileInfo', '');
    setPreviewUrl('');

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const onSubmit = () => {
    setLoading(true);
    formMethods
      .handleSubmit(
        (data) => {
          onAction(data);
        },
        (_) => {},
      )()
      .finally(() => setLoading(false));
  };

  const onTabClick = (_: React.SyntheticEvent, nextIndex: number) => {
    setActiveTabIndex(nextIndex);
  };

  return {
    formMethods,
    onSubmit,
    loading,

    fileInputRef,
    authorFileInputRef,

    handleRemoveFile,
    handleFileChange,
    previewUrl,
    fileName,

    handleAuthorFileChange,
    handleRemoveAuthorImage,
    authorPreviewUrl,
    authorFileName,

    activeTabIndex,
    onTabClick,

    isEnTabValid: !hasNonEmptyValues(
      formMethods.formState.errors,
      EN_FIELD_NAMES,
    ),
    isPlTabValid: !hasNonEmptyValues(
      formMethods.formState.errors,
      PL_FIELD_NAMES,
    ),
    isEuTabValid: !hasNonEmptyValues(
      formMethods.formState.errors,
      UK_FIELD_NAMES,
    ),
    isRuTabValid: !hasNonEmptyValues(
      formMethods.formState.errors,
      RU_FIELD_NAMES,
    ),

    selectedAuthorFileInfo,
  };
};
