export const PayPass = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_9289)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <g clipPath="url(#clip0_2954_9289)">
        <path
          d="M64.3257 32H35.6746C31.4426 32 28.0002 35.4424 28.0002 39.6744V60.1395C28.0002 64.3715 31.4426 67.814 35.6746 67.814H64.3257C68.5577 67.814 72.0002 64.3715 72.0002 60.1395V39.6744C72.0002 35.4424 68.5577 32 64.3257 32ZM41.8121 59.631C40.9646 59.631 40.2772 58.9436 40.2772 58.0961C40.2772 56.685 39.1301 55.538 37.7191 55.538C36.8716 55.538 36.1842 54.8505 36.1842 54.0031C36.1842 53.1556 36.8716 52.4682 37.7191 52.4682C40.8228 52.4682 43.347 54.9924 43.347 58.0961C43.347 58.9436 42.6595 59.631 41.8121 59.631ZM47.9516 59.631C47.1041 59.631 46.4167 58.9436 46.4167 58.0961C46.4167 53.3005 42.5146 49.3984 37.7191 49.3984C36.8716 49.3984 36.1842 48.711 36.1842 47.8635C36.1842 47.0161 36.8716 46.3287 37.7191 46.3287C44.2073 46.3287 49.4865 51.6078 49.4865 58.0961C49.4865 58.9436 48.7991 59.631 47.9516 59.631ZM54.0952 59.625C53.2478 59.625 52.5604 58.9376 52.5604 58.0902C52.5604 49.909 45.9043 43.2529 37.7231 43.2529C36.8757 43.2529 36.1883 42.5655 36.1883 41.7181C36.1883 40.8706 36.8757 40.1832 37.7231 40.1832C47.5969 40.1832 55.6301 48.2164 55.6301 58.0902C55.6301 58.9376 54.9427 59.625 54.0952 59.625Z"
          fill="#1068ED"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_2954_9289"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_9289"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_9289"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2954_9289">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(28 28)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
