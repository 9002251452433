import { useEffect, useReducer, useState } from 'react';
import { API } from '../../api/api';
import { URLS } from '../../api/urls';
import { useAlert } from '../../context/Alert.context';
import { IPaginationMetaDtoParameters } from '../../types/types';
import { IMail, IMailResponse } from './MailsPage.type';
import { initialState, reducer } from './reducer/reducer';
import { actionMailsTypes } from './reducer/reducer.type';

export const useMailsPage = () => {
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState(true);
  const [{ mails, metaData, page, quantity, order }, dispatch] = useReducer(
    reducer,
    initialState,
  );

  useEffect(() => {
    API.get<IMailResponse>(
      `${URLS.MAIL}?page=${page}&limit=${quantity}&order=${order}`,
    )
      .then(({ data }) => {
        actions.setMails(data.data);
        actions.setMetaData(data.meta);
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }))
      .finally(() => setLoading(false));
  }, [page, quantity, order]);

  const actions = {
    setMails: (data: IMail[]) => {
      const updatedData = data.map((mail) => ({
        ...mail,
        createdAt: new Date(mail.createdAt).toString(),
      }));

      return dispatch({
        type: actionMailsTypes.SET_MAILS,
        payload: updatedData,
      });
    },
    setMetaData: (metaData: IPaginationMetaDtoParameters) => {
      return dispatch({
        type: actionMailsTypes.SET_META_DATA,
        payload: metaData,
      });
    },
    setPage: (page: number) => {
      return dispatch({
        type: actionMailsTypes.SET_PAGE,
        payload: page,
      });
    },
    setQuantity: (quantity: number) => {
      return dispatch({
        type: actionMailsTypes.SET_QUANTITY,
        payload: quantity,
      });
    },
    setOrder: (order: string) => {
      return dispatch({
        type: actionMailsTypes.SET_ORDER,
        payload: order,
      });
    },
  };

  const updateMailsAfterDelete = (mailId: string) => {
    const updatedMails = mails.filter((mail) => mail.id !== mailId);
    dispatch({
      type: actionMailsTypes.SET_MAILS,
      payload: updatedMails,
    });
  };

  return {
    mails,
    metaData,
    loading,
    page,
    quantity,
    order,
    updateMailsAfterDelete,
    ...actions,
  };
};
