import { useMemo } from 'react';
import {
  ServiceInfo,
  ServiceInfoFromBack,
} from '../../types/serviceInfo.types';
import { CreateServiceInfoModal } from './modals/CreateServiceInfoModal';
import { EditServiceInfoModal } from './modals/EditServiceInfoModal';

export enum MODALS {
  CREATE_SERVICE_INFO = 'CREATE_SERVICE_INFO',
  EDIT_SERVICE_INFO = 'EDIT_SERVICE_INFO',
}

export type ModalKeys = keyof typeof MODALS;

interface chooseModalProps {
  choosenModal: ModalKeys | null;
  selectedGadgetId: string;
  selectedServiceInfo: ServiceInfo | null;
  closeModal: () => void;
  updateAfterAdd: (serviceInfo: ServiceInfoFromBack) => void;
  updateAfterEdit: (serviceInfo: ServiceInfoFromBack) => void;
}

export const ChooseModal = ({
  selectedServiceInfo,
  updateAfterEdit,
  updateAfterAdd,
  closeModal,
  selectedGadgetId = '',
  choosenModal,
}: chooseModalProps) => {
  const modal = useMemo(() => {
    switch (choosenModal) {
      case MODALS.CREATE_SERVICE_INFO:
        return (
          <CreateServiceInfoModal
            updateAfterCreating={updateAfterAdd}
            selectedGadgetId={selectedGadgetId}
            open
            onClose={closeModal}
          />
        );
      case MODALS.EDIT_SERVICE_INFO: {
        return (
          selectedServiceInfo && (
            <EditServiceInfoModal
              open
              updateAfterEditing={updateAfterEdit}
              selectedServiceInfo={selectedServiceInfo}
              onClose={closeModal}
            />
          )
        );
      }

      default:
        return <></>;
    }
  }, [choosenModal, closeModal, selectedGadgetId]);

  return modal;
};
