import { useState } from 'react';
import { API } from '../../../../api/api';
import { URLS } from '../../../../api/urls';
import { Modal } from '../../../../components/Modal/Modal';
import { ALERT_TEXT } from '../../../../constants/content';
import { useAlert } from '../../../../context/Alert.context';
import { GadgetDto } from '../../../../types/gadgets.types';
import { GadgetEdit } from '../../GadgetsPage.type';
import { GadgetForm } from './GadgetForm';

interface CreateGadgetModalProps {
  open: boolean;
  onClose: () => void;
  updateGadgetAfterCreate: (gadget: GadgetDto) => void;
}

export const CreateGadgetModal = ({
  open,
  onClose,
  updateGadgetAfterCreate,
}: CreateGadgetModalProps) => {
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();
  const editGadgetModal = (formData: GadgetEdit) => {
    setLoading(true);
    API.post(URLS.GADGETS, { ...formData })
      .then(({ data }) => {
        updateGadgetAfterCreate(data);
        onClose();
        showAlert({
          message: ALERT_TEXT.GADGET_CREATED_SUCCESS,
          severity: 'success',
        });
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal shouldPreventModal title="Add gadget" open={open} onClose={onClose}>
      <GadgetForm
        loading={loading}
        onSubmit={editGadgetModal}
        onCancel={onClose}
      />
    </Modal>
  );
};
