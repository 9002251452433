import HelpIcon from '@mui/icons-material/Help';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { Modal } from '../../../../components/Modal/Modal';
import tip from '../../../../img/tip.png';
import styles from './styles.module.css';

export const Tip = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles.tip}>
      <div onClick={() => setOpen(true)}>
        <HelpIcon />
      </div>
      <Modal open={open} onClose={() => setOpen(false)} title="How to fill">
        <div className={styles.model}>
          <Typography variant="h5">Keyword: {'{model}'}</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            In the places where you want to insert the name of the model to
            which this information will be added, specify {'{model}'}
          </Typography>
          <div className={styles.example}>
            <Typography variant="body1" color="textSecondary">
              For example:
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Your text: ...water gets into your {'{model}'}, you will be
              prompted by...
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Result: ...water gets into your iPhone 11 Pro, you will be
              prompted by...
            </Typography>
          </div>
        </div>
        <Typography variant="h5">Example</Typography>
        <div className={styles.img}>
          <img src={tip} alt="tip" />
        </div>
      </Modal>
    </div>
  );
};
