export const VolumeButton = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_9211)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43 32C43 29.7909 44.7909 28 47 28H53C55.2091 28 57 29.7909 57 32V72H43V32ZM48 36C48 35.4477 48.4477 35 49 35H51C51.5523 35 52 35.4477 52 36V48C52 48.5523 51.5523 49 51 49H49C48.4477 49 48 48.5523 48 48V36ZM49 51C48.4477 51 48 51.4477 48 52V64C48 64.5523 48.4477 65 49 65H51C51.5523 65 52 64.5523 52 64V52C52 51.4477 51.5523 51 51 51H49Z"
        fill="#1068ED"
      />
      <defs>
        <filter
          id="filter0_b_2954_9211"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_9211"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_9211"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
