import { useState } from 'react';
import { API } from '../../../../api/api';
import { URLS } from '../../../../api/urls';
import { DeleteConfirmationModal } from '../../../../components/DeleteConfirmationModal/DeleteConfirmationModal';
import { ALERT_TEXT } from '../../../../constants/content';
import { useAlert } from '../../../../context/Alert.context';
import { IPagesDto } from '../../PagesPage.type';

interface DeletePageModalProps {
  open: boolean;
  onClose: () => void;
  item: IPagesDto;
  updatePageAfterDelete: () => void;
}

export const DeletePageModal = ({
  open,
  onClose,
  item,
  updatePageAfterDelete,
}: DeletePageModalProps) => {
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();

  const onDelete = () => {
    setLoading(true);
    API.delete(`${URLS.PAGES}/${item.id}`)
      .then((res) => {
        updatePageAfterDelete();
        onClose();
        showAlert({
          message: ALERT_TEXT.PAGE_DELETE_SUCCESS,
          severity: 'success',
        });
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }))
      .finally(() => setLoading(false));
  };

  return (
    <DeleteConfirmationModal
      loading={loading}
      onClose={onClose}
      open={open}
      titleItem={item.page}
      onDelete={onDelete}
    />
  );
};
