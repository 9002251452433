export const HearingSpeaker = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_9223)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <g clipPath="url(#clip0_2954_9223)">
        <path
          d="M63.3059 35.4595C62.1846 35.4595 61.2723 36.3738 61.2723 37.4977V62.5163C61.3749 65.221 65.2452 65.2113 65.3471 62.5163V37.4977C65.3471 36.3738 64.4315 35.4595 63.3059 35.4595Z"
          fill="#1068ED"
        />
        <path
          d="M50 35.4595C48.8744 35.4595 47.9587 36.3738 47.9587 37.4977V62.5163C48.0616 65.2172 51.9394 65.2151 52.0414 62.5163V37.4977C52.0414 36.3738 51.1256 35.4595 50 35.4595Z"
          fill="#1068ED"
        />
        <path
          d="M56.6528 42.9116C55.5316 42.9116 54.6195 43.8259 54.6195 44.9498V55.0635C54.7221 57.7684 58.5923 57.7584 58.6942 55.0635V44.9498C58.6942 43.8259 57.7785 42.9116 56.6528 42.9116Z"
          fill="#1068ED"
        />
        <path
          d="M69.9587 46.6382C68.8375 46.6382 67.9253 47.5525 67.9253 48.6764V51.3375C68.0279 54.0423 71.8981 54.0324 72 51.3375V48.6764C72 47.5525 71.0843 46.6382 69.9587 46.6382Z"
          fill="#1068ED"
        />
        <path
          d="M30.0414 46.6382C28.9158 46.6382 28 47.5525 28 48.6763V51.3374C28.103 54.0343 31.9731 54.0402 32.0747 51.3374V48.6763C32.0747 47.5525 31.1626 46.6382 30.0414 46.6382Z"
          fill="#1068ED"
        />
        <path
          d="M36.6942 39.1855C35.5686 39.1855 34.6528 40.0998 34.6528 41.2237V58.7899C34.7558 61.4869 38.6261 61.4926 38.7277 58.7899V41.2237C38.7277 40.0998 37.8155 39.1855 36.6942 39.1855Z"
          fill="#1068ED"
        />
        <path
          d="M43.3472 28.0068C42.2215 28.0068 41.3059 28.9211 41.3059 30.045V69.9687C41.4087 72.6656 45.2789 72.6714 45.3806 69.9687V30.045C45.3806 28.9211 44.4684 28.0068 43.3472 28.0068Z"
          fill="#1068ED"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_2954_9223"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_9223"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_9223"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2954_9223">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(28 28)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
