import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { FormState, useForm } from 'react-hook-form';
import { ServiceInfo } from '../../../types/serviceInfo.types';
import { mapToFieldValues } from '../utils/mapToFieldValues';
import { FieldValues, FieldsArray } from '../utils/types';
import { validationSchema } from '../utils/validationSchema';

export enum ACTIVE_TAB {
  POLISH = 0,
  ENGLISH = 1,
  RUSSIAN = 2,
  UKRANIAN = 3,
}

export enum LANGUAGES {
  POLISH = 'Pl',
  ENGLISH = 'En',
  RUSSIAN = 'Ru',
  UKRANIAN = 'Uk',
}

const EN_FIELD_NAMES: FieldsArray = ['infoEn', 'titleEn', 'metaDescriptionEn'];
const PL_FIELD_NAMES: FieldsArray = ['infoPl', 'titlePl', 'metaDescriptionPl'];
const UK_FIELD_NAMES: FieldsArray = ['infoUk', 'titleUk', 'metaDescriptionUk'];
const RU_FIELD_NAMES: FieldsArray = ['infoRu', 'titleRu', 'metaDescriptionRu'];

export const chooseLangAccordingToTab = (lang: ACTIVE_TAB) => {
  const tabToLang = {
    [ACTIVE_TAB.POLISH]: LANGUAGES.POLISH,
    [ACTIVE_TAB.ENGLISH]: LANGUAGES.ENGLISH,
    [ACTIVE_TAB.RUSSIAN]: LANGUAGES.RUSSIAN,
    [ACTIVE_TAB.UKRANIAN]: LANGUAGES.UKRANIAN,
  };
  return tabToLang[lang];
};

export const hasNonEmptyValues = (
  allDirtyFields:
    | FormState<FieldValues>['dirtyFields']
    | FormState<FieldValues>['errors'],
  fieldsToCheck: Array<keyof FieldValues>,
) => fieldsToCheck.some((fieldName) => allDirtyFields[fieldName]);

export const useServiceInfoForm = ({
  serviceInfo,
  createService,
}: {
  serviceInfo: ServiceInfo | null;
  createService: any;
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(ACTIVE_TAB.POLISH);
  const [loading, setLoading] = useState(false);

  //TODO refactor write hook for img upload
  const [previewUrl, setPreviewUrl] = useState<string | null>(
    serviceInfo?.fileInfo?.fileName
      ? `${process.env.REACT_APP_API_URL_STATIC}/services/${serviceInfo.fileInfo.fileName}`
      : null,
  );
  const [fileName, setFileName] = useState<string>('');

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFileName(file.name);
      formMethods.setValue('fileInfo', file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  const formMethods = useForm<FieldValues>({
    defaultValues: mapToFieldValues(serviceInfo),
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = () => {
    setLoading(true);
    formMethods
      .handleSubmit(
        (data) => {
          createService(data);
        },
        (_) => {},
      )()
      .finally(() => setLoading(false));
  };

  const onTabClick = (_: React.SyntheticEvent, nextIndex: number) => {
    setActiveTabIndex(nextIndex);
  };
  return {
    formMethods,
    onSubmit,
    activeTabIndex,
    onTabClick,
    handleFileChange,
    previewUrl,
    fileName,
    loading,

    isEnTabValid: !hasNonEmptyValues(
      formMethods.formState.errors,
      EN_FIELD_NAMES,
    ),
    isPlTabValid: !hasNonEmptyValues(
      formMethods.formState.errors,
      PL_FIELD_NAMES,
    ),
    isEuTabValid: !hasNonEmptyValues(
      formMethods.formState.errors,
      UK_FIELD_NAMES,
    ),
    isRuTabValid: !hasNonEmptyValues(
      formMethods.formState.errors,
      RU_FIELD_NAMES,
    ),
  };
};
