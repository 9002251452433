export const USBTypeC = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_10688)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <g clipPath="url(#clip0_2954_10688)">
        <path
          d="M64.6667 42.6667H35.3333C31.2899 42.6667 28 45.9567 28 50.0001C28 54.0435 31.2899 57.3334 35.3333 57.3334H64.6667C68.7101 57.3334 72 54.0435 72 50.0001C72 45.9567 68.7101 42.6667 64.6667 42.6667ZM63.75 50.9167H36.25C35.7431 50.9167 35.3333 50.507 35.3333 50.0001C35.3333 49.4932 35.7431 49.0834 36.25 49.0834H63.75C64.2569 49.0834 64.6667 49.4932 64.6667 50.0001C64.6667 50.507 64.2569 50.9167 63.75 50.9167Z"
          fill="#1068ED"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_2954_10688"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_10688"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_10688"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2954_10688">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(28 28)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
