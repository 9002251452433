import { object, string, type ObjectSchema, number, boolean } from 'yup';
import { FieldValues } from './types';

export const validationSchema: ObjectSchema<FieldValues> = object({
  price: number().positive().required(),
  oldPrice: number().min(0).optional(),
  isBestPrice: boolean().required(),
  recoveryTime: string().trim().required(),
  isFreeDiagnostic: boolean().required(),
  isPopular: boolean().required(),
  guarantee: number().positive().required(),
  serviceInfoId: string().trim().required(),
}).required();
