export const Imac = ({
  height = '1em',
  width = '1em',
}: {
  height?: string;
  width?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.1282 7H2.87183C2.11044 7.00086 1.38048 7.30114 0.842099 7.83495C0.303716 8.36877 0.000870296 9.09253 0 9.84746V30.1525C0.000870296 30.9075 0.303716 31.6312 0.842099 32.165C1.38048 32.6989 2.11044 32.9991 2.87183 33H41.1282C41.8896 32.9991 42.6195 32.6989 43.1579 32.165C43.6963 31.6312 43.9991 30.9075 44 30.1525V9.84746C43.9991 9.09253 43.6963 8.36877 43.1579 7.83495C42.6195 7.30114 41.8896 7.00086 41.1282 7ZM42.5641 30.1525C42.5637 30.53 42.4122 30.8919 42.143 31.1588C41.8738 31.4257 41.5089 31.5758 41.1282 31.5763H2.87183C2.49113 31.5758 2.12615 31.4257 1.85696 31.1588C1.58777 30.8919 1.43635 30.53 1.43591 30.1525V9.84746C1.43635 9.46999 1.58777 9.10811 1.85696 8.84121C2.12615 8.5743 2.49113 8.42416 2.87183 8.42373H16.3348L17.1999 9.31721C17.2669 9.38637 17.3474 9.4414 17.4364 9.479C17.5254 9.5166 17.6211 9.53599 17.7179 9.53602H26.2821C26.3789 9.53599 26.4746 9.5166 26.5636 9.479C26.6526 9.4414 26.7331 9.38637 26.8001 9.31721L27.6652 8.42373H41.1282C41.5089 8.42416 41.8738 8.5743 42.143 8.84121C42.4122 9.10811 42.5637 9.46999 42.5641 9.84746V30.1525Z"
        fill="#101010"
      />
      <rect x="17" y="32" width="10" height="5" rx="1" fill="#101010" />
    </svg>
  );
};
