import { CardActionArea, CardMedia } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styles from './styles.module.css';
import { CardProps } from './types';

export const CommonCard = ({
  title,
  onButtonClicked,
  icon,
  description,
  image,
}: CardProps) => {
  return (
    <Card className={styles['card-wrapper']} onClick={onButtonClicked}>
      <CardActionArea className={styles['card-style']}>
        <CardContent>
          {/* {icon} */}
          <CardMedia
            className={styles['card-image']}
            image={image}
            title="green iguana"
          />
          <Typography
            className={styles['card-title']}
            gutterBottom
            variant="h5"
            component="div"
          >
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
