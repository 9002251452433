export const DataRecoveryConsultation = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_12325)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <g clipPath="url(#clip0_2954_12325)">
        <path
          d="M68.1328 40.9817H65.4326C63.136 35.5286 58.079 31.5658 52.2571 30.809C46.4878 30.0398 40.8192 32.2604 37.1107 36.7306C36.0205 38.0448 35.1557 39.4736 34.5225 40.9817H31.8672C29.7347 40.9817 28 42.7165 28 44.8489V50.0052C28 52.1376 29.7347 53.8723 31.8672 53.8723H37.161L36.6072 52.1817C34.9945 47.257 35.9022 42.2254 39.0959 38.3771C42.2343 34.5942 47.0266 32.7223 51.9223 33.3644C57.0998 34.0388 61.5939 37.7004 63.3745 42.695L63.3854 42.7239C63.6724 43.4868 63.8738 44.2711 63.9896 45.078C64.3761 47.4887 64.1558 49.9321 63.3539 52.144L63.3482 52.1594C61.3578 57.8105 56.0045 61.6067 50.0252 61.6067C47.8788 61.6067 46.1328 63.3414 46.1328 65.4739C46.1328 67.6064 47.8675 69.3411 50 69.3411C52.1325 69.3411 53.8672 67.6064 53.8672 65.4739V63.7354C59.0143 62.512 63.3377 58.8633 65.4157 53.8723H68.1328C70.2653 53.8723 72 52.1375 72 50.0051V44.8488C72 42.7164 70.2653 40.9817 68.1328 40.9817Z"
          fill="#1068ED"
        />
        <path
          d="M38.3984 56.4504V59.0286H50C56.3974 59.0286 61.6016 53.8244 61.6016 47.427C61.6016 41.0296 56.3974 35.8254 50 35.8254C43.6026 35.8254 38.3984 41.0296 38.3984 47.427C38.3984 50.0354 39.2746 52.5556 40.8758 54.5937C40.5649 55.68 39.5729 56.4504 38.3984 56.4504ZM53.8672 46.1379H56.4453V48.7161H53.8672V46.1379ZM48.7109 46.1379H51.2891V48.7161H48.7109V46.1379ZM43.5547 46.1379H46.1328V48.7161H43.5547V46.1379Z"
          fill="#1068ED"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_2954_12325"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_12325"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_12325"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2954_12325">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(28 28)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
