import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { object, string, type ObjectSchema } from 'yup';
import { authAPI } from '../../api/api';
import dogLogo from '../../assets/images/dog.png';
import { useAlert } from '../../context/Alert.context';
import { useAuth } from '../../context/Auth.context';
import { Input } from '../Input/Input';
import styles from './styles.module.css';

interface IAuth {
  email: string;
  password: string;
}

const validationSchema: ObjectSchema<IAuth> = object({
  email: string().trim().email().required(),
  password: string().trim().required(),
}).required();

export const Authorization = () => {
  const [loading, setLoading] = useState(false);

  const { setIsAuth } = useAuth();
  const { showAlert } = useAlert();
  const navigate = useNavigate();

  const formMethods = useForm<{ email: string; password: string }>({
    defaultValues: { email: '', password: '' },
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = () => {
    setLoading(true);
    formMethods
      .handleSubmit(
        async (formData) => {
          try {
            const { data } = await authAPI.post('/auth/login', { ...formData });
            localStorage.setItem('token', data.token);
            setIsAuth(true);
            navigate('/');
          } catch (error: any) {
            const dataResponse = error?.response?.data;
            const messages = Array.isArray(dataResponse)
              ? dataResponse.join(',')
              : dataResponse?.message;
            showAlert({ message: messages || 'some error', severity: 'error' });
          }
        },
        (_) => {},
      )()
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className={styles.container}>
        <img className={styles.image} src={dogLogo} alt="dog-logo" />
        <FormProvider {...formMethods}>
          <div className={styles.infoContainer}>
            <Input
              required
              multiline
              name="email"
              title="Email"
              placeholder="Enter email"
              formMethods={formMethods}
              type="text"
            />
            <Input
              required
              multiline
              name="password"
              title="Password"
              placeholder="Enter password"
              formMethods={formMethods}
              type="password"
            />
          </div>
          <div className={styles.forgotPasswordText}>
            <Typography variant="body2">
              Forgot password? Click
              <Link className={styles.resetLink} to="/reset-password">
                here
              </Link>
            </Typography>
          </div>
        </FormProvider>
        <LoadingButton variant="contained" loading={loading} onClick={onSubmit}>
          Log in
        </LoadingButton>
      </div>
    </>
  );
};
