import { IPost } from '../types';
import { actionPostsTypes, ActionTypes, IInitialState } from './reducer.type';

export const initialState: IInitialState = {
  posts: [],
  metaData: null,
  page: 1,
  quantity: 10,
  order: 'DESC',
};

export const reducer = (
  state = initialState,
  action: ActionTypes,
): IInitialState => {
  switch (action.type) {
    case actionPostsTypes.SET_POSTS:
      return { ...state, posts: action.payload as IPost[] };
    case actionPostsTypes.EDIT_POSTS: {
      const updatedPosts = initialState.posts.map((post) => {
        if (post.id === action.payload.id) {
          return {
            ...post,
            ...action.payload,
          };
        }
        return post;
      });
      return { ...state, posts: updatedPosts };
    }
    case actionPostsTypes.ADD_POSTS:
      return { ...state, posts: [...initialState.posts, action.payload] };
    case actionPostsTypes.SET_META_DATA:
      return { ...state, metaData: action.payload };
    case actionPostsTypes.SET_PAGE:
      return { ...state, page: action.payload };
    case actionPostsTypes.SET_QUANTITY:
      return { ...state, quantity: action.payload };
    case actionPostsTypes.SET_ORDER:
      return { ...state, order: action.payload };
    default:
      return state;
  }
};
