import { Typography } from '@mui/material';
import 'quill-image-uploader/dist/quill.imageUploader.min.css';
import MagicUrl from 'quill-magic-url';
import React, { useCallback, useRef } from 'react';
import { useController } from 'react-hook-form';
import ReactQuill, { Quill, type UnprivilegedEditor } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './styles.module.css';
import imageCompression from 'browser-image-compression';
import { API } from '../../../../api/api';
import { useAlert } from '../../../../context/Alert.context';
// Register video module
const Video = Quill.import('formats/video');
Video.className = 'quill-video';
Quill.register(Video, true);

Quill.register('modules/magicUrl', MagicUrl);

const DEFAULT_TOOLBAR_OPTIONS = [
  [{ header: [false, 2, 3, 4] }],
  ['bold'],
  ['italic'],
  ['underline'],
  ['strike'],
  ['blockquote'],
  [{ list: 'ordered' }],
  [{ list: 'bullet' }],
  ['link'],
  ['image'],
  ['video'],
];

const convertYouTubeUrl = (url: string): string | null => {
  const regex = /^.*(?:youtu.be\/|v\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regex);
  return match && match[1].length === 11
    ? `https://www.youtube.com/embed/${match[1]}`
    : null;
};



export const TextEditor = ({
  errorMessage,
  isInvalid = false,
  name,
  rules,
  formMethods,
  ...props
}: any) => {
  const { showAlert } = useAlert();
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({ name, rules });
  const valueWatch = formMethods.watch(name);

  const ownRef = useRef<ReactQuill | null>(null);

  const imageHandler = React.useCallback(async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.click();

    input.onchange = async (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];

      if (!file) return;

      const MAX_SIZE = 2 * 1024 * 1024; // 2 MB
      if (file.size > MAX_SIZE) {
        showAlert({ message: 'The image size is too large. Please upload an image smaller than 2 MB', severity: 'error' })
        return;
      }

      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1024,
        };
        const compressedFile = await imageCompression(file, options);

        const formData = new FormData();
        formData.append('image', compressedFile, file.name);

        const response = await API.post('/file/upload', formData);

        const quill = ownRef.current?.getEditor();
        const range = quill?.getSelection();
        quill?.clipboard.dangerouslyPasteHTML(
          range?.index ?? 0,
          `<img src="${process.env.REACT_APP_API_URL_STATIC}/${response.data.imageUrl}" />`
        );
      } catch (error) {
        showAlert({ message:`Error with image compression or upload: ${error}`, severity: 'error' })
      }
    };
  }, []
)

  const videoHandler = useCallback(() => {
    const url = prompt('Enter YouTube video URL:');
    if (url) {
      const embedUrl = convertYouTubeUrl(url);
      if (embedUrl) {
        const quill = ownRef.current?.getEditor();
        const range = quill?.getSelection();
        quill?.clipboard.dangerouslyPasteHTML(
          range?.index ?? 0,
          `<iframe style="width: 100%" frameborder="0" allowfullscreen="true" src="${embedUrl}"></iframe>`,
        );
      }
    }
  }, [ownRef]);

  const modules = {
    toolbar: {
      container: DEFAULT_TOOLBAR_OPTIONS,
      handlers: {
        image: imageHandler,
        video: videoHandler,
      },
    },
    magicUrl: true,
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ];
  return (
    <div className={styles.container}>
      <Typography variant="body1">Post content</Typography>
      <div className={styles.quill}>
        <ReactQuill
          key={name}
          ref={ownRef}
          {...props}
          modules={modules}
          onBlur={onBlur}
          formats={formats}
          value={valueWatch}
          onChange={onChange}
          onFocus={(
            selection: any,
            source: any,
            editor: UnprivilegedEditor,
          ) => {
            props.onFocus?.(selection, source, editor);
          }}
        />
      </div>
    </div>
  );
};
