//TODO ADD TYPES
import { actionServiceInfoTypes } from './reducer.type';

export const initialState: any = {
  gadgets: [],
  loading: true,
  choosenModal: null,
  selectedGadgetId: null,
  selectedServiceInfo: null,
};

export const reducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case actionServiceInfoTypes.SET_GADGETS:
      return { ...state, gadgets: action.payload };
    case actionServiceInfoTypes.SET_LOADING:
      return { ...state, loading: action.payload };
    case actionServiceInfoTypes.SET_CHOOSEN_MODAL:
      return { ...state, choosenModal: action.payload };
    case actionServiceInfoTypes.SET_SELECTED_GADGET:
      return { ...state, selectedGadgetId: action.payload };
    case actionServiceInfoTypes.SET_SELECTED_SERVICE_INFO:
      return { ...state, selectedServiceInfo: action.payload };
    default:
      return state;
  }
};
