export const SystemUpdate = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_9367)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <g clipPath="url(#clip0_2954_9367)">
        <path
          d="M58.2481 28C55.9024 28.1622 53.1606 29.6637 51.5629 31.619C50.1054 33.3927 48.9064 36.0272 49.3739 38.5874C51.9369 38.6672 54.5851 37.1299 56.1196 35.1417C57.5551 33.291 58.6414 30.673 58.2481 28Z"
          fill="#1068ED"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59.1116 38.2988C62.1008 38.2988 65.2661 39.9378 67.5183 42.7621C60.1346 46.8348 61.3308 57.4442 68.7915 60.285C67.7658 62.5729 67.2736 63.5932 65.9508 65.6199C64.1056 68.4469 61.5068 71.9696 58.2811 71.9944C56.9551 72.0084 56.0852 71.6071 55.1472 71.1743C54.0601 70.6728 52.8816 70.1291 50.7956 70.1409C48.7212 70.1512 47.5238 70.6882 46.4218 71.1823C45.4596 71.6138 44.5702 72.0127 43.2332 71.9999C40.0102 71.9724 37.5462 68.7934 35.701 65.9664C30.5448 58.0657 30.0003 48.79 33.182 43.8565C35.4452 40.3531 39.0147 38.3043 42.3669 38.3043C44.1536 38.3043 45.5935 38.8206 46.9657 39.3126C48.2146 39.7604 49.4074 40.1881 50.7544 40.1881C52.0008 40.1881 53.0256 39.7976 54.1434 39.3717C55.4844 38.8608 56.9593 38.2988 59.1116 38.2988ZM49 50H51V54H55V56H51V60H49V56H45V54H49V50Z"
          fill="#1068ED"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_2954_9367"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_9367"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_9367"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2954_9367">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(28 28)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
