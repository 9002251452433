export const CameraGlass = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_9259)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <g clipPath="url(#clip0_2954_9259)">
        <path
          d="M50 28C37.873 28 28 37.873 28 50C28 62.127 37.873 72 50 72C62.127 72 72 62.127 72 50C72 37.873 62.127 28 50 28ZM50 65.3098C41.5516 65.3098 34.6902 58.4393 34.6902 49.991C34.6902 41.5426 41.5607 34.6811 50 34.6811C58.4484 34.6811 65.3189 41.5516 65.3189 49.991C65.3098 58.4484 58.4484 65.3098 50 65.3098ZM58.8451 48.8189C58.8992 49.2066 58.9352 49.5943 58.9352 50C58.9352 54.932 54.932 58.9352 50 58.9352C45.068 58.9352 41.0648 54.932 41.0648 50C41.0648 45.068 45.068 41.0648 50 41.0648C50.3967 41.0648 50.7934 41.1008 51.1811 41.1549C50.3246 42.1197 49.7926 43.382 49.7926 44.7705C49.7926 47.7729 52.227 50.2074 55.2295 50.2074C56.618 50.2074 57.8893 49.6844 58.8451 48.8189Z"
          fill="#1068ED"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_2954_9259"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_9259"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_9259"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2954_9259">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(28 28)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
