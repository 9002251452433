import { Breadcrumbs } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';

export interface LinkItem {
  name: string;
  to: string;
  icon: React.ReactNode;
}

type Props = {
  linkList: LinkItem[];
};

export const BreadcrumbsComponent = ({ linkList }: Props) => {
  return (
    <Breadcrumbs className={styles.breadCrumbs} aria-label="breadcrumb">
      {linkList.map((item, index) => (
        <Link
          key={`${item.name}-${index}`}
          className={styles.breadCrumbsLink}
          color="inherit"
          to={item.to}
        >
          {item.icon}
          {item.name}
        </Link>
      ))}
    </Breadcrumbs>
  );
};
