export const Light = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_10724)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <g clipPath="url(#clip0_2954_10724)">
        <path
          d="M50 34.3378C49.241 34.3378 48.625 33.7218 48.625 32.9628V29.375C48.625 28.616 49.241 28 50 28C50.759 28 51.375 28.616 51.375 29.375V32.9628C51.375 33.7218 50.759 34.3378 50 34.3378Z"
          fill="#1068ED"
        />
        <path
          d="M62.0468 39.3283C61.6948 39.3283 61.3428 39.1944 61.0751 38.9249C60.5379 38.3878 60.5379 37.5169 61.0751 36.9798L63.6124 34.4424C64.1496 33.9053 65.0204 33.9053 65.5576 34.4424C66.0948 34.9796 66.0948 35.8504 65.5576 36.3876L63.0203 38.9249C62.7508 39.1926 62.3988 39.3283 62.0468 39.3283Z"
          fill="#1068ED"
        />
        <path
          d="M70.6249 51.375H67.0371C66.2781 51.375 65.6621 50.759 65.6621 50C65.6621 49.241 66.2781 48.625 67.0371 48.625H70.6249C71.3839 48.625 71.9999 49.241 71.9999 50C71.9999 50.759 71.3839 51.375 70.6249 51.375Z"
          fill="#1068ED"
        />
        <path
          d="M64.5841 65.9591C64.2321 65.9591 63.8801 65.8253 63.6124 65.5558L61.0751 63.0185C60.5379 62.4813 60.5379 61.6105 61.0751 61.0733C61.6123 60.5361 62.4831 60.5361 63.0203 61.0733L65.5576 63.6106C66.0948 64.1478 66.0948 65.0186 65.5576 65.5558C65.2881 65.8253 64.9361 65.9591 64.5841 65.9591Z"
          fill="#1068ED"
        />
        <path
          d="M35.4158 65.9591C35.0638 65.9591 34.7118 65.8253 34.4441 65.5558C33.907 65.0186 33.907 64.1478 34.4441 63.6106L36.9815 61.0733C37.5186 60.5361 38.3895 60.5361 38.9266 61.0733C39.4638 61.6105 39.4638 62.4813 38.9266 63.0185L36.3893 65.5558C36.1198 65.8253 35.7678 65.9591 35.4158 65.9591Z"
          fill="#1068ED"
        />
        <path
          d="M32.9628 51.375H29.375C28.616 51.375 28 50.759 28 50C28 49.241 28.616 48.625 29.375 48.625H32.9628C33.7218 48.625 34.3378 49.241 34.3378 50C34.3378 50.759 33.7218 51.375 32.9628 51.375Z"
          fill="#1068ED"
        />
        <path
          d="M37.9531 39.3283C37.6011 39.3283 37.2491 39.1944 36.9815 38.9249L34.4441 36.3876C33.907 35.8504 33.907 34.9796 34.4441 34.4424C34.9813 33.9053 35.8521 33.9053 36.3893 34.4424L38.9266 36.9798C39.4638 37.5169 39.4638 38.3878 38.9266 38.9249C38.6553 39.1926 38.3051 39.3283 37.9531 39.3283Z"
          fill="#1068ED"
        />
        <path
          d="M55.5 66.5V68.7917C55.5 70.5517 54.0517 72 52.2917 72H47.7083C46.1683 72 44.5 70.8267 44.5 68.26V66.5H55.5Z"
          fill="#1068ED"
        />
        <path
          d="M58.085 40.0264C55.0783 37.5881 51.1183 36.6348 47.25 37.4598C42.3917 38.4681 38.45 42.4281 37.4417 47.2864C36.415 52.2731 38.285 57.2231 42.2817 60.2481C43.3633 61.0548 44.115 62.3014 44.3717 63.7498V63.7681C44.4083 63.7498 44.4633 63.7498 44.5 63.7498H55.5C55.5367 63.7498 55.555 63.7498 55.5917 63.7681V63.7498C55.8483 62.3564 56.6733 61.0731 57.9383 60.0831C61.0367 57.6264 62.8333 53.9598 62.8333 49.9998C62.8333 46.1131 61.11 42.4831 58.085 40.0264ZM56.875 50.9164C56.1233 50.9164 55.5 50.2931 55.5 49.5414C55.5 46.7548 53.245 44.4998 50.4583 44.4998C49.7067 44.4998 49.0833 43.8764 49.0833 43.1248C49.0833 42.3731 49.7067 41.7498 50.4583 41.7498C54.7483 41.7498 58.25 45.2514 58.25 49.5414C58.25 50.2931 57.6267 50.9164 56.875 50.9164Z"
          fill="#1068ED"
        />
        <path
          d="M44.3717 63.75H44.5C44.4634 63.75 44.4084 63.75 44.3717 63.7683V63.75Z"
          fill="#1068ED"
        />
        <path
          d="M55.5917 63.75V63.7683C55.555 63.75 55.5367 63.75 55.5 63.75H55.5917Z"
          fill="#1068ED"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_2954_10724"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_10724"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_10724"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2954_10724">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(28 28)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
