export const MagSafe = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_10690)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.3333 43H64.6667C68.7101 43 72 46.3647 72 50.5C72 54.6353 68.7101 58 64.6667 58H35.3333C31.2899 58 28 54.6353 28 50.5C28 46.3647 31.2899 43 35.3333 43ZM42 50.5C42 51.8807 40.8807 53 39.5 53C38.1193 53 37 51.8807 37 50.5C37 49.1193 38.1193 48 39.5 48C40.8807 48 42 49.1193 42 50.5ZM49 50.5C49 51.8807 47.8807 53 46.5 53C45.1193 53 44 51.8807 44 50.5C44 49.1193 45.1193 48 46.5 48C47.8807 48 49 49.1193 49 50.5ZM53.5 53C54.8807 53 56 51.8807 56 50.5C56 49.1193 54.8807 48 53.5 48C52.1193 48 51 49.1193 51 50.5C51 51.8807 52.1193 53 53.5 53ZM63 50.5C63 51.8807 61.8807 53 60.5 53C59.1193 53 58 51.8807 58 50.5C58 49.1193 59.1193 48 60.5 48C61.8807 48 63 49.1193 63 50.5Z"
        fill="#1068ED"
      />
      <defs>
        <filter
          id="filter0_b_2954_10690"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_10690"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_10690"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
