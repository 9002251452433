export const FrontCamera = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_9961)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <path
        d="M60.9999 28H38.9999C36.9786 28 35.3333 29.6444 35.3333 31.6667V68.3334C35.3333 70.3556 36.9786 72 38.9999 72H60.9999C63.0213 72 64.6666 70.3556 64.6666 68.3333V31.6667C64.6666 29.6444 63.0213 28 60.9999 28ZM52.7499 68.3333H47.2499C46.7433 68.3333 46.3333 67.9233 46.3333 67.4166C46.3333 66.9099 46.7433 66.5 47.2499 66.5H52.7499C53.2566 66.5 53.6666 66.91 53.6666 67.4167C53.6666 67.9234 53.2566 68.3333 52.7499 68.3333ZM60.9999 61C60.4689 60.4861 59.8798 60.2135 59.2239 59.9956L57.4425 59.4011C56.6816 59.1469 56.0191 58.6402 55.5715 57.9716L55.2994 57.5652C55.1293 57.311 54.8446 57.1588 54.5385 57.1588H54.1213L53.8456 56.0586C54.6692 55.0712 55.1974 53.835 55.3818 52.9819C56.5079 51.973 56.5903 49.0323 56.5903 48.9965C56.5903 48.3815 56.4184 47.762 56.0836 47.3163C56.3325 46.7962 56.5241 46.1436 56.576 45.4176L56.8893 45.106C57.0612 44.9342 57.1579 44.7005 57.1579 44.457C57.1579 41.8323 54.6388 39 50.5675 39C48.3814 39 47.2177 39.752 46.72 40.196C45.4972 40.5156 43.9753 42.0481 43.9753 43.8895L43.9717 44.4293C43.9628 45.6521 43.9735 46.5159 44.0702 47.1336C43.6334 47.5821 43.4078 48.292 43.4078 48.9965C43.4078 49.0323 43.4901 51.973 44.6163 52.9819C44.8007 53.835 45.3289 55.0721 46.1542 56.0586L45.8786 57.1588H45.4596C45.1534 57.1588 44.867 57.3118 44.6969 57.5678L44.4266 57.9734C43.9807 58.6402 43.3183 59.1469 42.5574 59.4012L40.776 59.9947C40.1195 60.2132 39.5315 60.6623 38.9999 61.1769V31.6667H60.9999V61Z"
        fill="#1068ED"
      />
      <path
        d="M49.9999 36.2501C51.0124 36.2501 51.8332 35.4293 51.8332 34.4168C51.8332 33.4043 51.0124 32.5835 49.9999 32.5835C48.9874 32.5835 48.1666 33.4043 48.1666 34.4168C48.1666 35.4293 48.9874 36.2501 49.9999 36.2501Z"
        fill="#1068ED"
      />
      <defs>
        <filter
          id="filter0_b_2954_9961"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_9961"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_9961"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
