export const MacMini = ({
  height = '1em',
  width = '1em',
}: {
  height?: string;
  width?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="6.5"
        y="4"
        width="32"
        height="36"
        rx="2"
        stroke="#101010"
        strokeWidth="1.5"
      />
      <circle cx="22.5" cy="20" r="4.25" stroke="#101010" strokeWidth="1.5" />
      <line
        x1="6.5"
        y1="35.25"
        x2="38.5"
        y2="35.25"
        stroke="#101010"
        strokeWidth="1.5"
      />
    </svg>
  );
};
