import ErrorIcon from '@mui/icons-material/Error';
import { LoadingButton } from '@mui/lab';
import { Tab, Tabs, Typography } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { Input } from '../../../components/Input/Input';
import { UploadImage } from '../../../components/UploadImage/UploadImage';
import { ServiceInfo } from '../../../types/serviceInfo.types';
import { IconSelect } from './inputs/IconSelect';
import styles from './styles.module.css';
import {
  chooseLangAccordingToTab,
  useServiceInfoForm,
} from './useServiceInfoForm';
interface ServiceInfoFormProps {
  serviceInfo: ServiceInfo | null;
  onCancel: () => void;
  createService: (service: ServiceInfo) => void;
}

export const ServiceInfoForm = ({
  serviceInfo,
  onCancel,
  createService,
}: ServiceInfoFormProps) => {
  const {
    formMethods,
    onSubmit,
    activeTabIndex,
    onTabClick,
    previewUrl,
    fileName,
    handleFileChange,
    isEnTabValid,
    isPlTabValid,
    isEuTabValid,
    isRuTabValid,
    loading,
  } = useServiceInfoForm({ serviceInfo, createService });
  const titleName: any = `title${chooseLangAccordingToTab(activeTabIndex)}`;
  const shortTitleName: any = `shortTitle${chooseLangAccordingToTab(activeTabIndex)}`;
  const subTitle: any = `subTitle${chooseLangAccordingToTab(activeTabIndex)}`;
  const info = `info${chooseLangAccordingToTab(activeTabIndex)}`;
  const metaDescription = `metaDescription${chooseLangAccordingToTab(activeTabIndex)}`;

  return (
    <div className={styles.container}>
      <FormProvider {...formMethods}>
        <UploadImage
          previewUrl={previewUrl || ''}
          fileName={fileName}
          isDeleteBtnShouldBe={false}
          isError={!previewUrl}
          onChange={handleFileChange}
          name="fileInfo"
        />
        <IconSelect required name="icon" title="Service icon" />
        <div className={styles.tabContainer}>
          <Tabs value={activeTabIndex} onChange={onTabClick}>
            <Tab label={<TabLabel label="Polish" isValid={isPlTabValid} />} />
            <Tab label={<TabLabel label="English" isValid={isEnTabValid} />} />
            <Tab label={<TabLabel label="Russian" isValid={isRuTabValid} />} />
            <Tab label={<TabLabel label="Ukranian" isValid={isEuTabValid} />} />
          </Tabs>
        </div>
        <div className={styles.infoContainer}>
          <Input
            required
            multiline
            name={titleName}
            title="Service title"
            placeholder="Enter Service title"
            formMethods={formMethods}
          />
          <Input
            required
            multiline
            name={shortTitleName}
            title="Service short title"
            placeholder="Enter Service short title"
            formMethods={formMethods}
          />
          <Input
            required
            multiline
            name={subTitle}
            title="Service preview text"
            placeholder="Enter Service preview text"
            formMethods={formMethods}
          />
          <Input
            required
            multiline
            name={`${info}.title`}
            title="Info title"
            placeholder="Enter Info title"
            formMethods={formMethods}
          />
          <Input
            required
            multiline
            name={`${info}.titleContent`}
            title="Info 1st paragraph"
            placeholder="Enter Info 1st paragraph"
            formMethods={formMethods}
          />
          <Input
            required
            multiline
            name={`${info}.subTitle`}
            title="Info subtitle"
            placeholder="Enter Info subtitle"
            formMethods={formMethods}
          />
          <Input
            required
            multiline
            name={`${info}.subTitleContent`}
            title="Info 2nd paragraph"
            placeholder="Enter Info 2nd paragraph"
            formMethods={formMethods}
          />
          <Input
            required
            multiline
            name={`${info}.titleList`}
            title="List title"
            placeholder="Enter List title"
            formMethods={formMethods}
          />
          <Input
            required
            multiline
            name={`${info}.list`}
            title="List"
            placeholder="Enter List"
            formMethods={formMethods}
          />
          <Input
            required
            multiline
            name={`${info}.listContent`}
            title="Info 3rd paragraph"
            placeholder="Enter Info 3rd paragraph"
            formMethods={formMethods}
          />
          <Input
            required
            multiline
            name={`${info}.titleTip`}
            title="Tip title"
            placeholder="Enter Tip title"
            formMethods={formMethods}
          />
          <Input
            required
            multiline
            name={`${info}.tipContent`}
            title="Tip content"
            placeholder="Enter Tip content"
            formMethods={formMethods}
          />
          <Input
            required
            multiline
            name={metaDescription}
            title="meta description"
            placeholder="Enter meta description"
            formMethods={formMethods}
          />
        </div>
      </FormProvider>
      <LoadingButton variant="contained" loading={loading} onClick={onSubmit}>
        SAVE
      </LoadingButton>
    </div>
  );
};

const TabLabel = ({ label, isValid }: { label: string; isValid: boolean }) => {
  return (
    <div className={styles.tabItem}>
      <Typography variant="body1" color={isValid ? 'blue' : 'red'}>
        {label}
      </Typography>
      {!isValid && <ErrorIcon color="error" />}
    </div>
  );
};
