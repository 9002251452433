import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { AlertProvider } from './context/Alert.context';
import { AuthProvider } from './context/Auth.context';
import './index.css';
import { router } from './routes/routes';

const rootElement = document.getElementById('root') as HTMLElement;

const RootApp: React.FC = () => {
  return (
    <AlertProvider>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </AlertProvider>
  );
};

ReactDOM.createRoot(rootElement).render(<RootApp />);
