import AccountTreeIcon from '@mui/icons-material/AccountTree';
import HomeIcon from '@mui/icons-material/Home';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { API } from '../../../../api/api';
import { URLS } from '../../../../api/urls';
import {
  BreadcrumbsComponent,
  LinkItem,
} from '../../../../components/Breadcrumbs/Breadcrumbs';
import { useAlert } from '../../../../context/Alert.context';
import { locations } from '../../../../utils/locations';
import { PostForm } from '../../components/PostForm/PostForm';
import { FieldValues } from '../../types';
import styles from '../styles.module.css';

const linkList: LinkItem[] = [
  {
    name: 'Projects',
    to: '/',
    icon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    name: 'Choice',
    to: '/choice',
    icon: <AccountTreeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    name: 'Blog',
    to: '/choice/blog',
    icon: <NewspaperIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    name: 'Create blog',
    to: '',
    icon: <NoteAddIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
];

export const CreatePostPage = () => {
  const { showAlert } = useAlert();
  const navigate = useNavigate();

  const createPost = (post: FieldValues) => {
    const formData = new FormData();
    formData.append('fileInfo', post.fileInfo as File);
    formData.append('authorFileInfo', post.authorFileInfo as File);
    formData.append(
      'selectedAuthorFileInfo',
      post.selectedAuthorFileInfo as string,
    );
    formData.append('altText', post.altText);

    formData.append('author', post.author);
    formData.append('authorPosition', post.authorPosition);
    formData.append('readTime', post.readTime.toString());
    formData.append('topic', post.topic as string);
    formData.append('isTrending', JSON.stringify(post.isTrending));

    formData.append('metaDescriptionRu', post.metaDescriptionRu);
    formData.append('metaDescriptionEn', post.metaDescriptionEn);
    formData.append('metaDescriptionPl', post.metaDescriptionPl);
    formData.append('metaDescriptionUk', post.metaDescriptionUk);

    formData.append('previewTextRu', post.previewTextRu);
    formData.append('previewTextEn', post.previewTextEn);
    formData.append('previewTextPl', post.previewTextPl);
    formData.append('previewTextUk', post.previewTextUk);

    formData.append('bodyEn', post.bodyEn);
    formData.append('bodyPl', post.bodyPl);
    formData.append('bodyUk', post.bodyUk);
    formData.append('bodyRu', post.bodyRu);

    formData.append('titleEn', post.titleEn);
    formData.append('titlePl', post.titlePl);
    formData.append('titleUk', post.titleUk);
    formData.append('titleRu', post.titleRu);
    API.post(`${URLS.POSTS}`, formData)
      .then(() => {
        navigate(locations.choice_blog.path);
      })
      .then((e) =>
        showAlert({ message: 'Post has been created', severity: 'success' }),
      )
      .catch((e) => showAlert({ message: e.message, severity: 'error' }));
  };

  return (
    <div className={styles.container}>
      <Typography className={styles.title} variant="h2">
        Create new post
      </Typography>
      <BreadcrumbsComponent linkList={linkList} />
      <div className={styles.form}>
        <PostForm post={null} onAction={createPost} />
      </div>
    </div>
  );
};
