import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { FormEvent, useMemo, useState } from 'react';
import { IPagesDto } from '../../PagesPage.type';
import styles from './PageModal.module.css';

interface PageFormProps {
  onSubmit: (Page: IPagesDto) => void;
  onCancel: () => void;
  defaultValue?: {
    page: string;
    title?: string | undefined;
    template?: string | undefined;
    description?: string | undefined;
    payload?: any | undefined;
  };
  loading: boolean;
}

export const PageForm = ({
  onSubmit,
  defaultValue,
  onCancel,
  loading,
}: PageFormProps) => {
  const [page, setPage] = useState(
    defaultValue || {
      page: '',
      title: '',
      template: '',
      description: '',
      payload: {},
    },
  );

  const onSubmitHdl = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isSubmitBtnDisabled) return;

    onSubmit(page as IPagesDto);
  };

  const isSubmitBtnDisabled = useMemo(() => {
    return !page?.page.trim();
  }, [page, defaultValue]);

  return (
    <form className={styles.form} onSubmit={onSubmitHdl} noValidate>
      <TextField
        name="page"
        label="Page"
        value={page.page}
        variant="standard"
        required
        onChange={(e) => setPage({ ...page, page: e.target.value })}
      />
      <TextField
        name="title"
        label="Title"
        value={page.title}
        variant="standard"
        onChange={(e) =>
          setPage((page): any => ({
            ...page,
            title: e.target.value,
          }))
        }
      />
      <TextField
        name="description"
        label="Description"
        value={page.description}
        variant="standard"
        onChange={(e) =>
          setPage((page): any => ({
            ...page,
            description: e.target.value,
          }))
        }
      />
      <TextField
        name="template"
        label="Template"
        value={page.template}
        variant="standard"
        onChange={(e) =>
          setPage((page): any => ({
            ...page,
            template: e.target.value,
          }))
        }
      />
      {defaultValue?.page === 'Global' && (
        <TextField
          name="discount"
          label="Discount"
          value={page?.payload?.discount}
          variant="standard"
          onChange={(e) =>
            setPage((page): any => ({
              ...page,
              payload: {
                discount: e.target.value,
              },
            }))
          }
        />
      )}
      <DialogActions>
        <Button disabled={loading} variant="outlined" onClick={onCancel}>
          CANCEL
        </Button>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="contained"
          disabled={isSubmitBtnDisabled}
        >
          SAVE
        </LoadingButton>
      </DialogActions>
    </form>
  );
};
