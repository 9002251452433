export const DataRecovery = ({
  height = '1em',
  width = '1em',
}: {
  height?: string;
  width?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0679 31.3775C20.6969 31.3775 19.3336 31.0494 18.0797 30.401C15.65 29.1431 13.9859 26.8736 13.5094 24.1744C13.4508 23.8385 13.6734 23.5181 14.0094 23.4596C14.3453 23.401 14.6656 23.6236 14.7242 23.9596C15.1304 26.276 16.5601 28.2213 18.6461 29.3033C20.4156 30.2213 22.439 30.3931 24.3375 29.7877C26.2359 29.1822 27.7906 27.8736 28.7047 26.1041C29.6226 24.3346 29.7945 22.3111 29.189 20.4127C28.5836 18.5142 27.2789 16.9596 25.5054 16.0455C22.9 14.6939 19.7203 15.0025 17.4195 16.8385C17.1539 17.0494 16.7633 17.0064 16.5523 16.7408C16.3414 16.4752 16.3844 16.0846 16.65 15.8736C19.3336 13.7369 23.0328 13.3697 26.0797 14.9478C28.1422 16.0181 29.6656 17.8267 30.3726 20.0416C31.0758 22.2564 30.8765 24.6119 29.8062 26.6744C28.7398 28.7369 26.9312 30.2642 24.7164 30.9674C23.8453 31.2408 22.9547 31.3775 22.0679 31.3775Z"
        fill="#101010"
      />
      <path
        d="M19.7673 17.7487C19.7126 17.7487 19.6579 17.7409 19.5993 17.7253L16.8532 16.9597C16.697 16.9167 16.5602 16.8112 16.4821 16.6667C16.4001 16.5261 16.3806 16.3542 16.4235 16.1979L17.1891 13.4518C17.279 13.1237 17.6227 12.9323 17.9509 13.0222C18.279 13.112 18.4704 13.4557 18.3806 13.7839L17.779 15.9362L19.9313 16.5378C20.2595 16.6276 20.4509 16.9714 20.361 17.2995C20.2868 17.569 20.0407 17.7487 19.7673 17.7487Z"
        fill="#101010"
      />
      <rect
        x="6.5"
        y="1"
        width="32"
        height="42"
        rx="2"
        stroke="#101010"
        strokeWidth="1.5"
      />
    </svg>
  );
};
