import { useEffect, useReducer, useState } from 'react';
import { API } from '../../api/api';
import { URLS } from '../../api/urls';
import { useAlert } from '../../context/Alert.context';
import { IPaginationMetaDtoParameters } from '../../types/types';
import { initialState, reducer } from './reducer/reducer';
import { actionPostsTypes } from './reducer/reducer.type';
import { IBlogResponse, IPost } from './types';

export const useBlogPage = () => {
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState(true);
  const [{ posts, metaData, page, quantity, order }, dispatch] = useReducer(
    reducer,
    initialState,
  );

  const [selectedPost, setSelectedPost] = useState<IPost | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    API.get<IBlogResponse>(
      `${URLS.POSTS}?isAdmin=true&page=${page}&limit=${quantity}&order=${order}`,
    )
      .then(({ data }) => {
        actions.setPosts(data.data);
        actions.setMetaData(data.meta);
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }))
      .finally(() => setLoading(false));
  }, [page, quantity, order]);

  const actions = {
    setPosts: (posts: IPost[]) =>
      dispatch({ type: actionPostsTypes.SET_POSTS, payload: posts }),
    setMetaData: (metaData: IPaginationMetaDtoParameters) => {
      return dispatch({
        type: actionPostsTypes.SET_META_DATA,
        payload: metaData,
      });
    },
    setPage: (page: number) => {
      return dispatch({
        type: actionPostsTypes.SET_PAGE,
        payload: page,
      });
    },
    setQuantity: (quantity: number) => {
      return dispatch({
        type: actionPostsTypes.SET_QUANTITY,
        payload: quantity,
      });
    },
    setOrder: (order: string) => {
      return dispatch({
        type: actionPostsTypes.SET_ORDER,
        payload: order,
      });
    },
  };

  const updatePostsAfterDelete = (postId: string) => {
    const updatedPost = posts.filter((post) => post.id !== postId);

    dispatch({
      type: actionPostsTypes.SET_POSTS,
      payload: updatedPost,
    });
  };

  return {
    posts,
    loading,

    selectedPost,
    setSelectedPost,

    openDeleteModal,
    setOpenDeleteModal,

    metaData,
    page,
    quantity,
    order,
    updatePostsAfterDelete,
    ...actions,
  };
};
