import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, IconButton, Typography } from '@mui/material';
import { Sidebar } from '../Drawer/Drawer';
import MenuIcon from '@mui/icons-material/Menu';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  background: `${theme.palette.primary.main}`,
  color: 'white',
  padding: theme.spacing(2),
}));

export const Header = () => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <StyledBox>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer(true)}
        edge="start"
        sx={{ mr: 2, ...(open && { display: 'none' }) }}
      >
        <MenuIcon />
      </IconButton>
      <Typography>CHOICE ADMIN</Typography>
      <Sidebar onClose={toggleDrawer(false)} open={open} />
    </StyledBox>
  );
};
