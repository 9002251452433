import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { object, string, type ObjectSchema } from 'yup';
import { authAPI } from '../../../api/api';
import dogLogo from '../../../assets/images/dog.png';
import { useAlert } from '../../../context/Alert.context';
import { Input } from '../../Input/Input';
import styles from './styles.module.css';

interface IAuth {
  email: string;
}

const validationSchema: ObjectSchema<IAuth> = object({
  email: string().email().trim().required(),
}).required();

export const RequestResetPassword = () => {
  const [loading, setLoading] = useState(false);

  const { showAlert } = useAlert();

  const formMethods = useForm<{ email: string }>({
    defaultValues: { email: '' },
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = () => {
    setLoading(true);
    formMethods
      .handleSubmit(
        async (formData) => {
          try {
            await authAPI.post('/auth/request-reset-password', {
              ...formData,
            });
            showAlert({
              message: 'Password reset email sent',
              severity: 'success',
            });
          } catch (error: any) {
            showAlert({ message: 'Some error occurred', severity: 'error' });
          }
        },
        (_) => {},
      )()
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className={styles.container}>
        <img className={styles.image} src={dogLogo} alt="dog-logo" />
        <FormProvider {...formMethods}>
          <div className={styles.infoContainer}>
            <Input
              required
              multiline
              name="email"
              title="Email"
              placeholder="Enter email"
              formMethods={formMethods}
            />
          </div>
          <div className={styles.forgotPasswordText}>
            <Typography variant="body2">
              Back to
              <Link className={styles.resetLink} to="/login">
                Login page
              </Link>
            </Typography>
          </div>
        </FormProvider>
        <LoadingButton variant="contained" loading={loading} onClick={onSubmit}>
          Request Password reset
        </LoadingButton>
      </div>
    </>
  );
};
