export const Camera = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_9106)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <path
        d="M52.8884 37.0745H32.814C30.1663 37.0745 28 39.2408 28 41.8885V58.1117C28 60.7594 30.1663 62.9257 32.814 62.9257H52.8884C55.5361 62.9257 57.7024 60.7594 57.7024 58.1117V41.8885C57.7024 39.1926 55.5361 37.0745 52.8884 37.0745Z"
        fill="#1068ED"
      />
      <path
        d="M68.4376 39.7221C68.1488 39.7702 67.86 39.9146 67.6193 40.0591L60.1094 44.3917V55.5601L67.6674 59.8928C69.0635 60.7111 70.7965 60.2297 71.6149 58.8337C71.8556 58.4004 72 57.919 72 57.3895V42.5142C72 40.733 70.3151 39.2888 68.4376 39.7221Z"
        fill="#1068ED"
      />
      <defs>
        <filter
          id="filter0_b_2954_9106"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_9106"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_9106"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
