import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { useController } from 'react-hook-form';

export const CustomCheckbox = ({
  name,
  rules,
  label,
  formMethods,
  ...props
}: any) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, rules });
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            {...props}
            onChange={onChange}
            checked={value}
            value={value}
            error={error ? error : undefined}
          />
        }
        label={label}
      />
      {error?.message && (
        <Typography color="error" variant="body1">
          Set valid data.
        </Typography>
      )}
    </FormGroup>
  );
};
