export const TouchScreen = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_9343)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <g clipPath="url(#clip0_2954_9343)">
        <path
          d="M36.3998 40.9775C36.3998 35.406 40.9326 30.8732 46.5042 30.8732C52.0757 30.8732 56.6085 35.406 56.6085 40.9775C56.6085 41.9268 56.4762 42.8671 56.2152 43.7726L58.976 44.5686C59.3116 43.4044 59.4818 42.1962 59.4818 40.9776C59.4818 33.8217 53.6601 28 46.5042 28C39.3483 28 33.5266 33.8217 33.5266 40.9776C33.5266 44.1537 34.6908 47.2126 36.805 49.5909L38.9524 47.6819C37.3064 45.8301 36.3998 43.4491 36.3998 40.9775Z"
          fill="#1068ED"
        />
        <path
          d="M54.6302 41.9765C54.6719 41.636 54.693 41.2998 54.693 40.9774C54.693 36.4621 51.0195 32.7886 46.5042 32.7886C41.9888 32.7886 38.3153 36.4621 38.3153 40.9774C38.3153 42.8215 38.9179 44.5651 40.0578 46.0197L42.3193 44.2475C41.5796 43.3035 41.1885 42.1727 41.1885 40.9774C41.1885 38.0464 43.573 35.6618 46.5041 35.6618C49.4351 35.6618 51.8196 38.0464 51.8196 40.9774C51.8196 41.1864 51.806 41.399 51.7781 41.6274L54.6302 41.9765Z"
          fill="#1068ED"
        />
        <path
          d="M64.0312 49.6405C62.6824 49.6405 61.5889 50.7339 61.5889 52.0827H60.6311V49.4514C60.6311 48.1026 59.5376 47.0091 58.1888 47.0091C56.84 47.0091 55.7466 48.1026 55.7466 49.4514V52.0827H54.7888V47.0481C54.7888 45.6993 53.6953 44.6058 52.3465 44.6058C50.9977 44.6058 49.9042 45.6993 49.9042 47.0481V52.0827H48.9465V40.9774C48.9465 39.6286 47.853 38.5352 46.5042 38.5352C45.1554 38.5352 44.0619 39.6286 44.0619 40.9774V59.0597H43.1041V53.1627C43.1041 51.8139 42.0107 50.7204 40.6619 50.7204C39.313 50.7204 38.2196 51.8139 38.2196 53.1627V62.1514C38.2196 67.5819 42.6376 71.9999 48.0681 71.9999H56.625C62.0554 71.9999 66.4734 67.5819 66.4734 62.1514V52.0827C66.4734 50.7339 65.38 49.6405 64.0312 49.6405Z"
          fill="#1068ED"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_2954_9343"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_9343"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_9343"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2954_9343">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(28 28)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
