import { actionPageTypes, ActionTypes, IInitialState } from './reducer.type';

export const initialState: IInitialState = {
  pages: [],
  loading: true,
  selectedPage: null,
  choosenModal: null,
};

export const reducer = (
  state = initialState,
  action: ActionTypes,
): IInitialState => {
  switch (action.type) {
    case actionPageTypes.SET_PAGES:
      return { ...state, pages: action.payload };
    case actionPageTypes.SET_LOADING:
      return { ...state, loading: action.payload };
    case actionPageTypes.SET_CHOOSEN_MODAL:
      return { ...state, choosenModal: action.payload };
    case actionPageTypes.SET_SELECTED_PAGE:
      return { ...state, selectedPage: action.payload };
    default:
      return state;
  }
};
