export const PowerButton = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_9205)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47 28C44.7909 28 43 29.7909 43 32V72H57V32C57 29.7909 55.2091 28 53 28H47ZM49 37C48.4477 37 48 37.4477 48 38V63C48 63.5523 48.4477 64 49 64H51C51.5523 64 52 63.5523 52 63V38C52 37.4477 51.5523 37 51 37H49Z"
        fill="#1068ED"
      />
      <defs>
        <filter
          id="filter0_b_2954_9205"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_9205"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_9205"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
