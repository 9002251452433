export const BatteryReplacement = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2953_9047)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <g clipPath="url(#clip0_2953_9047)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.923 38.1538C67.8577 38.1538 68.6154 38.9115 68.6154 39.8462V44.9231H71.1539C71.6212 44.9231 72 45.302 72 45.7692V54.2308C72 54.6981 71.6212 55.0769 71.1539 55.0769H68.6154V60.1539C68.6154 61.0886 67.8577 61.8462 66.923 61.8462H29.6924C28.7577 61.8461 28 61.0884 28 60.1539V39.8462C28 38.9115 28.7577 38.1538 29.6924 38.1538H66.923ZM39.8461 43.2308H33.077V56.7693H39.8461V43.2308Z"
          fill="#1068ED"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_2953_9047"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2953_9047"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2953_9047"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2953_9047">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(28 28)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
