import { TextField, Typography } from '@mui/material';
import { useController } from 'react-hook-form';
import styles from './styles.module.css';

export const Input = ({
  name,
  rules,
  title,
  formMethods,
  type,
  placeholder,
  required
}: any) => {
  const {
    field: { onChange, ref },
    fieldState: { error },
  } = useController({ name, rules });
  const valueWatch = formMethods.watch(name);

  return (
    <div className={styles.inputContainer}>
      <Typography variant="body1">{title}</Typography>
      <div>
        <TextField
          fullWidth
          required={required}
          error={!!error}
          inputRef={ref}
          onChange={onChange}
          name={name}
          value={valueWatch}
          hiddenLabel={true}
          type={type}
          placeholder={placeholder}
        />
        {error?.message && (
          <Typography color="error" variant="body1">
            {error?.message ?? `Set valid data.`}
          </Typography>
        )}
      </div>
    </div>
  );
};
