import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { FormEvent, useMemo, useState } from 'react';
import {
  GadgetIconsEnum,
  GadgetsIcon,
  getIcon,
} from '../../../../components/IconSelect/icons';
import { GadgetEdit } from '../../GadgetsPage.type';
import styles from './GadgetModal.module.css';
import { IconSelect } from '../../../../components/IconSelect/IconSelect';
interface GadgetFormProps {
  onSubmit: (gadget: GadgetEdit) => void;
  onCancel: () => void;
  defaultValue?: { name: string; icon: string };
  loading: boolean;
}

export const GadgetForm = ({
  onSubmit,
  defaultValue,
  onCancel,
  loading,
}: GadgetFormProps) => {
  const [name, setName] = useState(defaultValue?.name || '');
  const [iconValue, setIconValue] = useState(
    getIcon(defaultValue?.icon as GadgetIconsEnum)?.value ||
      GadgetsIcon[0].value,
  );

  const onSubmitHdl = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isSubmitBtnDisabled) return;

    onSubmit({ name, icon: iconValue });
  };

  const isSubmitBtnDisabled = useMemo(() => {
    return (
      !name.trim() ||
      (defaultValue?.icon === iconValue &&
        !!defaultValue &&
        defaultValue.name === name)
    );
  }, [name, defaultValue, iconValue]);

  return (
    <form className={styles.form} onSubmit={onSubmitHdl} noValidate>
      <TextField
        name="name"
        label="Gadget"
        value={name}
        variant="standard"
        required
        onChange={(e) => setName(e.target.value)}
      />
      <IconSelect
        value={iconValue}
        onChange={(e: any) => {
          setIconValue(e.target.value);
        }}
      />
      <DialogActions>
        <Button disabled={loading} variant="outlined" onClick={onCancel}>
          CANCEL
        </Button>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="contained"
          disabled={isSubmitBtnDisabled}
        >
          SAVE
        </LoadingButton>
      </DialogActions>
    </form>
  );
};
