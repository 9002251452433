import { CircularProgress } from '@mui/material';
import { createContext, useContext, useEffect, useState } from 'react';
import { authAPI } from '../api/api';

const cssStyles = {
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

interface AuthContextProps {
  isAuth: boolean;
  setIsAuth: (data: any) => any;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    authAPI
      .post('/auth/check')
      .then((data) => {
        const { data: responseData } = data;
        localStorage.setItem('token', responseData.token);
        setIsAuth(true);
      })
      .catch((error) => {
        setIsLoading(false);
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return (
      <div style={cssStyles}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ isAuth, setIsAuth }}>
      {/* {!isAuth ? <Authorization /> : children} */}
      {children}
    </AuthContext.Provider>
  );
};
