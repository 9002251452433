import { ServiceInfo } from '../../../types/serviceInfo.types';

export const parseServiceInfoListToArray = (serviceInfo: ServiceInfo) => {
  return {
    ...serviceInfo,
    infoEn: { ...serviceInfo.infoEn, list: serviceInfo.infoEn.list.split(',') },
    infoRu: { ...serviceInfo.infoRu, list: serviceInfo.infoRu.list.split(',') },
    infoPl: { ...serviceInfo.infoPl, list: serviceInfo.infoPl.list.split(',') },
    infoUk: { ...serviceInfo.infoUk, list: serviceInfo.infoUk.list.split(',') },
  };
};

export const parseServiceInfoListToString = (serviceInfo: any) => {
  if (!serviceInfo) return;

  const languages = ['infoEn', 'infoRu', 'infoPl', 'infoUk'];

  const parsedServiceInfo = { ...serviceInfo };

  languages.forEach((lang) => {
    parsedServiceInfo[lang] = infoParser(serviceInfo[lang]);
    parsedServiceInfo[lang].list = listParser(parsedServiceInfo[lang].list);
  });

  return parsedServiceInfo;
};

const infoParser = (data: string | object) => {
  return typeof data === 'string' ? JSON.parse(data) : data;
};

const listParser = (list: string | string[]) => {
  return typeof list === 'string' ? list : list.join(',');
};
