import { useEffect, useReducer } from 'react';
import { API } from '../../api/api';
import { URLS } from '../../api/urls';
import { useAlert } from '../../context/Alert.context';
import { IPagesDto } from './PagesPage.type';
import { ModalKeys } from './chooseModal';
import { initialState, reducer } from './reducer/reducer';
import { actionPageTypes } from './reducer/reducer.type';

export const usePagesPage = () => {
  const [{ pages, loading, choosenModal, selectedPage }, dispatch] = useReducer(
    reducer,
    initialState,
  );
  const { showAlert } = useAlert();

  useEffect(() => {
    API.get<IPagesDto[]>(`${URLS.PAGES}`)
      .then(({ data }) => {
        dispatch({ type: actionPageTypes.SET_PAGES, payload: data });
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }))
      .finally(() =>
        dispatch({ type: actionPageTypes.SET_LOADING, payload: false }),
      );
  }, []);

  const actions = {
    setSelectedPage: (page: IPagesDto | null) =>
      dispatch({
        type: actionPageTypes.SET_SELECTED_PAGE,
        payload: page,
      }),
    setModal: (value: ModalKeys | null) =>
      dispatch({ type: actionPageTypes.SET_CHOOSEN_MODAL, payload: value }),
    closeModal: () =>
      dispatch({ type: actionPageTypes.SET_CHOOSEN_MODAL, payload: null }),
  };

  const updatePageAfterDelete = () => {
    const updatedPages: IPagesDto[] = pages.filter(
      (page: IPagesDto) => page.id !== selectedPage?.id,
    );
    dispatch({ type: actionPageTypes.SET_PAGES, payload: updatedPages });
  };
  const updatePageAfterCreate = (page: IPagesDto) => {
    const updatedPages: IPagesDto[] = [...pages, page];
    dispatch({ type: actionPageTypes.SET_PAGES, payload: updatedPages });
  };
  const updatePageAfterEdit = (editPage: IPagesDto) => {
    const updatedPage = pages.map((page) => {
      if (page.id === editPage.id) {
        return {
          ...editPage,
        };
      }

      return page;
    });
    dispatch({ type: actionPageTypes.SET_PAGES, payload: updatedPage });
  };

  return {
    pages,
    loading,
    choosenModal,
    selectedPage,
    updatePageAfterDelete,
    updatePageAfterCreate,
    updatePageAfterEdit,
    ...actions,
  };
};
