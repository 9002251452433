import {
  AddingMemory,
  BatteryReplacement,
  BrokenDisplayIpad,
  BrokenDisplayMac,
  Camera,
  CameraGlass,
  Case,
  CaseIpad,
  Chip,
  Clean,
  ConnectorOnTheMotherboard,
  DataRecovery,
  DataRecoveryConsultation,
  FaceID,
  Fan,
  FrontCamera,
  GlassPanel,
  GlassReplacement,
  HearingSpeaker,
  Keyboard,
  Light,
  Lightning,
  Lock,
  LoggoboardMac,
  MagSafe,
  Microphone,
  NoImage,
  NoWifi,
  PayPass,
  PowerButton,
  ProblemsTurningOn,
  RadiatorReplacement,
  ReplacingChargingConnector,
  ReplacingProximitySensor,
  RestoringCameraPower,
  SSD,
  Search,
  SystemUpdate,
  TopCase,
  TouchId,
  TouchScreen,
  Touchpad,
  USBTypeC,
  Vibrations,
  Volume,
  VolumeButton,
  Water,
  Wifi,
} from '../../../img/services';
import { ServiceIcon } from '../../../types/serviceInfo.types';

export const ServiceInfoIcons = [
  {
    value: ServiceIcon.FrontCamera,
    label: 'FrontCamera',
    icon: <FrontCamera />,
  },
  {
    value: ServiceIcon.Fan,
    label: 'Fan',
    icon: <Fan />,
  },
  {
    value: ServiceIcon.ReplacingProximitySensor,
    label: 'ReplacingProximitySensor',
    icon: <ReplacingProximitySensor />,
  },
  {
    value: ServiceIcon.RestoringCameraPower,
    label: 'RestoringCameraPower',
    icon: <RestoringCameraPower />,
  },
  {
    value: ServiceIcon.GlassReplacement,
    label: 'GlassReplacement',
    icon: <GlassReplacement />,
  },
  {
    value: ServiceIcon.BatteryReplacement,
    label: 'BatteryReplacement',
    icon: <BatteryReplacement />,
  },
  {
    value: ServiceIcon.GlassPanel,
    label: 'GlassPanel',
    icon: <GlassPanel />,
  },
  {
    value: ServiceIcon.Case,
    label: 'Case',
    icon: <Case />,
  },
  {
    value: ServiceIcon.AddingMemory,
    label: 'AddingMemory',
    icon: <AddingMemory />,
  },
  {
    value: ServiceIcon.BrokenDisplayIpad,
    label: 'BrokenDisplayIpad',
    icon: <BrokenDisplayIpad />,
  },
  {
    value: ServiceIcon.BrokenDisplayMac,
    label: 'BrokenDisplayMac',
    icon: <BrokenDisplayMac />,
  },
  {
    value: ServiceIcon.Camera,
    label: 'Camera',
    icon: <Camera />,
  },
  {
    value: ServiceIcon.CameraGlass,
    label: 'CameraGlass',
    icon: <CameraGlass />,
  },
  {
    value: ServiceIcon.CaseIpad,
    label: 'CaseIpad',
    icon: <CaseIpad />,
  },
  {
    value: ServiceIcon.Chip,
    label: 'Chip',
    icon: <Chip />,
  },
  {
    value: ServiceIcon.Clean,
    label: 'Clean',
    icon: <Clean />,
  },
  {
    value: ServiceIcon.ConnectorOnTheMotherboard,
    label: 'ConnectorOnTheMotherboard',
    icon: <ConnectorOnTheMotherboard />,
  },
  {
    value: ServiceIcon.DataRecovery,
    label: 'DataRecovery',
    icon: <DataRecovery />,
  },
  {
    value: ServiceIcon.DataRecoveryConsultation,
    label: 'DataRecoveryConsultation',
    icon: <DataRecoveryConsultation />,
  },
  {
    value: ServiceIcon.HearingSpeaker,
    label: 'HearingSpeaker',
    icon: <HearingSpeaker />,
  },
  {
    value: ServiceIcon.Keyboard,
    label: 'Keyboard',
    icon: <Keyboard />,
  },
  {
    value: ServiceIcon.Light,
    label: 'Light',
    icon: <Light />,
  },
  {
    value: ServiceIcon.MagSafe,
    label: 'MagSafe',
    icon: <MagSafe />,
  },
  {
    value: ServiceIcon.Microphone,
    label: 'Microphone',
    icon: <Microphone />,
  },
  {
    value: ServiceIcon.NoImage,
    label: 'NoImage',
    icon: <NoImage />,
  },
  {
    value: ServiceIcon.NoWifi,
    label: 'NoWifi',
    icon: <NoWifi />,
  },
  {
    value: ServiceIcon.Lightning,
    label: 'Lightning',
    icon: <Lightning />,
  },
  {
    value: ServiceIcon.PowerButton,
    label: 'PowerButton',
    icon: <PowerButton />,
  },
  {
    value: ServiceIcon.ProblemsTurningOn,
    label: 'ProblemsTurningOn',
    icon: <ProblemsTurningOn />,
  },
  {
    value: ServiceIcon.PayPass,
    label: 'PayPass',
    icon: <PayPass />,
  },
  {
    value: ServiceIcon.RadiatorReplacement,
    label: 'RadiatorReplacement',
    icon: <RadiatorReplacement />,
  },
  {
    value: ServiceIcon.ReplacingChargingConnector,
    label: 'ReplacingChargingConnector',
    icon: <ReplacingChargingConnector />,
  },
  {
    value: ServiceIcon.Search,
    label: 'Search',
    icon: <Search />,
  },
  {
    value: ServiceIcon.SSD,
    label: 'SSD',
    icon: <SSD />,
  },
  {
    value: ServiceIcon.SystemUpdate,
    label: 'SystemUpdate',
    icon: <SystemUpdate />,
  },
  {
    value: ServiceIcon.TopCase,
    label: 'TopCase',
    icon: <TopCase />,
  },
  {
    value: ServiceIcon.TouchId,
    label: 'TouchId',
    icon: <TouchId />,
  },
  {
    value: ServiceIcon.Touchpad,
    label: 'Touchpad',
    icon: <Touchpad />,
  },
  {
    value: ServiceIcon.TouchScreen,
    label: 'TouchScreen',
    icon: <TouchScreen />,
  },
  {
    value: ServiceIcon.USBTypeC,
    label: 'USBTypeC',
    icon: <USBTypeC />,
  },
  {
    value: ServiceIcon.Vibrations,
    label: 'Vibrations',
    icon: <Vibrations />,
  },
  {
    value: ServiceIcon.Volume,
    label: 'Volume',
    icon: <Volume />,
  },
  {
    value: ServiceIcon.VolumeButton,
    label: 'VolumeButton',
    icon: <VolumeButton />,
  },
  {
    value: ServiceIcon.Water,
    label: 'Water',
    icon: <Water />,
  },
  {
    value: ServiceIcon.Wifi,
    label: 'Wifi',
    icon: <Wifi />,
  },
  {
    value: ServiceIcon.Lock,
    label: 'Lock',
    icon: <Lock />,
  },
  {
    value: ServiceIcon.FaceID,
    label: 'FaceID',
    icon: <FaceID />,
  },
  {
    value: ServiceIcon.LoggoboardMac,
    label: 'LoggoboardMac',
    icon: <LoggoboardMac />,
  },
];
export const getIconByName = (iconName: ServiceIcon) =>
  ServiceInfoIcons.filter((infoIcon) => infoIcon.label === iconName)[0]?.icon;
