import { IPaginationMetaDtoParameters } from '../../../types/types';
import { IMail } from '../MailsPage.type';

export interface IInitialState {
  mails: IMail[];
  metaData: IPaginationMetaDtoParameters | null;
  page: number;
  quantity: number;
  order: string;
}

export const actionMailsTypes = {
  SET_MAILS: 'SET_MAILS' as const,
  SET_META_DATA: 'SET_META_DATA' as const,
  SET_PAGE: 'SET_PAGE' as const,
  SET_QUANTITY: 'SET_QUANTITY' as const,
  SET_ORDER: 'SET_ORDER' as const,
};

export type ActionTypes =
  | {
      type: typeof actionMailsTypes.SET_MAILS;
      payload: IMail[];
    }
  | {
      type: typeof actionMailsTypes.SET_META_DATA;
      payload: IPaginationMetaDtoParameters;
    }
  | {
      type: typeof actionMailsTypes.SET_PAGE;
      payload: number;
    }
  | {
      type: typeof actionMailsTypes.SET_QUANTITY;
      payload: number;
    }
  | {
      type: typeof actionMailsTypes.SET_ORDER;
      payload: string;
    };
