export const Touchpad = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_10684)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <path
        d="M66.5 29.375H33.5C32.9581 29.3742 32.4213 29.4804 31.9205 29.6875C31.4197 29.8945 30.9647 30.1983 30.5815 30.5815C30.1983 30.9647 29.8945 31.4197 29.6875 31.9205C29.4804 32.4213 29.3742 32.9581 29.375 33.5V66.5C29.3742 67.0419 29.4804 67.5787 29.6875 68.0795C29.8945 68.5803 30.1983 69.0353 30.5815 69.4185C30.9647 69.8017 31.4197 70.1055 31.9205 70.3126C32.4213 70.5196 32.9581 70.6258 33.5 70.625H66.5C67.0419 70.6258 67.5787 70.5196 68.0795 70.3126C68.5803 70.1055 69.0353 69.8017 69.4185 69.4185C69.8017 69.0353 70.1055 68.5803 70.3126 68.0795C70.5196 67.5787 70.6258 67.0419 70.625 66.5V33.5C70.6258 32.9581 70.5196 32.4213 70.3126 31.9205C70.1055 31.4197 69.8017 30.9647 69.4185 30.5815C69.0353 30.1983 68.5803 29.8945 68.0795 29.6875C67.5787 29.4804 67.0419 29.3742 66.5 29.375ZM51.375 56.875V67.875H48.625V56.875H32.125V54.125H67.875V56.875H51.375Z"
        fill="#1068ED"
      />
      <defs>
        <filter
          id="filter0_b_2954_10684"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_10684"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_10684"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
