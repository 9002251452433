export const Lightning = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_9307)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <path
        d="M52.7151 29.374C52.5032 29.3844 52.3085 29.4938 52.1874 29.668L36.3735 52.3541C36.0556 52.81 36.3817 53.4351 36.9375 53.4351H47.8784L46.5652 69.8827C46.5138 70.578 47.4106 70.8995 47.8126 70.3299L63.6265 47.645C63.9441 47.1897 63.6189 46.5652 63.0638 46.5641H52.1216L53.4362 30.1152C53.4691 29.701 53.13 29.3524 52.7151 29.374Z"
        fill="#1068ED"
      />
      <defs>
        <filter
          id="filter0_b_2954_9307"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_9307"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_9307"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
