import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Service } from '../../../../../../types/services.types';
import { mapToFieldValues } from './mapToFieldValues';
import { FieldValues } from './types';
import { validationSchema } from './validationSchema';

interface useServiceFormProps {
  service: Service | null;
  createService: (service: Service) => void;
}

export const useServiceForm = ({
  service,
  createService,
}: useServiceFormProps) => {
  const [loading, setLoading] = useState(false);
  const formMethods = useForm<FieldValues>({
    defaultValues: mapToFieldValues(service),
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = () => {
    setLoading(true);
    formMethods
      .handleSubmit(
        (data) => {
          createService(data);
        },
        (_) => {},
      )()
      .finally(() => setLoading(false));
  };
  return {
    formMethods,
    onSubmit,
    loading,
  };
};
