import { FieldValues, IPost } from '../types';

export const mapToFieldValues = (
  post: IPost | undefined | null,
): FieldValues => ({
  titleEn: post?.titleEn ?? '',
  titlePl: post?.titlePl ?? '',
  titleUk: post?.titleUk ?? '',
  titleRu: post?.titleRu ?? '',

  bodyEn: post?.bodyEn ?? '',
  bodyPl: post?.bodyPl ?? '',
  bodyUk: post?.bodyUk ?? '',
  bodyRu: post?.bodyRu ?? '',

  metaDescriptionRu: post?.metaDescriptionRu ?? '',
  metaDescriptionEn: post?.metaDescriptionEn ?? '',
  metaDescriptionPl: post?.metaDescriptionPl ?? '',
  metaDescriptionUk: post?.metaDescriptionUk ?? '',

  altText: post?.altText ?? '',
  fileInfo: post?.fileInfo ?? null,
  authorFileInfo: post?.authorFileInfo ?? null,

  author: post?.author ?? '',
  authorPosition: post?.authorPosition ?? '',
  readTime: post?.readTime ?? 1,
  topic: post?.topic ?? null,
  isTrending: post?.isTrending ?? false,

  previewTextRu: post?.previewTextRu ?? '',
  previewTextEn: post?.previewTextEn ?? '',
  previewTextPl: post?.previewTextPl ?? '',
  previewTextUk: post?.previewTextUk ?? '',
});
