import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useController } from 'react-hook-form';
import { topicSelect } from '../../consts';
import styles from './styles.module.css';

export const TopicSelect = ({
  name,
  rules,
  title,
  formMethods,
  ...props
}: any) => {
  const {
    field: { onChange, value, ref },
    fieldState: { error },
  } = useController({ name, rules });
  return (
    <FormControl className={styles.select} fullWidth>
      <Typography className={styles.title} variant="body1">
        {title}
      </Typography>
      <div>
        <Select
          error={!!error}
          fullWidth
          value={value || ''}
          onChange={onChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          {...props}
        >
          {topicSelect.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <div className={styles.selectIconItem}>{item.label}</div>
            </MenuItem>
          ))}
        </Select>
        {error?.message && (
          <Typography color="error" variant="body1">
            This field is required
          </Typography>
        )}
      </div>
    </FormControl>
  );
};
