export const Wifi = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_9253)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <g clipPath="url(#clip0_2954_9253)">
        <path
          d="M54.0382 63.2941C54.4004 61.06 52.8829 58.9553 50.6488 58.5931C48.4146 58.2309 46.3099 59.7485 45.9477 61.9826C45.5856 64.2167 47.1031 66.3214 49.3372 66.6836C51.5714 67.0458 53.6761 65.5282 54.0382 63.2941Z"
          fill="#1068ED"
        />
        <path
          d="M70.7767 41.8653C64.8703 35.959 57.2746 33.2588 50 33.2588C42.7254 33.2588 35.1297 35.959 29.2233 41.8653C27.591 43.4995 27.5985 46.03 29.2081 47.6464C31.2182 49.6789 33.8499 48.8699 35.0284 47.6519C39.0291 43.6565 44.3457 41.4562 50 41.4562C55.6543 41.4562 60.9709 43.6565 64.9716 47.6519C66.1501 48.8699 68.7818 49.6789 70.7919 47.6464C72.4015 46.03 72.409 43.4995 70.7767 41.8653Z"
          fill="#1068ED"
        />
        <path
          d="M50 45.9009C45.9248 45.9009 41.552 47.416 38.1636 50.8036C36.5104 52.4567 36.5433 54.9989 38.1801 56.6184C39.8052 58.2325 42.3338 58.2257 43.9585 56.601C45.5722 54.9873 47.7178 54.0986 49.9999 54.0986C52.2821 54.0986 54.4276 54.9873 56.0413 56.601C57.6661 58.2257 60.1947 58.2325 61.8198 56.6184C63.4565 54.9989 63.4894 52.4567 61.8363 50.8036C58.448 47.416 54.0752 45.9009 50 45.9009Z"
          fill="#1068ED"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_2954_9253"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_9253"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_9253"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2954_9253">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(28 28)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
