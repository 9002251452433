export const Search = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_10722)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <g clipPath="url(#clip0_2954_10722)">
        <path
          d="M53.2134 53.2283C52.0433 54.3984 50.4909 55.0419 48.8354 55.0419C47.1799 55.0419 45.6275 54.3984 44.4574 53.2283C43.2873 52.0596 42.6452 50.5058 42.6452 48.8517C42.6452 47.1976 43.2887 45.6438 44.4574 44.4737C45.6275 43.3036 47.1799 42.6601 48.8354 42.6601C50.4909 42.6601 52.0433 43.3036 53.2134 44.4737C54.3835 45.6424 55.0257 47.1976 55.0257 48.8517C55.0257 50.5058 54.3835 52.0596 53.2134 53.2283ZM71.9148 50.0864C71.9148 62.2167 62.045 72.0864 49.9148 72.0864C37.7845 72.0864 27.9148 62.2167 27.9148 50.0864C27.9148 37.9562 37.7845 28.0864 49.9148 28.0864C62.045 28.0864 71.9148 37.9548 71.9148 50.0864ZM59.7034 57.7424L56.0652 54.0946C57.1707 52.5779 57.7757 50.7629 57.7757 48.8503C57.7757 46.4619 56.8462 44.2166 55.1577 42.5281C53.4692 40.8396 51.2252 39.9087 48.8354 39.9087C46.4457 39.9087 44.2017 40.8396 42.5132 42.5281C40.8247 44.2166 39.8952 46.4619 39.8952 48.8503C39.8952 51.2387 40.826 53.4841 42.5132 55.1712C44.2017 56.8597 46.4457 57.7906 48.8354 57.7906C50.7673 57.7906 52.6015 57.1718 54.1278 56.0443L57.7564 59.6826C58.0245 59.9521 58.3765 60.0868 58.7299 60.0868C59.0819 60.0868 59.4325 59.9534 59.7007 59.6853C60.2383 59.1518 60.2383 58.2801 59.7034 57.7424Z"
          fill="#1068ED"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_2954_10722"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_10722"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_10722"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2954_10722">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(28 28)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
