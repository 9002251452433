export const AppleWatch = ({
  height = '1em',
  width = '1em',
}: {
  height?: string;
  width?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2600_8488)">
        <path
          d="M28.6872 7.5625H13.5628C10.9091 7.5625 8.75 9.72369 8.75 12.3804V31.6197C8.75 34.2764 10.9091 36.4376 13.5628 36.4376H28.6872C31.3409 36.4376 33.5 34.2764 33.5 31.6197V12.3804C33.5 9.72369 31.3409 7.5625 28.6872 7.5625ZM32.125 31.6197C32.125 33.5184 30.5828 35.0625 28.6872 35.0625H13.5628C11.6672 35.0625 10.125 33.5183 10.125 31.6197V12.3804C10.125 10.4816 11.6672 8.93757 13.5628 8.93757H28.6872C30.5828 8.93757 32.125 10.4817 32.125 12.3804V31.6197Z"
          fill="#101010"
        />
        <path
          d="M30.0514 8.12713L29.0452 2.59152C28.7806 1.138 27.4177 0 25.9416 0H16.3085C14.8329 0 13.4695 1.138 13.205 2.59152L12.1986 8.12713C12.162 8.32784 12.2169 8.53379 12.3473 8.69039C12.4777 8.84699 12.6711 8.93753 12.875 8.93753H29.375C29.5788 8.93753 29.7722 8.84679 29.9028 8.69039C30.0334 8.53389 30.0881 8.32784 30.0514 8.12713ZM13.6988 7.56246L14.5576 2.83724C14.702 2.04437 15.5036 1.37497 16.3083 1.37497H25.9414C26.7462 1.37497 27.5478 2.04508 27.6922 2.83724L28.5512 7.56246H13.6988Z"
          fill="#101010"
        />
        <path
          d="M29.9026 35.3096C29.7722 35.153 29.5788 35.0625 29.3749 35.0625H12.8749C12.671 35.0625 12.4777 35.1532 12.3471 35.3096C12.2164 35.466 12.1618 35.6721 12.1984 35.8729L13.2048 41.4085C13.4694 42.862 14.8326 44 16.3083 44H25.9414C27.4175 44 28.7805 42.862 29.045 41.4085L30.0512 35.8729C30.0878 35.6722 30.033 35.4662 29.9026 35.3096ZM27.6923 41.1627C27.5478 41.9551 26.7463 42.625 25.9415 42.625H16.3084C15.5037 42.625 14.702 41.9556 14.5577 41.1627L13.6987 36.4375H28.5511L27.6923 41.1627Z"
          fill="#101010"
        />
        <path
          d="M21.125 18.5625C19.2297 18.5625 17.6875 20.1047 17.6875 22C17.6875 23.8952 19.2297 25.4374 21.125 25.4374C23.0202 25.4374 24.5624 23.8952 24.5624 22C24.5624 20.1047 23.0202 18.5625 21.125 18.5625ZM21.125 24.0625C19.9877 24.0625 19.0625 23.1372 19.0625 22C19.0625 20.8627 19.9877 19.9375 21.125 19.9375C22.2623 19.9375 23.1875 20.8627 23.1875 22C23.1875 23.1372 22.2623 24.0625 21.125 24.0625Z"
          fill="#101010"
        />
      </g>
      <defs>
        <clipPath id="clip0_2600_8488">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
