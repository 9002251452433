export const GlassReplacement = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2953_9041)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.3333 55.8667C64.0268 55.8667 63.7173 55.7713 63.4533 55.5733L58.7468 51.7952L54.0667 56.4739V60.2667C54.0667 61.0763 53.4096 61.7333 52.6 61.7333C51.7904 61.7333 51.1333 61.0763 51.1333 60.2667V57.3333H48.2C47.3904 57.3333 46.7333 56.6763 46.7333 55.8667C46.7333 55.0571 47.3904 54.4 48.2 54.4H51.9928L56.3928 50H52.6C52.138 50 51.7039 49.7829 51.4267 49.4133L50.7931 48.5671L49.014 49.7536C48.3393 50.2024 47.4285 50.0205 46.9797 49.3473C46.5309 48.6727 46.7128 47.7619 47.386 47.3131L49.0301 46.2175L47.0267 43.5467C46.5412 42.8984 46.6717 41.9788 47.32 41.4933C47.9683 41.0079 48.8879 41.1384 49.3733 41.7867L53.3333 47.0667H57C57.3168 47.0667 57.6263 47.1693 57.88 47.36L64.3333 52.5227V32.4C64.3333 29.9712 62.3621 28 59.9333 28H39.4C36.9712 28 35 29.9712 35 32.4V67.6C35 70.0288 36.9712 72 39.4 72H59.9333C62.3621 72 64.3333 70.0288 64.3333 67.6V55.8667ZM46.7333 33.8667H52.6C53.4096 33.8667 54.0667 33.2096 54.0667 32.4C54.0667 31.5904 53.4096 30.9333 52.6 30.9333H46.7333C45.9237 30.9333 45.2667 31.5904 45.2667 32.4C45.2667 33.2096 45.9237 33.8667 46.7333 33.8667Z"
        fill="#1068ED"
      />
      <defs>
        <filter
          id="filter0_b_2953_9041"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2953_9041"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2953_9041"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
