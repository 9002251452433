import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useController } from 'react-hook-form';
import { Typography } from '@mui/material';
import styles from '../styles.module.css';
import { ServiceInfoIcons } from '../../utils/ServiceInfoIcons';

export const IconSelect = ({
  name,
  rules,
  title,
  formMethods,
  ...props
}: any) => {
  const {
    field: { onChange, value, ref },
    fieldState: { error },
  } = useController({ name, rules });
  return (
    <FormControl
      className={styles.inputContainer}
      fullWidth
      sx={{ m: 1, minWidth: 120 }}
    >
      <Typography variant="h6">{title}</Typography>
      <div>
        <Select
          error={!!error}
          fullWidth
          value={value}
          onChange={onChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          {...props}
        >
          {ServiceInfoIcons.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <div className={styles.selectIconItem}>
                {item.icon}
                {item.label}
              </div>
            </MenuItem>
          ))}
        </Select>
        {error?.message && (
          <Typography color="error" variant="body1">
            This field is required
          </Typography>
        )}
      </div>
    </FormControl>
  );
};
