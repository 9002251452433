export const Fan = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_10957)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <path
        d="M67.4707 29.2942H32.5295C30.7427 29.2942 29.2942 30.7427 29.2942 32.5295V67.4707C29.2942 69.2575 30.7427 70.706 32.5295 70.706H67.4707C69.2575 70.706 70.706 69.2575 70.706 67.4707V32.5295C70.706 30.7427 69.2575 29.2942 67.4707 29.2942ZM65.8529 32.5296C66.7458 32.5296 67.4706 33.2543 67.4706 34.1473C67.4706 35.0402 66.7458 35.7649 65.8529 35.7649C64.9599 35.7649 64.2353 35.0402 64.2353 34.1473C64.2353 33.2543 64.9599 32.5296 65.8529 32.5296ZM34.1472 32.5296C35.0401 32.5296 35.7648 33.2543 35.7648 34.1473C35.7648 35.0402 35.0401 35.7649 34.1472 35.7649C33.2542 35.7649 32.5295 35.0402 32.5295 34.1473C32.5295 33.2543 33.2542 32.5296 34.1472 32.5296ZM34.1471 67.4708C33.254 67.4708 32.5294 66.7461 32.5294 65.8532C32.5294 64.9602 33.254 64.2355 34.1471 64.2355C35.0399 64.2355 35.7647 64.9602 35.7647 65.8532C35.7647 66.7461 35.0399 67.4708 34.1471 67.4708ZM49.9046 67.5659C40.2189 67.5659 32.3386 59.6859 32.3386 50.0002C32.3386 40.3143 40.2189 32.4342 49.9046 32.4342C59.5902 32.4342 67.4706 40.3143 67.4706 50.0002C67.4706 59.6859 59.5902 67.5659 49.9046 67.5659ZM65.8531 67.4708C64.9601 67.4708 64.2354 66.746 64.2354 65.8532C64.2354 64.9601 64.9601 64.2355 65.8531 64.2355C66.746 64.2355 67.4707 64.9601 67.4707 65.8532C67.4707 66.746 66.746 67.4708 65.8531 67.4708Z"
        fill="#1068ED"
      />
      <path
        d="M49.8091 53.2625C51.5066 53.2625 52.8827 51.8865 52.8827 50.189C52.8827 48.4915 51.5066 47.1155 49.8091 47.1155C48.1117 47.1155 46.7356 48.4915 46.7356 50.189C46.7356 51.8865 48.1117 53.2625 49.8091 53.2625Z"
        fill="#1068ED"
      />
      <path
        d="M41.6979 40.7571C43.7486 42.5447 45.5693 44.3324 46.8744 46.1529C45.5913 47.0632 44.7578 48.5656 44.7578 50.2656C44.7578 50.4849 44.7688 50.7042 44.8017 50.9126C43.354 50.4191 39.9321 50.3533 36.5105 50.2765C36.0829 50.2656 35.699 49.9695 35.6002 49.5527C34.8325 45.9774 38.9342 39.9017 41.6979 40.7571Z"
        fill="#1068ED"
      />
      <path
        d="M38.2701 55.0562C40.604 53.6583 42.8667 52.4791 45.0015 51.8005C45.4707 53.3021 46.642 54.5591 48.2588 55.0844C48.4674 55.1522 48.6794 55.2095 48.8877 55.2426C47.971 56.4669 46.851 59.701 45.7206 62.9314C45.5781 63.3347 45.1778 63.6083 44.7509 63.5735C41.1134 63.1988 36.6025 57.4203 38.2701 55.0562Z"
        fill="#1068ED"
      />
      <path
        d="M50.8103 62.7349C50.202 60.0833 49.7797 57.5669 49.7941 55.327C51.3671 55.3447 52.9246 54.6191 53.9238 53.2438C54.0527 53.0664 54.1727 52.8825 54.2685 52.6946C55.1497 53.9448 57.8794 56.0094 60.6024 58.0826C60.9419 58.3428 61.0784 58.8081 60.9134 59.2033C59.4329 62.547 52.5434 65.0514 50.8103 62.7349Z"
        fill="#1068ED"
      />
      <path
        d="M61.9881 53.1816C59.2783 52.9407 56.7546 52.5647 54.6287 51.8589C55.1317 50.3683 54.9229 48.6629 53.9236 47.2876C53.7948 47.1102 53.6569 46.9392 53.5078 46.7899C54.9691 46.3383 57.7762 44.3802 60.5894 42.4311C60.9418 42.1886 61.4265 42.2025 61.7513 42.4816C64.4739 44.9228 64.7268 52.2491 61.9881 53.1816Z"
        fill="#1068ED"
      />
      <path
        d="M56.3563 39.5985C55.2899 42.1013 54.1523 44.3853 52.8242 46.189C51.562 45.25 49.8755 44.9216 48.2587 45.4469C48.0502 45.5147 47.8449 45.5929 47.6569 45.6886C47.6789 44.1592 46.6841 40.8845 45.6998 37.6067C45.578 37.1966 45.741 36.7399 46.1069 36.5172C49.2699 34.6823 56.3158 36.7057 56.3563 39.5985Z"
        fill="#1068ED"
      />
      <defs>
        <filter
          id="filter0_b_2954_10957"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_10957"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_10957"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
