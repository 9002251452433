export const RestoringCameraPower = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_9361)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <path
        d="M69.0667 38.0223H61.0489L58.2622 32.9868C58 32.5327 57.5154 32.2531 56.9911 32.2534H43.0578C42.5335 32.2531 42.0489 32.5327 41.7867 32.9868L38.9511 38.0223H30.9333C29.3133 38.0223 28 39.3356 28 40.9556V64.8134C28 66.4334 29.3133 67.7468 30.9333 67.7468H69.0667C70.6867 67.7468 72 66.4334 72 64.8134V40.9556C72 39.3356 70.6867 38.0223 69.0667 38.0223ZM50 62.1245C44.0059 62.1245 39.1467 57.2653 39.1467 51.2712C39.1467 45.2771 44.0059 40.4179 50 40.4179C55.9941 40.4179 60.8533 45.2771 60.8533 51.2712C60.8266 57.2542 55.983 62.0978 50 62.1245Z"
        fill="#1068ED"
      />
      <path
        d="M50 56.6981C52.9971 56.6981 55.4267 54.2685 55.4267 51.2714C55.4267 48.2743 52.9971 45.8447 50 45.8447C47.0029 45.8447 44.5733 48.2743 44.5733 51.2714C44.5733 54.2685 47.0029 56.6981 50 56.6981Z"
        fill="#1068ED"
      />
      <defs>
        <filter
          id="filter0_b_2954_9361"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_9361"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_9361"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
