import { IPaginationMetaDtoParameters } from '../../../types/types';
import { IPost } from '../types';

export interface IInitialState {
  posts: IPost[];
  metaData: IPaginationMetaDtoParameters | null;
  page: number;
  quantity: number;
  order: string;
}

export const actionPostsTypes = {
  SET_POSTS: 'SET_POSTS' as const,
  EDIT_POSTS: 'EDIT_POSTS' as const,
  ADD_POSTS: 'ADD_POSTS' as const,
  SET_META_DATA: 'SET_META_DATA' as const,
  SET_PAGE: 'SET_PAGE' as const,
  SET_QUANTITY: 'SET_QUANTITY' as const,
  SET_ORDER: 'SET_ORDER' as const,
};

export type ActionTypes =
  | { type: typeof actionPostsTypes.SET_POSTS; payload: IPost[] }
  | { type: typeof actionPostsTypes.EDIT_POSTS; payload: IPost }
  | { type: typeof actionPostsTypes.ADD_POSTS; payload: IPost }
  | {
      type: typeof actionPostsTypes.SET_META_DATA;
      payload: IPaginationMetaDtoParameters;
    }
  | {
      type: typeof actionPostsTypes.SET_PAGE;
      payload: number;
    }
  | {
      type: typeof actionPostsTypes.SET_QUANTITY;
      payload: number;
    }
  | {
      type: typeof actionPostsTypes.SET_ORDER;
      payload: string;
    };
