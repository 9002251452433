import { useState } from 'react';
import { useAlert } from '../../../../../../context/Alert.context';
import { API } from '../../../../../../api/api';
import { ALERT_TEXT } from '../../../../../../constants/content';
import { URLS } from '../../../../../../api/urls';
import { DeleteConfirmationModal } from '../../../../../../components/DeleteConfirmationModal/DeleteConfirmationModal';

interface DeleteGadgetModalProps {
  open: boolean;
  onClose: () => void;
  id: string;
  updateServicesAfterDelete: () => void;
}

export const DeleteServiceModal = ({
  open,
  onClose,
  id,
  updateServicesAfterDelete,
}: DeleteGadgetModalProps) => {
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();

  const onDelete = () => {
    setLoading(true);
    API.delete(`${URLS.SERVICE}/${id}`)
      .then((res) => {
        updateServicesAfterDelete();
        onClose();
        showAlert({
          message: ALERT_TEXT.SERVICE_DELETE_SUCCESS,
          severity: 'success',
        });
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }))
      .finally(() => setLoading(false));
  };

  return (
    <DeleteConfirmationModal
      loading={loading}
      onClose={onClose}
      open={open}
      onDelete={onDelete}
    />
  );
};
