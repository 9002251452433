import { useState } from 'react';
import { API } from '../../../../api/api';
import { URLS } from '../../../../api/urls';
import { Modal } from '../../../../components/Modal/Modal';
import { ALERT_TEXT } from '../../../../constants/content';
import { useAlert } from '../../../../context/Alert.context';
import { IPagesDto, PageEdit } from '../../PagesPage.type';
import { PageForm } from './PageForm';

interface CreatePageModalProps {
  open: boolean;
  onClose: () => void;
  page: PageEdit;
  updatePageAfterEdit: (data: IPagesDto) => void;
}

export const EditPageModal = ({
  open,
  onClose,
  page,
  updatePageAfterEdit,
}: CreatePageModalProps) => {
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();

  const createPageModal = (formData: PageEdit) => {
    setLoading(true);
    API.put(`${URLS.PAGES}/${page.id}`, formData)
      .then(({ data }) => {
        updatePageAfterEdit(data);
        onClose();
        showAlert({
          message: ALERT_TEXT.PAGE_EDIT_SUCCESS,
          severity: 'success',
        });
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal shouldPreventModal title="Edit page" open={open} onClose={onClose}>
      <PageForm
        loading={loading}
        onSubmit={createPageModal}
        onCancel={onClose}
        defaultValue={page}
      />
    </Modal>
  );
};
