import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from '@mui/icons-material';
import { Box, IconButton, MenuItem, Select, Typography } from '@mui/material';

type Props = {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void;
  onRowsPerPageChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
};

const CustomTablePagination = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
}: Props) => {
  const handleFirstPageButtonClick = (event: any) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: any) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: any) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: any) => {
    onPageChange(event, count - 1);
  };

  const handleRowsPerPageChange = (event: any) => {
    onRowsPerPageChange(event);
  };

  return (
    <Box
      sx={{
        flexShrink: 0,
        ml: 2.5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPage />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <Typography variant="body2">
        {page + 1} of {count}
      </Typography>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= count - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= count - 1}
        aria-label="last page"
      >
        <LastPage />
      </IconButton>
      <Typography variant="body2">Rows per page:</Typography>
      <Select
        value={rowsPerPage}
        onChange={handleRowsPerPageChange}
        sx={{ ml: 1, mr: 2, mt: 1, mb: 1 }}
      >
        {[10, 20, 30, 40, 50].map((rows) => (
          <MenuItem key={rows} value={rows}>
            {rows}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default CustomTablePagination;
