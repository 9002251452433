import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ArticleIcon from '@mui/icons-material/Article';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import HomeIcon from '@mui/icons-material/Home';
import { Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import blogImage from '../../assets/images/blog.jpeg';
import emailsImage from '../../assets/images/emails.jpeg';
import gadgetsImage from '../../assets/images/gadgets.webp';
import pagesImage from '../../assets/images/pages.webp';
import serviceInfoImage from '../../assets/images/service-info.webp';
import {
  BreadcrumbsComponent,
  LinkItem,
} from '../../components/Breadcrumbs/Breadcrumbs';
import { CommonCard } from '../../components/common/CommonCard/CommonCard';
import styles from '../../components/common/CommonCard/styles.module.css';
import { locations } from '../../utils/locations';
import stylesPage from './styles.module.css';

const linkList: LinkItem[] = [
  {
    name: 'Projects',
    to: '/',
    icon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    name: 'Choice',
    to: '/choice',
    icon: <AccountTreeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
];

export const ChoicePage = () => {
  const navigate = useNavigate();

  const navigateToTheRoute = (path: string) => {
    navigate(path);
  };

  const cards = [
    {
      title: 'Service Info',
      onButtonClicked: () => navigateToTheRoute(locations.choice_services.path),
      icon: <ArticleIcon fontSize="large" color="primary" />,
      image: serviceInfoImage,
      description: 'You can create description of service for all gadgets.',
    },
    {
      title: 'Gadgets',
      onButtonClicked: () => navigateToTheRoute(locations.choice_gadgets.path),
      icon: <DevicesOtherIcon fontSize="large" color="primary" />,
      image: gadgetsImage,
      description: 'You can create Gadgets, Models and Service.',
    },
    {
      title: 'Blog',
      onButtonClicked: () => navigateToTheRoute(locations.choice_blog.path),
      image: blogImage,
      description: 'This section allow you to handle blog.',
    },
    {
      title: 'Mails',
      onButtonClicked: () => navigateToTheRoute(locations.choice_mails.path),
      image: emailsImage,
      description: 'There are mails that receive from the client.',
    },
    {
      title: 'Pages',
      onButtonClicked: () => navigateToTheRoute(locations.choice_pages.path),
      image: pagesImage,
      description: 'There are pages settings that you can handle.',
    },
    // {
    //   title: 'Trade-in',
    //   onButtonClicked: () => navigateToTheRoute(locations.choice_trade_in.path),
    //   image: tradeInImage,
    //   description: 'There are trade-in models.',
    // },
  ];

  return (
    <Container className={stylesPage.container} maxWidth="xl">
      <div className={styles['card-container']}>
        <BreadcrumbsComponent linkList={linkList} />
        <div className={styles.cardsList}>
          {cards.map((card) => (
            <CommonCard {...card} key={card.title} />
          ))}
        </div>
      </div>
    </Container>
  );
};
