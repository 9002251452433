import { useState } from 'react';
import { API } from '../../../api/api';
import { URLS } from '../../../api/urls';
import { DeleteConfirmationModal } from '../../../components/DeleteConfirmationModal/DeleteConfirmationModal';
import { ALERT_TEXT } from '../../../constants/content';
import { useAlert } from '../../../context/Alert.context';

interface DeletePostModalProps {
  open: boolean;
  onClose: () => void;
  updatePostsAfterDelete: (postId: string) => void;
  postId: string;
}

export const DeletePostModal = ({
  open,
  onClose,
  postId,
  updatePostsAfterDelete,
}: DeletePostModalProps) => {
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();

  const onDelete = () => {
    onClose();

    setLoading(true);
    API.delete(`${URLS.POSTS}/${postId}`)
      .then(() => {
        updatePostsAfterDelete(postId);
        onClose();
        showAlert({
          message: ALERT_TEXT.POST_DELETE_SUCCESS,
          severity: 'success',
        });
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }))
      .finally(() => setLoading(false));
  };

  return (
    <DeleteConfirmationModal
      loading={loading}
      onClose={onClose}
      open={open}
      onDelete={onDelete}
    />
  );
};
