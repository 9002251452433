export const Water = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_9265)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <path
        d="M65.3766 44.4514L65.3103 44.3616L65.2971 44.3353L59.272 35.3911L53.2469 44.3354L53.2343 44.3605L53.1674 44.4515C52.2006 45.7609 51.6896 47.3164 51.6896 48.9502C51.6896 53.1312 55.0911 56.5326 59.2719 56.5326C63.4528 56.5326 66.8543 53.1311 66.8543 48.9502C66.8545 47.3163 66.3434 45.7606 65.3766 44.4514Z"
        fill="#1068ED"
      />
      <path
        d="M59.2721 59.1161C53.6666 59.1161 49.1063 54.5557 49.1063 48.9502C49.1063 46.7712 49.7851 44.6949 51.0696 42.9436L55.0304 37.0638L48.9245 28L36.2027 46.8851C34.2025 49.6071 33.1455 52.8344 33.1455 56.221C33.1455 64.9216 40.224 72 48.9245 72C57.1685 72 63.955 65.6448 64.6447 57.5764C63.0848 58.5515 61.2434 59.1161 59.2721 59.1161Z"
        fill="#1068ED"
      />
      <defs>
        <filter
          id="filter0_b_2954_9265"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_9265"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_9265"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
