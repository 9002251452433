import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { object, string, type ObjectSchema } from 'yup';
import { authAPI } from '../../api/api';
import dogLogo from '../../assets/images/dog.png';
import { useAlert } from '../../context/Alert.context';
import { Input } from '../Input/Input';
import styles from './styles.module.css';

interface IAuth {
  newPassword: string;
}

const validationSchema: ObjectSchema<IAuth> = object({
  newPassword: string().trim().required(),
}).required();

export const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const { token } = useParams<{ token: string }>();

  const { showAlert } = useAlert();
  const navigate = useNavigate();

  const formMethods = useForm<{ newPassword: string }>({
    defaultValues: { newPassword: '' },
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = () => {
    setLoading(true);
    formMethods
      .handleSubmit(
        async (formData) => {
          try {
            const response = await authAPI.post('/auth/reset-password', {
              ...formData,
              token,
            });
            showAlert({ message: response.data.message, severity: 'success' });
            navigate('/login');
          } catch (error: any) {
            showAlert({ message: 'Some error occurred', severity: 'error' });
          }
        },
        (_) => {},
      )()
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className={styles.container}>
        <img className={styles.image} src={dogLogo} alt="dog-logo" />
        <FormProvider {...formMethods}>
          <div className={styles.infoContainer}>
            <Input
              required
              multiline
              name="newPassword"
              title="Password"
              placeholder="Enter password"
              formMethods={formMethods}
              type="password"
            />
          </div>
          <div className={styles.forgotPasswordText}>
            <Typography variant="body2">
              Back to
              <Link className={styles.resetLink} to="/login">
                Login page
              </Link>
            </Typography>
          </div>
        </FormProvider>
        <LoadingButton variant="contained" loading={loading} onClick={onSubmit}>
          Reset Password
        </LoadingButton>
      </div>
    </>
  );
};
