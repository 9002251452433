import { IPagesDto } from '../PagesPage.type';
import { ModalKeys } from '../chooseModal';

export interface IInitialState {
  pages: IPagesDto[];
  loading: boolean;
  selectedPage: IPagesDto | null;
  choosenModal: ModalKeys | null;
}

export const actionPageTypes = {
  SET_PAGES: 'SET_PAGES' as const,
  SET_SELECTED_PAGE: 'SET_SELECTED_PAGE' as const,
  SET_LOADING: 'SET_LOADING' as const,
  SET_CHOOSEN_MODAL: 'SET_CHOOSEN_MODAL' as const,
};

export type ActionTypes =
  | {
      type: typeof actionPageTypes.SET_PAGES;
      payload: IPagesDto[];
    }
  | { type: typeof actionPageTypes.SET_LOADING; payload: boolean }
  | {
      type: typeof actionPageTypes.SET_CHOOSEN_MODAL;
      payload: null | ModalKeys;
    }
  | {
      type: typeof actionPageTypes.SET_SELECTED_PAGE;
      payload: null | IPagesDto;
    };
