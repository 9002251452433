export const Clean = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_10986)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <g clipPath="url(#clip0_2954_10986)">
        <path
          d="M57.8907 64.1522C58.4277 64.6892 58.4277 65.5598 57.8907 66.0968C57.3538 66.6338 56.4832 66.6338 55.9462 66.0968C55.4092 65.5598 55.4092 64.6892 55.9462 64.1522C56.4832 63.6153 57.3538 63.6153 57.8907 64.1522Z"
          fill="#1068ED"
        />
        <path
          d="M52.3907 69.6522C52.9277 70.1892 52.9277 71.0598 52.3907 71.5968C51.8538 72.1338 50.9832 72.1338 50.4462 71.5968C49.9092 71.0598 49.9092 70.1892 50.4462 69.6522C50.9832 69.1153 51.8538 69.1153 52.3907 69.6522Z"
          fill="#1068ED"
        />
        <path
          d="M62.9875 67.3045C64.0614 68.3784 64.0614 70.1196 62.9875 71.1936C61.9136 72.2675 60.1724 72.2675 59.0984 71.1936C58.0245 70.1196 58.0245 68.3784 59.0984 67.3045C60.1724 66.2305 61.9136 66.2305 62.9875 67.3045Z"
          fill="#1068ED"
        />
        <path
          d="M66.9222 28C65.3995 28 63.9654 28.6714 62.9892 29.8423L54.2927 40.2775C54.9336 40.5722 55.5517 40.9203 56.133 41.3351C57.771 42.5046 58.9735 44.0361 59.8009 45.7224L70.2012 37.0543C71.3721 36.0781 72.0435 34.6441 72.0435 33.1213C72.0435 30.2975 69.746 28 66.9222 28Z"
          fill="#1068ED"
        />
        <path
          d="M54.5363 43.5748C51.0155 41.0638 46.1305 41.3431 42.9253 44.2354C40.9232 46.0428 34.8324 51.5415 29.1256 52.7822L27.9561 53.0373C27.9561 53.0373 28.0433 56.8763 29.2068 58.6076C31.4857 58.353 33.3305 57.8817 33.964 57.2482C32.5635 58.9047 31.4981 60.1025 30.6728 60.9984C31.3516 61.9057 32.1875 62.7669 33.1264 63.5974C36.4931 62.165 39.4958 59.6894 40.4756 58.7095C38.501 62.0005 36.8952 64.4722 36.0509 65.7455C37.9298 64.6523 40.2198 63.2315 42.3628 61.64C41.5617 62.4411 40.5108 64.9578 39.6608 67.8322C40.6085 68.2894 43.2731 69.2473 43.2731 69.2473C47.3645 68.9358 54.4705 59.2973 56.8633 55.6101C59.4427 51.6368 58.4209 46.3489 54.5363 43.5748Z"
          fill="#1068ED"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_2954_10986"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_10986"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_10986"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2954_10986">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(28 28)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
