import ErrorIcon from '@mui/icons-material/Error';
import { LoadingButton } from '@mui/lab';
import { Tab, Tabs, Typography } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { CustomCheckbox } from '../../../../components/Checkbox/Checkbox';
import { Input } from '../../../../components/Input/Input';
import { UploadImage } from '../../../../components/UploadImage/UploadImage';
import { chooseLangAccordingToTab } from '../../../ServicesPage/components/useServiceInfoForm';
import { IPost } from '../../types';
import { TextEditor } from '../TextEditor/TextEditor';
import { AuthorPhotoSelect } from './components/authorPhotoSelect/AuthorPhotoSelect';
import { Card } from './components/Card/Card';
import { TopicSelect } from './components/select/TopicSelect';
import styles from './styles.module.css';
import { usePostForm } from './usePostForm';

interface PostFormProps {
  onAction: (post: any) => void;
  post: IPost | null;
}

export const PostForm = ({ post, onAction }: PostFormProps) => {
  const {
    formMethods,
    onSubmit,
    loading,

    fileInputRef,
    authorFileInputRef,

    activeTabIndex,
    onTabClick,

    previewUrl,
    fileName,
    handleFileChange,
    handleRemoveFile,

    handleAuthorFileChange,
    handleRemoveAuthorImage,
    authorPreviewUrl,
    authorFileName,
    selectedAuthorFileInfo,

    isEnTabValid,
    isPlTabValid,
    isEuTabValid,
    isRuTabValid,
  } = usePostForm({ post, onAction });

  const titleName: any = `title${chooseLangAccordingToTab(activeTabIndex)}`;
  const metaDescription: any = `metaDescription${chooseLangAccordingToTab(activeTabIndex)}`;
  const body: any = `body${chooseLangAccordingToTab(activeTabIndex)}`;
  const previewText: any = `previewText${chooseLangAccordingToTab(activeTabIndex)}`;

  return (
    <div className={styles.container}>
      <FormProvider {...formMethods}>
        <div className={styles.form}>
          <Card title="Preview Image">
            <UploadImage
              previewSizeHeight="200px"
              previewSizeWidth="200px"
              previewUrl={previewUrl || ''}
              fileName={fileName}
              isError={!previewUrl}
              onChange={handleFileChange}
              name="fileInfo"
              handleRemoveImage={handleRemoveFile}
              fileInputRef={fileInputRef}
            />
            <Input
              required
              multiline
              name="altText"
              title="Alt text"
              placeholder="Enter Alt text"
              formMethods={formMethods}
            />
          </Card>

          <Card title="Post settings">
            Upload author Photo
            <UploadImage
              previewSizeHeight="200px"
              previewSizeWidth="200px"
              previewUrl={authorPreviewUrl || ''}
              fileName={authorFileName || ''}
              isError={!authorPreviewUrl && !selectedAuthorFileInfo}
              onChange={handleAuthorFileChange}
              name="authorFileInfo"
              handleRemoveImage={handleRemoveAuthorImage}
              fileInputRef={authorFileInputRef}
            />{' '}
            or choose from already exist
            <AuthorPhotoSelect
              name="selectedAuthorFileInfo"
              title="Author photo"
              disabled={!!authorPreviewUrl}
            />
            <Input
              required
              multiline
              name="author"
              title="Author"
              placeholder="Enter author"
              formMethods={formMethods}
            />
            <Input
              required
              multiline
              name="authorPosition"
              title="Author position"
              placeholder="Enter author position"
              formMethods={formMethods}
            />
            <Input
              required
              multiline
              name="readTime"
              title="Read time (min)"
              type="number"
              placeholder="Enter read time"
              formMethods={formMethods}
            />
            <TopicSelect required name="topic" title="Post topic" />
            <CustomCheckbox
              name="isTrending"
              label="Trending"
              formMethods={formMethods}
            />
          </Card>

          <div className={styles.tabContainer}>
            <Tabs value={activeTabIndex} onChange={onTabClick}>
              <Tab label={<TabLabel label="Polish" isValid={isPlTabValid} />} />
              <Tab
                label={<TabLabel label="English" isValid={isEnTabValid} />}
              />
              <Tab
                label={<TabLabel label="Russian" isValid={isRuTabValid} />}
              />
              <Tab
                label={<TabLabel label="Ukranian" isValid={isEuTabValid} />}
              />
            </Tabs>
          </div>
          <Card title="Post content">
            <Input
              required
              multiline
              name={previewText}
              title="Preview text"
              placeholder="Enter preview text"
              formMethods={formMethods}
            />
            <Input
              required
              multiline
              name={titleName}
              title="Post title"
              placeholder="Enter post title"
              formMethods={formMethods}
            />
            <Input
              required
              multiline
              name={metaDescription}
              title="Meta Description"
              placeholder="Enter meta description"
              formMethods={formMethods}
            />
            <TextEditor
              placeholder="Post..."
              required
              formMethods={formMethods}
              name={body}
            />
          </Card>
        </div>
      </FormProvider>
      <LoadingButton
        fullWidth
        variant="contained"
        loading={loading}
        onClick={onSubmit}
      >
        SAVE
      </LoadingButton>
    </div>
  );
};

const TabLabel = ({ label, isValid }: { label: string; isValid: boolean }) => {
  return (
    <div className={styles.tabItem}>
      <Typography variant="body1" color={isValid ? 'blue' : 'red'}>
        {label}
      </Typography>
      {!isValid && <ErrorIcon color="error" />}
    </div>
  );
};
