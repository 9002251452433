export enum ServiceIcon {
  FrontCamera = 'FrontCamera',
  Fan = 'Fan',
  ReplacingProximitySensor = 'ReplacingProximitySensor',
  RestoringCameraPower = 'RestoringCameraPower',
  GlassReplacement = 'GlassReplacement',
  BatteryReplacement = 'BatteryReplacement',
  GlassPanel = 'GlassPanel',
  Case = 'Case',
  AddingMemory = 'AddingMemory',
  BrokenDisplayIpad = 'BrokenDisplayIpad',
  BrokenDisplayMac = 'BrokenDisplayMac',
  Camera = 'Camera',
  CameraGlass = 'CameraGlass',
  CaseIpad = 'CaseIpad',
  Chip = 'Chip',
  Clean = 'Clean',
  ConnectorOnTheMotherboard = 'ConnectorOnTheMotherboard',
  DataRecovery = 'DataRecovery',
  DataRecoveryConsultation = 'DataRecoveryConsultation',
  Keyboard = 'Keyboard',
  Light = 'Light',
  HearingSpeaker = 'HearingSpeaker',
  MagSafe = 'MagSafe',
  Microphone = 'Microphone',
  NoImage = 'NoImage',
  NoWifi = 'NoWifi',
  Lightning = 'Lightning',
  PowerButton = 'PowerButton',
  ProblemsTurningOn = 'ProblemsTurningOn',
  PayPass = 'PayPass',
  RadiatorReplacement = 'RadiatorReplacement',
  ReplacingChargingConnector = 'ReplacingChargingConnector',
  Search = 'Search',
  SSD = 'SSD',
  SystemUpdate = 'SystemUpdate',
  TopCase = 'TopCase',
  TouchId = 'TouchId',
  Touchpad = 'Touchpad',
  TouchScreen = 'TouchScreen',
  USBTypeC = 'USBTypeC',
  Vibrations = 'Vibrations',
  LoggoboardMac = 'LoggoboardMac',
  Volume = 'Volume',
  VolumeButton = 'VolumeButton',
  Water = 'Water',
  Wifi = 'Wifi',
  Lock = 'Lock',
  FaceID = 'FaceID',
}

export interface ServiceInfo {
  id: string;
  gadgetId?: string;
  titleEn: string;
  titlePl: string;
  titleUk: string;
  titleRu: string;

  shortTitleEn: string;
  shortTitlePl: string;
  shortTitleUk: string;
  shortTitleRu: string;

  metaDescriptionEn: string;
  metaDescriptionPl: string;
  metaDescriptionUk: string;
  metaDescriptionRu: string;

  subTitleEn: string;
  subTitlePl: string;
  subTitleUk: string;
  subTitleRu: string;
  //TODO
  fileInfo: any;

  icon: ServiceIcon;

  infoEn: IInfoDto;

  infoPl: IInfoDto;

  infoUk: IInfoDto;

  infoRu: IInfoDto;
}

export interface ServiceInfoFromBack {
  id: string;
  gadget?: {
    name: string;
    id: string;
  };
  titleEn: string;
  titlePl: string;
  titleUk: string;
  titleRu: string;

  shortTitleEn: string;
  shortTitlePl: string;
  shortTitleUk: string;
  shortTitleRu: string;

  subTitleEn: string;
  subTitlePl: string;
  subTitleUk: string;
  subTitleRu: string;

  metaDescriptionEn: string;
  metaDescriptionPl: string;
  metaDescriptionUk: string;
  metaDescriptionRu: string;
  //TODO
  fileInfo: object;

  icon: ServiceIcon;

  infoEn: string;

  infoPl: string;

  infoUk: string;

  infoRu: string;
}

export interface IInfoDto {
  title: string;
  titleContent: string;
  subTitle: string;
  subTitleContent: string;
  titleList: string;
  list: string;
  listContent: string;
  titleTip: string;
  tipContent: string;
}
