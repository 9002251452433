import { Typography } from '@mui/material';
import { API } from '../../../api/api';
import { URLS } from '../../../api/urls';
import { Modal } from '../../../components/Modal/Modal';
import { ALERT_TEXT } from '../../../constants/content';
import { useAlert } from '../../../context/Alert.context';
import { ServiceInfo } from '../../../types/serviceInfo.types';
import { ServiceInfoForm } from '../components/ServiceInfoForm';
import { parseServiceInfoListToArray } from '../utils/parseServiceInfo';
import { Tip } from './components/Tip';
import styles from './styles.module.css';

interface EditServiceInfoModalProps {
  open: boolean;
  onClose: () => void;
  selectedServiceInfo: ServiceInfo;
  updateAfterEditing: (serviceInfo: any) => void;
}

export const EditServiceInfoModal = ({
  onClose,
  open,
  selectedServiceInfo,
  updateAfterEditing,
}: EditServiceInfoModalProps) => {
  const { showAlert } = useAlert();

  const createService = (serviceFromForm: ServiceInfo) => {
    const service = parseServiceInfoListToArray(serviceFromForm);
    const formData = new FormData();
    formData.append('fileInfo', service.fileInfo as File);
    // formData.append('gadgetId', selectedGadgetId);
    formData.append('icon', service.icon);

    formData.append('titleEn', service.titleEn);
    formData.append('titlePl', service.titlePl);
    formData.append('titleUk', service.titleUk);
    formData.append('titleRu', service.titleRu);

    formData.append('shortTitleEn', service.shortTitleEn);
    formData.append('shortTitlePl', service.shortTitlePl);
    formData.append('shortTitleUk', service.shortTitleUk);
    formData.append('shortTitleRu', service.shortTitleRu);

    formData.append('subTitleEn', service.subTitleEn);
    formData.append('subTitlePl', service.subTitlePl);
    formData.append('subTitleUk', service.subTitleUk);
    formData.append('subTitleRu', service.subTitleRu);

    formData.append('metaDescriptionEn', service.metaDescriptionEn);
    formData.append('metaDescriptionPl', service.metaDescriptionPl);
    formData.append('metaDescriptionUk', service.metaDescriptionUk);
    formData.append('metaDescriptionRu', service.metaDescriptionRu);

    const infoEn = JSON.stringify(service.infoEn);
    const infoRu = JSON.stringify(service.infoRu);
    const infoUk = JSON.stringify(service.infoUk);
    const infoPl = JSON.stringify(service.infoPl);

    formData.append('infoEn', infoEn);
    formData.append('infoRu', infoRu);
    formData.append('infoUk', infoUk);
    formData.append('infoPl', infoPl);

    API.put(`${URLS.SERVICE_INFO}/${selectedServiceInfo.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }: any) => {
        onClose();
        updateAfterEditing(data);
        showAlert({
          message: ALERT_TEXT.INFO_EDITED_SUCCESS,
          severity: 'success',
        });
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }));
  };

  return (
    <Modal
      shouldPreventModal
      title={
        <div className={styles.modalHeader}>
          <Typography variant="h6">Update service info</Typography>
          <Tip />
        </div>
      }
      open={open}
      onClose={onClose}
    >
      <ServiceInfoForm
        serviceInfo={selectedServiceInfo}
        onCancel={onClose}
        createService={createService}
      />
    </Modal>
  );
};
