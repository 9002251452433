export const Vibrations = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_9247)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <g clipPath="url(#clip0_2954_9247)">
        <path
          d="M56.6165 31.6665H55.0417H44.9583H43.3835C41.4713 31.6665 39.9167 33.3623 39.9167 35.4487V60.8862C39.9167 62.9707 41.4713 64.6665 43.3835 64.6665H56.6183C58.5305 64.6665 60.0852 62.9707 60.0852 60.8862V35.4487C60.0833 33.3623 58.5287 31.6665 56.6165 31.6665ZM57.5625 60.5415C57.5625 61.3005 56.9978 61.9165 56.3012 61.9165H43.697C43.0003 61.9165 42.4357 61.3005 42.4357 60.5415V35.3332C42.4357 34.5742 43.0003 33.9582 43.697 33.9582H44.3277C45.0243 33.9582 45.589 34.5742 45.589 35.3332C45.589 36.0922 46.1537 36.7082 46.8503 36.7082H53.1533C53.85 36.7082 54.4147 36.0922 54.4147 35.3332C54.4147 34.5742 54.9793 33.9582 55.676 33.9582H56.3067C57.0033 33.9582 57.568 34.5742 57.568 35.3332V60.5415H57.5625Z"
          fill="#1068ED"
        />
        <path
          d="M34.4167 68.7915C33.9803 68.7915 33.5403 68.6375 33.1902 68.3203L28.6068 64.1953C28.2328 63.8598 28.0147 63.3832 28 62.8808C27.9872 62.3785 28.1815 61.8908 28.5372 61.537L31.8243 58.2498L28.5372 54.9627C27.8203 54.2458 27.8203 53.0872 28.5372 52.3703L31.8243 49.0832L28.5372 45.796C27.8203 45.0792 27.8203 43.9205 28.5372 43.2037L31.8243 39.9165L28.5372 36.6293C28.1815 36.2737 27.9872 35.7878 28 35.2855C28.0147 34.7832 28.2328 34.3065 28.6068 33.971L33.1902 29.846C33.9437 29.1677 35.1005 29.23 35.7788 29.9817C36.4553 30.7352 36.3948 31.8938 35.6432 32.5703L32.4953 35.4028L35.7128 38.6203C36.4297 39.3372 36.4297 40.4958 35.7128 41.2127L32.4257 44.4998L35.7128 47.787C36.4297 48.5038 36.4297 49.6625 35.7128 50.3793L32.4257 53.6665L35.7128 56.9537C36.4297 57.6705 36.4297 58.8292 35.7128 59.546L32.4953 62.7635L35.6432 65.596C36.3948 66.2725 36.4553 67.4312 35.7788 68.1847C35.4177 68.5862 34.9172 68.7915 34.4167 68.7915Z"
          fill="#1068ED"
        />
        <path
          d="M65.5833 68.7915C65.0828 68.7915 64.5823 68.5862 64.2212 68.1847C63.5447 67.4312 63.6052 66.2725 64.3568 65.596L67.5047 62.7635L64.2872 59.546C63.5703 58.8292 63.5703 57.6705 64.2872 56.9537L67.5743 53.6665L64.2872 50.3793C63.5703 49.6625 63.5703 48.5038 64.2872 47.787L67.5743 44.4998L64.2872 41.2127C63.5703 40.4958 63.5703 39.3372 64.2872 38.6203L67.5047 35.4028L64.3568 32.5703C63.6052 31.8938 63.5447 30.7352 64.2212 29.9817C64.8995 29.23 66.0582 29.1677 66.8098 29.846L71.3932 33.971C71.7672 34.3065 71.9853 34.7832 72 35.2855C72.0128 35.7878 71.8185 36.2755 71.4628 36.6293L68.1757 39.9165L71.4628 43.2037C72.1797 43.9205 72.1797 45.0792 71.4628 45.796L68.1757 49.0832L71.4628 52.3703C72.1797 53.0872 72.1797 54.2458 71.4628 54.9627L68.1757 58.2498L71.4628 61.537C71.8185 61.8927 72.0128 62.3785 72 62.8808C71.9853 63.3832 71.7672 63.858 71.3932 64.1953L66.8098 68.3203C66.4597 68.6357 66.0197 68.7915 65.5833 68.7915Z"
          fill="#1068ED"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_2954_9247"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_9247"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_9247"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2954_9247">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(28 28)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
