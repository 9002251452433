import { LoadingButton } from '@mui/lab';
import { FormProvider } from 'react-hook-form';
import { CustomCheckbox } from '../../../../../../components/Checkbox/Checkbox';
import { Input } from '../../../../../../components/Input/Input';
import { ServiceInfo } from '../../../../../../types/serviceInfo.types';
import { Service } from '../../../../../../types/services.types';
import { useServiceForm } from '../logic/useServiceForm';
import styles from '../styles.module.css';
import { ServiceInfoSelect } from './ServiceInfoSelect';

interface ServiceFormProps {
  service: Service | null;
  createService: (service: Service) => void;
  serviceInfo: ServiceInfo[];
}

export const ServiceForm = ({
  service,
  createService,
  serviceInfo,
}: ServiceFormProps) => {
  const { formMethods, onSubmit, loading } = useServiceForm({
    service,
    createService,
  });

  return (
    <>
      <FormProvider {...formMethods}>
        <ServiceInfoSelect
          serviceInfoData={serviceInfo}
          required
          name="serviceInfoId"
          title="Service Info"
        />
        <div className={styles.inputWrapper}>
          <Input
            name="price"
            type="number"
            required
            min={0}
            title="Price"
            placeholder="Enter price"
            formMethods={formMethods}
          />
          <Input
            name="oldPrice"
            type="number"
            required
            min={0}
            title="Old price"
            placeholder="Enter old price"
            formMethods={formMethods}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Input
            name="recoveryTime"
            type="string"
            required
            min={0}
            title="Recovery Time"
            placeholder="Enter recovery time"
            formMethods={formMethods}
          />
          <Input
            name="guarantee"
            type="number"
            min={0}
            required
            title="Guarantee"
            placeholder="Enter guarantee"
            formMethods={formMethods}
          />
        </div>
        <CustomCheckbox
          name="isBestPrice"
          label="Best Price"
          formMethods={formMethods}
        />
        <CustomCheckbox
          name="isFreeDiagnostic"
          label="Free diagnostic"
          formMethods={formMethods}
        />
        <CustomCheckbox
          name="isPopular"
          label="Popular Service"
          formMethods={formMethods}
        />
      </FormProvider>
      <LoadingButton
        fullWidth
        variant="contained"
        loading={loading}
        onClick={onSubmit}
      >
        SAVE
      </LoadingButton>
    </>
  );
};
