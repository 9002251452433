export const ProblemsTurningOn = ({
  height = 100,
  width = 100,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2954_11101)">
        <circle cx="50" cy="50" r="50" fill="#F4F4F4" fillOpacity="0.5" />
      </g>
      <g clipPath="url(#clip0_2954_11101)">
        <path
          d="M72 50C72 37.8692 62.1307 28 50 28C37.8692 28 28 37.8692 28 50C28 62.1299 37.8692 72 50 72C62.1316 72 72 62.1316 72 50ZM52.1605 49.9663C52.1605 51.1201 51.2256 52.0532 50.0736 52.0532C48.9206 52.0532 47.9857 51.1183 47.9857 49.9663V39.1081C47.9857 37.9552 48.9206 37.0203 50.0736 37.0203C51.2265 37.0203 52.1605 37.9543 52.1605 39.1081V49.9663ZM64.2062 50.0363C64.2062 57.8692 57.8337 64.2407 50 64.2407C42.1663 64.2407 35.7938 57.8692 35.7938 50.0363C35.7938 46.2391 37.2729 42.6696 39.9597 39.9872C40.3674 39.5796 40.9008 39.3766 41.4352 39.3766C41.9695 39.3766 42.5048 39.5804 42.9124 39.989C43.7277 40.8051 43.7268 42.1264 42.9107 42.9417C41.0151 44.8363 39.9704 47.3557 39.9704 50.0363C39.9704 55.566 44.4694 60.0633 50 60.0633C55.5306 60.0633 60.0288 55.566 60.0288 50.0363C60.0288 47.3574 58.9866 44.839 57.0929 42.9443C56.2776 42.1282 56.2785 40.8069 57.0938 39.9907C57.9099 39.1746 59.2312 39.1763 60.0474 39.9916C62.728 42.6749 64.2062 46.2426 64.2062 50.0363Z"
          fill="#1068ED"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_2954_11101"
          x="-24"
          y="-24"
          width="148"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2954_11101"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2954_11101"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2954_11101">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(28 28)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
