import {
  Iphone,
  Ipad,
  MacBook,
  Imac,
  MacMini,
  AirPods,
  AppleWatch,
  DataRecovery,
} from './svg';

export enum GadgetIconsEnum {
  Iphone = 'Iphone',
  Ipad = 'Ipad',
  MacBook = 'MacBook',
  AppleWatch = 'AppleWatch',
  Imac = 'Imac',
  MacMini = 'MacMini',
  AirPods = 'AirPods',
  DataRecovery = 'DataRecovery',
}
export const GadgetsIcon = [
  {
    value: GadgetIconsEnum.Iphone,
    label: 'Iphone',
    icon: <Iphone />,
  },
  {
    value: GadgetIconsEnum.Ipad,
    label: 'Ipad',
    icon: <Ipad />,
  },
  {
    value: GadgetIconsEnum.MacBook,
    label: 'MacBook',
    icon: <MacBook />,
  },
  {
    value: GadgetIconsEnum.Imac,
    label: 'Imac',
    icon: <Imac />,
  },
  {
    value: GadgetIconsEnum.MacMini,
    label: 'Mac Mini',
    icon: <MacMini />,
  },
  {
    value: GadgetIconsEnum.AirPods,
    label: 'Air Pods',
    icon: <AirPods />,
  },
  {
    value: GadgetIconsEnum.AppleWatch,
    label: 'Apple Watch',
    icon: <AppleWatch />,
  },
  {
    value: GadgetIconsEnum.DataRecovery,
    label: 'Data Recovery',
    icon: <DataRecovery />,
  },
];

export const getIcon = (iconName: string) => {
  return GadgetsIcon.filter(
    (gadgetIcon: any) => gadgetIcon.value === iconName,
  )[0];
};
